/**
 * Sidebar profile
 * @type {preact.FunctionComponent}
 * @param {Object} props
 * @param {App.Entity.User} props.user
 * @param {App.Entity.Organisation|null} props.organisation
 */
const SidebarUserProfile = ({
  user,
  organisation,
}) => (
  <>
    <h2 className="uk-h4 uk-margin-remove-vertical">
      { user.name }
    </h2>
    <div className="uk-text-meta uk-text-truncate">
      { user.email }
    </div>
    {organisation &&
      <div className="uk-text-meta uk-text-truncate">
        { organisation.name }
      </div>
    }
  </>
)

export default SidebarUserProfile
