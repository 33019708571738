/**
 * Third-party packages config provider
 */

import { useContext } from 'preact/hooks'
import { createContext } from 'preact'

import { useAuth } from './AuthProvider.jsx'

/**
 * @typedef {Object} PackagesConfigInterface
 * @property {string} imageServerUrl
 * @property {string} videoServerUrl
 */

/**
 * Context object
 */
export const PackagesConfigContext = createContext(/** @type {PackagesConfigInterface|null} */ (null))

PackagesConfigContext.displayName = 'PackagesConfigContext'

/**
 * Hook
 * @return {PackagesConfigInterface}
 */
export function usePackagesConfig () {
  const value = useContext(PackagesConfigContext)

  if (!value) {
    throw new Error('Packages Config Context has not been provided')
  }

  return value
}

/**
 * @type {preact.FunctionComponent}
 * @param {Object} props
 * @param {PackagesConfigInterface} props.config
 * @param {preact.ComponentChildren} props.children
 */
export function PackagesConfigProvider ({
  config,
  children,
}) {
  const { organisation } = useAuth()

  /** @type {PackagesConfigInterface} */
  const compiledConfig = {
    ...config,
    imageServerUrl: null,
    videoServerUrl: null
  }

  // Replace hash when organisation is available
  if (organisation && organisation.hash) {
    compiledConfig.imageServerUrl = config.imageServerUrl.replace(':organisationHash', organisation.hash)
    compiledConfig.videoServerUrl = config.videoServerUrl.replace(':organisationHash', organisation.hash)
  }

  return (
    <PackagesConfigContext.Provider value={ compiledConfig }>
      { children }
    </PackagesConfigContext.Provider>
  )
}
