/** @typedef { import('../../Constants/permission.js').Permission } Permission */

/** @enum {Permission} */
const PERMISSION = {
  INDEX: 'playlist:index',
  CREATE: 'playlist:create',
  READ: 'playlist:read',
  UPDATE: 'playlist:update',
  DELETE: 'playlist:delete',
}

export default PERMISSION
