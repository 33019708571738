import { useAuth } from '../../Context/AuthProvider.jsx'

/**
 * Date time component
 * @type {preact.FunctionComponent<{
 *   timestamp: number | null,
 *   asDate?: boolean,
 * }>}
 */
const DateTime = ({ timestamp, asDate = false }) => {
  const { user, organisation } = useAuth()

  if (timestamp === null) {
    return <>-</>
  }

  const language = user.language ?? organisation?.language ?? undefined
  const date = new Date(timestamp * 1_000)

  return (
    <>
      {asDate
        ? date.toLocaleDateString(language)
        : date.toLocaleString(language)
      }
    </>
  )
}

export default DateTime
