/** @type {Number} */
let uniqueId = 0

/**
 * Get unique id
 * @return {Number}
 */
export function getUniqueId() {
  return ++uniqueId
}
