import { useContext } from 'preact/hooks'
import { createContext } from 'preact'

import { useIndex } from '../Hooks/Api/useRestApi.js'
import { useAuth } from './AuthProvider.jsx'
import LoadingOverlay from '../Components/LoadingOverlay/LoadingOverlay.jsx'

import PERMISSION from '../Pages/Organisation/organisation.permission.js'

/**
 * @typedef { import('../Hooks/Api/useRestApi.js').IndexResponse<App.Entity.Organisation> } OrganisationIndexResponse
 */

/**
 * @typedef {OrganisationIndexResponse & { find: (id: number | null) => App.Entity.Organisation|undefined }} OrganisationProviderValue
 */

/**
 * Context object
 */
export const OrganisationsContext = createContext(/** @type {OrganisationProviderValue|null} */(null))

OrganisationsContext.displayName = 'OrganisationsContext'

/**
 * Hook
 * @return {OrganisationProviderValue}
 */
export function useOrganisations() {
  const value = useContext(OrganisationsContext)

  if (!value) {
    throw new Error('Organisations context has not been provided')
  }

  return value
}

/** @type {App.Entity.Organisation[]} */
const initialData = []

/**
 * Provider
 * Organisations preloader
 * @type {preact.FunctionComponent}
 */
export function OrganisationsProvider({ children }) {
  const { isGranted } = useAuth()

  // Fetch items when user has appropriate permission
  const {
    items = initialData,
    isLoading,
    error,
    mutate,
  } = useIndex('/organisations', true, undefined, isGranted(PERMISSION.INDEX))

  return (
    <OrganisationsContext.Provider value={{
      items,
      isLoading,
      error,
      mutate,
      find: (id) => id ? items.find(item => item.id === id) : undefined,
    }}>
      {children}
      <LoadingOverlay loadingError={error} isLoading={isLoading} />
    </OrganisationsContext.Provider>
  )
}
