import { ApiConfigProvider } from './ApiConfigProvider.jsx'
import { AuthProvider } from './AuthProvider.jsx'
import { PackagesConfigProvider } from './PackagesConfigProvider.jsx'

export default function AppProviders({ children }) {
  return (
    <ApiConfigProvider baseUrl={import.meta.env.VITE_API_URL}>
      <AuthProvider>
        <PackagesConfigProvider config={{
          imageServerUrl: import.meta.env.VITE_IMAGE_SERVER_URL,
          videoServerUrl: import.meta.env.VITE_VIDEO_SERVER_URL,
        }}>
          { children }
        </PackagesConfigProvider>
      </AuthProvider>
    </ApiConfigProvider>
  )
}
