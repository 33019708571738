import { useState, useEffect } from 'preact/hooks'
import { Formik } from 'formik'

import { withPagePermissions } from '../../Components/Guard/index.jsx'
import PERMISSION from './organisation.permission.js'

import { useRead } from '../../Hooks/Api/useRestApi.js'

import OrganisationForm from './OrganisationForm.jsx'

import { initialData } from './organisationStore.js'

/**
 * @typedef { import('../../Hooks/Api/useRestApi.js').ReadResponse<App.Entity.Organisation> } OrganisationReadResponse
 */

/**
 * Organisation read item page
 *
 * @type {preact.FunctionComponent<{ id: string }>}
 */
function OrganisationRead({
  params,
}) {
  const id = Number.parseInt(params.id)

  /** @type {OrganisationReadResponse} */
  const { item, isLoading, error: loadingError } = useRead('/organisations', id)

  const [ formItem, setFormItem ] = useState({ ...initialData, id })
  const [ isHydrated, setIsHydrated ] = useState(false)

  useEffect(() => {
    if (isHydrated) {
      return
    }

    if (item) {
      setFormItem(item)
      setIsHydrated(true)
    }
  }, [item])

  return (
    <Formik
      initialValues={formItem}
      enableReinitialize={true}
      onSubmit={undefined}
    >
      <OrganisationForm
        readOnly
        id={id}
        loadingError={loadingError}
        isLoading={isLoading}
      />
    </Formik>
  )
}

export default withPagePermissions(OrganisationRead, [PERMISSION.READ])
