import { Component, createRef } from 'preact'

import { html } from 'htm/preact'

import classNames from 'classnames'

import { useTranslator } from '@eo-locale/preact'

import withTranslationsProvider from './withTranslationsProvider.js'

import { formControlInvalid } from '../utils/constraintValidation.js'

import CONTENT_TYPE from './../Constants/ContentType.js'

// Utilities
import {
  parseDuration,
  formatDuration,
} from '../utils/date.js'

import {
  getDuration as getPlaylistRowsDuration,
  getCount as getPlaylistRowsCount,
} from '../utils/playlistRows.js'

import compilePlaylistItems from '../utils/compile.js'

import ScreenSaverRange from './ScreenSaverRange.js'

// Option components
import AirlyStationOptionsComponent from './Options/AirlyStation.js'
import BiStationOptionsComponent from './Options/BiStation.js'
import BiStationsOptionsComponent from './Options/BiStations.js'
import CountdownOptionsComponent from './Options/Countdown.js'
import ImageOptionsComponent from './Options/Image.js'
import ReferenceOptionsComponent from './Options/Reference.js'
import SyngeosStationOptionsComponent from './Options/SyngeosStation.js'
import SyngeosStationsOptionsComponent from './Options/SyngeosStations.js'
import TextOptionsComponent from './Options/Text.js'
import VideoOptionsComponent from './Options/Video.js'
import VideoPlaylistOptionsComponent from './Options/VideoPlaylist.js'
import WebsiteOptionsComponent from './Options/Website.js'
import YoutubePlaylistOptionsComponent from './Options/YoutubePlaylist.js'

import {
  parse as referenceParse,
  format as referenceFormat,
  create as referenceCreate,
} from './Options/Reference/Converter.js'

/**
 * @typedef { import('preact').ComponentClass } ComponentClass
 * @typedef { import('preact').FunctionComponent } FunctionComponent
 * @typedef { ComponentClass | FunctionComponent } ComponentType
 */

/**
 * @typedef { import('preact').RefObject<HTMLInputElement[]> } InputElementsRef
 * @typedef { import('preact').RefObject<reportValidityInterface[]>} reportValidityInterfaceRef
 */

/**
 * @typedef { import('./ScreenSaverRange.js').ScreenSaverRange } ScreenSaverRange
 */

/**
 * @typedef {Object} ComponentProps
 * @property {string} [language]
 * @property {string} [inputName]
 * @property {CONTENT_TYPE[]} [includeTypes]
 * @property {CONTENT_TYPE[]} [disabledTypes]
 * @property {Record<'biStation'|'syngeosStation'|'image'|'video', object>} optionConfig
 * @property {PlaylistItem[]} [items]
 * @property {ScreenSaverRange|null} [screenSaverRange]
 * @property {PlaylistItem} [defaultItem]
 * @property {boolean} [isDebug]
 * @property {function|null} [onChange]
 * @property {function|null} [onScreenSaverRangeChange]
 */

/**
 * @typedef {Object} ComponentState
 * @property {PlaylistRow[]} rows
 * @property {ScreenSaverRange|null} screenSaverRange
 * @property {Error|null} error
 */

/**
 * @typedef {Object} PlaylistItem
 * @property {number} duration
 * @property {CONTENT_TYPE} type
 * @property {Object|undefined} options
 * @property {string|null} label
 * @property {boolean} [disabled] - Extra property N/A at client
 */

/**
 * @typedef {Object} PlaylistRow
 * @property {number} [uid]
 * @property {PlaylistItem} item
 * @property {OptionComponentDefinition} [def]
 * @property {boolean} isSelected
 * @property {boolean} isOpen
 */

/**
 * @typedef {function(PlaylistRow[]): PlaylistRow[]} rowParseInterface
 * @typedef {function(PlaylistRow[]): PlaylistRow[]} rowFormatInterface
 * @typedef {function(PlaylistRow): PlaylistRow} rowCreateInterface
 */

/**
 * @typedef {(options: Object) => void} onChange - On value change callback used in option components
 */

/**
 * @typedef {() => boolean|undefined} reportValidityInterface
 * @typedef {(callback: reportValidityInterface) => void} setReportValidityInterface
 */

/**
 * @typedef {Object} OptionComponentDefinition
 * @property {string} title
 * @property {string} icon
 * @property {ComponentType & { }} [OptionsComponent]
 * @property {Object} [config]
 */

/**
 * Playlist editor
 * @extends {Component<ComponentProps, ComponentState>}
 */
class PlaylistEditor extends Component {
  /**
   * @inheritdoc
   * @return {ComponentProps}
   */
  static get defaultProps() {
    return {
      language: 'pl',
      inputName: 'form[playlist]',
      includeTypes: undefined,
      disabledTypes: [],
      optionConfig: {
        biStation: { items: [] },
        syngeosStation: { items: [] },
        image: { apiUrl: undefined, apiBearerToken: undefined },
        video: { apiUrl: undefined },
      },
      items: [],
      screenSaverRange: null,
      defaultItem: {
        type: CONTENT_TYPE.CLOCK,
        options: undefined,
        duration: 60,
        label: null,
        disabled: false,
      },
      isDebug: false,
      onChange: null,
    }
  }

  /**
   * @inheritdoc
   * @param {ComponentProps} props
   */
  constructor(props) {
    super(props)

    /** @type {ComponentState} */
    const state = {
      rows: [],
      screenSaverRange: props.screenSaverRange,
      error: null,
    }

    /** @type {rowParseInterface[]} - Parsers */
    this.rowParsers = [ referenceParse ]

    /** @type {rowFormatInterface[]} - Formatters */
    this.rowFormatters = [ referenceFormat ]

    /** @type {rowCreateInterface[]} - Creators */
    this.rowCreators = [ referenceCreate ]

    /** @type {InputElementsRef} */
    this.inputDurationsRef = createRef()
    this.inputDurationsRef.current = []

    /** @type {reportValidityInterfaceRef} */
    this.optionReportValiditiesRef = createRef()
    this.optionReportValiditiesRef.current = []

    /** @type {Object<CONTENT_TYPE, any>} */
    const optionConfig = props.optionConfig

    /** @type {Array<[CONTENT_TYPE, OptionComponentDefinition]>} */
    const contentTypes = [
      [CONTENT_TYPE.AIRLY_STATION,    { title: 'option.airlyStation.title',    icon: 'location',     OptionsComponent: AirlyStationOptionsComponent }],
      [CONTENT_TYPE.BI_STATION,       { title: 'option.biStation.title',       icon: 'location',     OptionsComponent: BiStationOptionsComponent, config: optionConfig['biStation'] }],
      [CONTENT_TYPE.BI_STATIONS,      { title: 'option.biStations.title',      icon: 'location',     OptionsComponent: BiStationsOptionsComponent, config: optionConfig['biStation'] }],
      [CONTENT_TYPE.CLOCK,            { title: 'option.clock.title',           icon: 'clock',        OptionsComponent: undefined }],
      [CONTENT_TYPE.COUNTDOWN,        { title: 'option.countdown.title',       icon: 'future',       OptionsComponent: CountdownOptionsComponent }],
      [CONTENT_TYPE.IMAGE,            { title: 'option.image.title',           icon: 'image',        OptionsComponent: ImageOptionsComponent, config: optionConfig['image'] }],
      [CONTENT_TYPE.REFERENCE,        { title: 'option.reference.title',       icon: 'link',         OptionsComponent: ReferenceOptionsComponent,   config: { rows: [] } }],
      [CONTENT_TYPE.SCREENSAVER,      { title: 'option.screensaver.title',     icon: 'file',         OptionsComponent: undefined }],
      [CONTENT_TYPE.SYNGEOS_STATION,  { title: 'option.syngeosStation.title',  icon: 'location',     OptionsComponent: SyngeosStationOptionsComponent, config: optionConfig['syngeosStation'] }],
      [CONTENT_TYPE.SYNGEOS_STATIONS, { title: 'option.syngeosStations.title', icon: 'location',     OptionsComponent: SyngeosStationsOptionsComponent, config: optionConfig['syngeosStation'] }],
      [CONTENT_TYPE.TEXT,             { title: 'option.text.title',            icon: 'file-text',    OptionsComponent: TextOptionsComponent }],
      [CONTENT_TYPE.VIDEO,            { title: 'option.video.title',           icon: 'video-camera', OptionsComponent: VideoOptionsComponent, config: optionConfig['video'] }],
      [CONTENT_TYPE.VIDEO_PLAYLIST,   { title: 'option.videoPlaylist.title',   icon: 'video-camera', OptionsComponent: VideoPlaylistOptionsComponent, config: optionConfig['video'] }],
      [CONTENT_TYPE.WEBSITE,          { title: 'option.website.title',         icon: 'bookmark',     OptionsComponent: WebsiteOptionsComponent }],
      [CONTENT_TYPE.YOUTUBE_PLAYLIST, { title: 'option.youtubePlaylist.title', icon: 'youtube',      OptionsComponent: YoutubePlaylistOptionsComponent, config: { placeholder: 'https://www.youtube.com/watch?v=bIk-hZ-vXPo' } }],
    ]

    // Register options components
    /** @type {Map<CONTENT_TYPE, OptionComponentDefinition>} */
    this.contentTypes = new Map(
      this.props.includeTypes
        ? contentTypes.filter(([ contentType ]) => this.props.includeTypes.includes(contentType))
        : contentTypes
    )

    // Parse input
    if (props.items.length) {
      try {
        state.rows = this._createRows(props.items)
      } catch (error) {
        state.error = error
      }
    }

    this.state = state

    // Bind events
    this.handleItemDurationChange = this.handleItemDurationChange.bind(this)
    this.handleItemLabelChange = this.handleItemLabelChange.bind(this)
    this.handleItemTypeChange = this.handleItemTypeChange.bind(this)
    this.handleItemToggleOptions = this.handleItemToggleOptions.bind(this)
    this.handleItemOptionsChange = this.handleItemOptionsChange.bind(this)

    this.handleButtonAddClick = this.handleButtonAddClick.bind(this)
    this.handleButtonRemoveClick = this.handleButtonRemoveClick.bind(this)
    this.handleButtonToggleClick = this.handleButtonToggleClick.bind(this)
    this.handleButtonUpClick = this.handleButtonUpClick.bind(this)
    this.handleButtonDownClick = this.handleButtonDownClick.bind(this)

    this.handleDisableSelectedClick = this.handleDisableSelectedClick.bind(this)

    if (props.isDebug) {
      // @ts-ignore
      window._DEBUG = this
    }
  }

  /**
   * @inheritdoc
   * @param {ComponentProps} prevProps
   * @param {ComponentState} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    // Overwrite items from props
    // Note: This overwrites internal state (ie. row.isOpen)
    if (this.props.items !== prevProps.items) {
      this.setState({ rows: this._createRows(this.props.items) })
    }

    // Update input duration refs array length
    if (this.state.rows.length !== prevState.rows.length) {
      const currentRowsLength = this.state.rows.length

      this.inputDurationsRef.current = this.inputDurationsRef.current.slice(0, currentRowsLength)
      this.optionReportValiditiesRef.current = this.optionReportValiditiesRef.current.slice(0, currentRowsLength)
    }

    if (this.state.screenSaverRange !== prevState.screenSaverRange && this.props.onScreenSaverRangeChange) {
      this.props.onScreenSaverRangeChange(this.state.screenSaverRange)
    }
  }

  /**
   * Handle item duration change
   * @param {number} index - Item index
   * @param {string} rawDuration - Item duration
   * @return {void}
   */
  handleItemDurationChange(index, rawDuration) {
    let duration

    try {
      duration = parseDuration(rawDuration) / 1e3
    } catch (error) {
      // Revert
      duration = this.state.rows[index].item.duration
    }

    this._updateItem(index, {
      duration
    })
  }

  /**
   * Handle item label change
   * @param {number} index
   * @param {string} rawLabel
   * @return {void}
   */
  handleItemLabelChange(index, rawLabel) {
    this._updateItem(index, {
      label: rawLabel || null
    })
  }

  /**
   * handle item type change
   * @param {number} index - Item index
   * @param {CONTENT_TYPE} rawType - Item type
   * @return {void}
   */
  handleItemTypeChange(index, rawType) {
    // Validate type
    if (!this.contentTypes.has(rawType)) {
      return
    }

    const type = rawType
    const def = this.contentTypes.get(type)

    const rows = this.state.rows.map((cmpRow, cmpIndex) =>
      index === cmpIndex
        ? {
          ...cmpRow,
          item: {
            ...cmpRow.item,
            type,
            // Default options must be undefined so Option component uses it's defaultProps
            options: undefined,
          },
          def,
          // Open options so invalid ones are not added
          isOpen: def && def.OptionsComponent !== undefined,
        }
        : cmpRow
    )

    this._updateRows(rows)
  }

  /**
   * Toggle options
   * @param {number} index
   * @return {void}
   */
  handleItemToggleOptions(index) {
    const isOpen = this.state.rows[index].isOpen
    const optionReportValidity = this.optionReportValiditiesRef.current[index]

    // Prevent closign when options are not valid
    if (
      isOpen &&
      optionReportValidity &&
      !optionReportValidity()
    ) {
      return
    }

    const rows = this.state.rows.map((cmpRow, cmpIndex) =>
      index === cmpIndex
        ? { ...cmpRow, isOpen: !cmpRow.isOpen }
        : cmpRow
    )

    this._updateRows(rows)
  }

  /**
   * Handle options change
   * @param {number} index - Item index
   * @param {Object} options - Item options
   * @return {void}
   */
  handleItemOptionsChange(index, options) {
    this._updateItem(index, {
      options
    })
  }

  /**
   * Handle selection toggle change
   * @param {number} index
   * @return {void}
   */
  handleItemSelectToggle(index) {
    const rows = this.state.rows.map((cmpRow, cmpIndex) =>
      index === cmpIndex
        ? { ...cmpRow, isSelected: !cmpRow.isSelected }
        : cmpRow
    )

    this._updateRows(rows)
  }

  /**
   * Disable selected rows
   * @return {void}
   */
  handleDisableSelectedClick() {
    const rows = this.state.rows.map(cmpRow =>
      cmpRow.isSelected
        ? { ...cmpRow, isSelected: false, item: { ...cmpRow.item, disabled: true } }
        : cmpRow
    )

    this._updateRows(rows)
  }

  /**
   * Handle button add click
   * @return {void}
   */
  handleButtonAddClick() {
    const lastRow = this.state.rows[this.state.rows.length - 1]

    /** @type {PlaylistItem} */
    let newItem = lastRow
      // Last item with reset options
      ? { ...lastRow.item, options: undefined }
      // Default
      : { ...this.props.defaultItem }

    // Apply creators
    const row = this.rowCreators.reduce(
      (row, creator) => creator(row),
      this._createRow(newItem, true)
    )

    this._updateRows([
      ...this.state.rows,
      row,
    ])
  }

  /**
   * Handle button remove click
   * @todo Should use onRemove callback when changing item type too
   * @param {number} index - Item index
   * @return {void}
   */
  handleButtonRemoveClick(index) {
    const rows = this.state.rows.filter((cmpRow, cmpIndex) =>
      index !== cmpIndex
    )

    this._updateRows(rows)
  }

  /**
   * Handle button toggle click
   * @param {number} index - Item index
   * @return {void}
   */
  handleButtonToggleClick(index) {
    this._updateItem(index, {
      disabled: !this.state.rows[index].item.disabled
    })
  }

  /**
   * Handle button up click
   * @param {number} index
   * @return {void}
   */
  handleButtonUpClick(index) {
    this._updateItemOrder(index, index - 1)
  }

  /**
   * Handle button down click
   * @param {number} index
   * @return {void}
   */
  handleButtonDownClick(index) {
    this._updateItemOrder(index, index + 1)
  }

  /**
   * Update item order
   * @param {number} fromIndex
   * @param {number} toIndex
   * @return {void}
   */
  _updateItemOrder(fromIndex, toIndex) {
    // Clone
    const rows = this.state.rows.slice()

    // Add
    rows.splice(
      toIndex,
      0,
      // Remove
      ...rows.splice(fromIndex, 1)
    )

    this._updateRows(rows)
  }

  /**
   * Update item
   * @param {number} index - Item index
   * @param {Object} props - Playlist item props to merge
   * @return {void}
   */
  _updateItem(index, props) {
    const rows = this.state.rows.map((cmpRow, cmpIndex) =>
      index === cmpIndex
        ? {
          ...cmpRow,
          // Type changed
          def: props.type
            ? this.contentTypes.get(props.type)
            : cmpRow.def
          ,
          // Update item with new props
          item: { ...cmpRow.item, ...props }
        }
        : cmpRow
    )

    this._updateRows(rows)
  }

  /**
   * Update rows
   * @param {PlaylistRow[]} rows
   * @return {void}
   */
  _updateRows(rows) {
    // Alternative: Pass state in render method
    // Note: Need state update afterwards
    const referenceOptionCmpDef = this.contentTypes.get(CONTENT_TYPE.REFERENCE)

    if (referenceOptionCmpDef) {
      referenceOptionCmpDef.config.rows = rows
    }

    this.setState({ rows })

    // Note: This doesn't pass screen saver range
    if (this.props.onChange) {
      this.props.onChange(
        this.formatRows(rows)
      )
    }
  }

  /**
   * @inheritdoc
   */
  render() {
    const translator = useTranslator(this.props.language)

    // Error
    if (this.state.error) {
      return html`
        <div className="uk-alert uk-alert-danger">
          <p>
            ${this.state.error.message}
          </p>
        </div>
      `
    }

    // Append start time, easier to compute on render than on varius updates
    const playlistRows = this.state.rows.map((row, index) => ({
      ...row,
      startTime: getPlaylistRowsDuration(this.state.rows.slice(0, index))
    }))

    const totalEnabledDuration = getPlaylistRowsDuration(this.state.rows)
    const totalEnabledCount = getPlaylistRowsCount(this.state.rows)

    const totalDuration = getPlaylistRowsDuration(this.state.rows, true)
    const totalCount = getPlaylistRowsCount(this.state.rows, true)

    return html`
      <div>
        <table className="uk-table uk-table-divider uk-table-striped uk-table-small uk-table-responsive  uk-background-default">
          <thead>
            <tr>
              <th
                className="uk-table-shrink"
                title=${translator.translate('playlistEditor.header.index.hint')}
              >
                ${translator.translate('playlistEditor.header.index.text')}
              </th>
              <th
                className="uk-table-shrink"
                title=${translator.translate('playlistEditor.header.start.hint')}
              >
                ${translator.translate('playlistEditor.header.start.text')}
              </th>
              <th
                className="uk-table-shrink"
                title=${translator.translate('playlistEditor.header.duration.hint')}
              >
                ${translator.translate('playlistEditor.header.duration.text')}
              </th>
              <th
                className="uk-table-shrink"
                title=${translator.translate('playlistEditor.header.label.hint')}
              >
                ${translator.translate('playlistEditor.header.label.text')}
              </th>
              <th
                className="uk-width-small"
                title=${translator.translate('playlistEditor.header.type.hint')}
              >
                ${translator.translate('playlistEditor.header.type.text')}
              </th>
              <th className="uk-table-expand">
                ${translator.translate('playlistEditor.header.options.text')}
              </th>
              <th className="uk-table-shrink">
                ${translator.translate('playlistEditor.header.actions.text')}
              </th>
            </tr>
          </thead>
          <tbody>
            ${playlistRows.map((row, index) => html`
              <tr className=${classNames('bip-table-row', {
                'bip-table-row--disabled': row.item.disabled,
                'bip-table-row--selected': row.isSelected,
              })}>
                <!-- Index -->
                <td className="bip-code uk-text-right@m">
                  <label>
                    ${index + 1}.
                    <input
                      className="uk-checkbox uk-margin-small-left"
                      type="checkbox"
                      checked=${row.isSelected}
                      onChange=${() => this.handleItemSelectToggle(index)}
                    />
                  </label>
                </td>
                <!-- Start at -->
                <td className="bip-code uk-text-right@m">
                  ${formatDuration(row.startTime)}
                </td>
                <!-- Duration -->
                <td>
                  <input
                    ref=${element => this.inputDurationsRef.current[index] = element}
                    className=${classNames('uk-input uk-form-small uk-form-width-small uk-text-right', {
                      'uk-form-danger': formControlInvalid(this.inputDurationsRef.current[index]),
                    })}
                    type="text"
                    value=${formatDuration(row.item.duration * 1e3)}
                    required="required"
                    inputmode="numeric"
                    onChange=${event => this.handleItemDurationChange(index, event.target.value)}
                  />
                </td>
                <!-- Label -->
                <td>
                  <input
                    className="uk-input uk-form-small uk-form-width-xsmall"
                    type="text"
                    value=${row.item.label ?? ''}
                    inputmode="text"
                    onChange=${event => this.handleItemLabelChange(index, event.target.value)}
                  />
                </td>
                <!-- Type -->
                <td className="uk-text-nowrap">
                  <div className="uk-inline uk-form-width-medium">
                    <span
                      className="uk-form-icon uk-form-icon-flip uk-icon"
                      data-uk-icon=${`icon: ${this.contentTypes.get(row.item.type).icon || 'file'}`}
                    ></span>
                    <select
                      className="uk-select uk-form-small"
                      value=${row.item.type}
                      onChange=${event => this.handleItemTypeChange(index, event.target.value)}
                    >
                      ${Array.from(this.contentTypes).map(([contentType, definition]) => html`
                        <option
                          value=${contentType}
                          disabled=${this.props.disabledTypes.includes(contentType)}
                        >
                          ${translator.translate(definition.title, { defaultMessage: definition.title })}
                        </option>
                      `)}
                    </select>
                  </div>
                </td>
                <!-- Options -->
                <td>
                  ${row.def && row.def.OptionsComponent
                    ? html`
                      <button
                        className="uk-button uk-button-default uk-button-small uk-width-1-1"
                        type="button"
                        title=${row.isOpen
                          ? translator.translate('playlistEditor.action.options.hide.hint')
                          : translator.translate('playlistEditor.action.options.show.hint')
                        }
                        onCLick=${() => this.handleItemToggleOptions(index)}
                      >
                        <span
                          className="uk-icon"
                          data-uk-icon="icon: pencil"
                        ></span>
                        <span className="uk-text-middle uk-margin-small-left">
                          ${row.isOpen
                            ? translator.translate('playlistEditor.action.options.hide.text')
                            : translator.translate('playlistEditor.action.options.show.text')
                          }
                        </span>
                      </button>
                    `
                    : html`
                      <span className="uk-text-muted">${'N/A'}</span>
                      `
                  }
                  ${row.def && row.def.OptionsComponent && row.isOpen && html`
                    <div className="uk-card uk-card-default uk-card-small uk-card-body  uk-margin-top">
                      <${row.def.OptionsComponent}
                        options=${row.item.options}
                        config=${row.def.config}
                        setReportValidity=${reportValidity => this.optionReportValiditiesRef.current[index] = reportValidity}
                        onChange=${options => this.handleItemOptionsChange(index, options)}
                      />
                    </div>
                  `}
                </td>
                <!-- Action buttons -->
                <td>
                  <fieldset className="uk-fieldset uk-text-right uk-text-left@m">
                    <div className="uk-button-group">
                      <!-- Up (disabled on first item) -->
                      <button
                        className="uk-button uk-button-default uk-button-small"
                        type="button"
                        disabled=${!index}
                        title=${translator.translate('playlistEditor.action.order.moveUp.hint')}
                        onClick=${() => this.handleButtonUpClick(index)}
                      >
                        <span
                          className="uk-icon"
                          data-uk-icon="icon: chevron-up"
                        ></span>
                      </button>
                      <!-- Down (disabled on last item) -->
                      <button
                        className="uk-button uk-button-default uk-button-small"
                        type="button"
                        disabled=${index === this.state.rows.length - 1}
                        title=${translator.translate('playlistEditor.action.order.moveDown.hint')}
                        onClick=${() => this.handleButtonDownClick(index)}
                      >
                        <span
                          className="uk-icon"
                          data-uk-icon="icon: chevron-down"
                        ></span>
                      </button>
                      <!-- Toggle (Disable/ Enable) -->
                      <button
                        className="uk-button uk-button-default uk-button-small"
                        type="button"
                        title=${row.item.disabled
                          ? translator.translate('playlistEditor.action.state.enable.hint')
                          : translator.translate('playlistEditor.action.state.disable.hint')
                        }
                        onClick=${() => this.handleButtonToggleClick(index)}
                      >
                        <span
                          className="uk-icon"
                          data-uk-icon=${row.item.disabled
                            ? 'close'
                            : 'check'
                          }
                        ></span>
                      </button>
                      <!-- Remove -->
                      <button
                        className="uk-button uk-button-default uk-button-small"
                        type="button"
                        title=${translator.translate('playlistEditor.action.remove.hint')}
                        onClick=${() => this.handleButtonRemoveClick(index) }
                      >
                        <span
                          className="uk-icon"
                          data-uk-icon="icon: trash"
                        ></span>
                      </button>
                    </div>
                  </fieldset>
                </td>
              </tr>
            `)}
          </tbody>
          <tfoot>
            <tr>
              <!-- Count -->
              <td className="bip-code uk-text-right@m">
                <span className="uk-text-emphasis">${totalEnabledCount}</span>
                <span className="uk-text-muted">/ ${totalCount}</span>
              </td>
              <!-- Start at -->
              <td></td>
              <!-- Duration -->
              <td className="bip-code uk-text-right@m">
                <span className="uk-text-emphasis">
                  ${formatDuration(totalEnabledDuration)}
                </span>
                <span className="uk-text-muted">
                  / ${formatDuration(totalDuration)}
                </span>
              </td>
              <!-- Type, Label & Options -->
              <td colspan="3"></td>
              <!-- Action buttons -->
              <td>
                <!-- Add -->
                <button
                  className="uk-button uk-button-default uk-button-small uk-width-1-1 uk-text-nowrap"
                  type="button"
                  onClick=${this.handleButtonAddClick}
                >
                  <span
                    className="uk-icon"
                    data-uk-icon="icon: plus-circle"
                  ></span>
                  <span className="uk-text-middle uk-margin-small-left">
                    ${translator.translate('playlistEditor.action.add.text')}
                  </span>
                </button>
                <!-- Selection -->
                <div className="uk-width-1-1 uk-margin-small-top">
                  <button
                    className="uk-button uk-button-default uk-button-small uk-width-1-1"
                    type="button"
                  >
                    <span
                      className="uk-icon"
                      data-uk-icon="icon: list"
                    ></span>
                    <span className="uk-text-middle uk-margin-small-left">
                      ${translator.translate('playlistEditor.action.selection.text')}
                    </span>
                  </button>
                  <div
                    className="uk-padding-small"
                    data-uk-dropdown="offset: 0;"
                  >
                    <ul className="uk-nav uk-dropdown-nav">
                      <li>
                        <!-- Disable -->
                        <button
                          className="uk-button uk-button-text"
                          type="button"
                          onClick=${this.handleDisableSelectedClick}
                        >
                          <span
                            className="uk-icon"
                            data-uk-icon="icon: close"
                          ></span>
                          <span className="uk-text-middle uk-margin-small-left">
                            ${translator.translate('playlistEditor.action.selectionItems.disable.text')}
                          </span>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="7">
                <!-- Screen saver range -->
                <${ScreenSaverRange}
                  value=${this.state.screenSaverRange}
                  onChange=${screenSaverRange => this.setState({ screenSaverRange })}
                />
              </td>
            </tr>
          </tfoot>
        </table>
        <!-- Serialized output -->
        ${this.props.isDebug
          ? html`
              <textarea
                className="uk-textarea uk-resize-vertical uk-text-small uk-background-muted"
                name="${this.props.inputName}"
                readonly="readonly"
                rows=${25}
                style="font-size: .75em; line-height: 1.2"
              >${JSON.stringify({
                  items: this.formatRows(this.state.rows),
                  screenSaverRange: this.state.screenSaverRange,
                }, null, 2)}
              </textarea>
            `
          : html`
              <input
                name=${this.props.inputName}
                type="hidden"
                value=${JSON.stringify({
                  items: this.formatRows(this.state.rows),
                  screenSaverRange: this.state.screenSaverRange,
                })}
              />
            `
        }
      </div>
    `
  }

  /**
   * Create new row
   * @param {PlaylistItem} item
   * @param {boolean} isOpen
   * @return {PlaylistRow}
   */
  _createRow(item, isOpen = false) {
    const def = this.contentTypes.get(item.type)

    return ({
      item,
      def,
      // startTime: undefined,
      isSelected: false,
      isOpen: def && def.OptionsComponent ? isOpen : false,
    })
  }

  /**
   * Update rows when props updated
   * @param {PlaylistItem[]} items
   * @return {PlaylistRow[]}
   */
  _createRows(items) {
    const rows = this.parseItems(items)

    // Update items in reference def before first render
    const referenceOptionCmpDef = this.contentTypes.get(CONTENT_TYPE.REFERENCE)

    if (referenceOptionCmpDef) {
      referenceOptionCmpDef.config.rows = rows
    }

    return rows
  }

  /**
   * Parse items to rows
   * @param {PlaylistItem[]} apiItems
   * @return {PlaylistRow[]}
   */
  parseItems(apiItems) {

    const rows = apiItems
      // Only available types
      .filter(apiItem => this.contentTypes.has(apiItem.type))
      // Wrap
      .map(apiItem => this._createRow(apiItem))

    // Parse
    return this.rowParsers.reduce(
      (rows, parser) => Array.from(parser(rows)),
      rows
    )
  }

  /**
   * Format rows to items
   * @param {PlaylistRow[]} rows
   * @return {PlaylistItem[]}
   */
  formatRows(rows) {
    return this
      // Format
      .rowFormatters.reduce(
        (rows, formatter) => Array.from(formatter(rows)),
        rows
      )
      // Unwrap
      .map(row => row.item)
  }

  /**
   * Compile items, use either as middleware by API or by client
   * Remove disabled while keeping reference index
   * @return { import('../utils/compile.js').PlaylistItemClient[] }
   */
  compileItems() {
    return compilePlaylistItems(this.formatRows(this.state.rows))
  }
}

export default withTranslationsProvider(PlaylistEditor)
