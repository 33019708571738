/**
 * Format file mime type
 * @param {string} mimeType
 * @return {string}
 */
export function formatFileMime(mimeType) {
  return mimeType.replace(/^(image|video)\/(\w+).*$/, '$2')
}

// Units
const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

/**
 * Format file size
 * @param {number} size - In bytes
 * @param {number} [fractionDigits]
 * @return {string}
 */
export function formatFileSize(size, fractionDigits = 2) {
  let formattedSize = size
  let unitIndex = 0

  while (
    formattedSize >= 1024 &&
    sizes[++unitIndex]
  ) {
    formattedSize = formattedSize / 1024
  }

  return `${formattedSize.toFixed(fractionDigits)} ${sizes[unitIndex]}`
}

/**
 * Format file timestamp
 * @param {number} timestamp - Timestamp in Unix format
 * @return {string}
 */
export function formatFileTimestamp(timestamp) {
  const date = new Date(timestamp * 1e3)

  return date.toLocaleString()
}
