import AbstractHttpError from './AbstractHttpError.js'

/**
 * Validation error (400)
 */
export default class ValidationError extends AbstractHttpError {
  /**
   * @inheritdoc
   * @param {string} message
   * @param {Response} [response]
   * @param {Object<string, string[]>} [context]
   */
  constructor(message = 'Bad request.', response = undefined, context = {}) {
    super(message, response, context)

    this.name = 'ValidationError'

    Object.setPrototypeOf(this, ValidationError.prototype)
  }
}
