/** @typedef { import('../../Constants/permission.js').Permission } Permission */

/** @enum {Permission} */
const PERMISSION = {
  INDEX: 'organisation:index',
  CREATE: 'organisation:create',
  READ: 'organisation:read',
  UPDATE: 'organisation:update',
  DELETE: 'organisation:delete',
}

export default PERMISSION
