import clsx from 'clsx'

/**
 * UIKit icon wrapper
 * @see https://getuikit.com/docs/icon
 *
 * @type {preact.FunctionComponent}
 * @param {Object} props
 * @param {string} props.icon
 * @param {number} [props.ratio]
 * @param {boolean} [props.link] - Use link modifier
 * @param {boolean} [props.button] - Use button modifier
 * @param {string} [props.tooltip] - Show tooltip
 * @param {string} [props.className] - Additional class name in a Classnames format
 */
const Icon = ({
  icon,
  ratio = 1,
  button = false,
  link = false,
  tooltip = undefined,
  className = undefined
}) => (
  <span
    className={clsx(
      'uk-icon', {
        'uk-icon-link': link,
        'uk-icon-button': button,
      },
      className,
    )}
    data-uk-icon={serializeUikitConfig({ icon, ratio })}
    data-uk-tooltip={tooltip ?? undefined}
  ></span>
)

/**
 * Serialize UIkit component configuration
 * @see https://getuikit.com/docs/javascript#component-configuration
 * @example
 * ```js
 * serializeUikitProps({ icon: 'check', ratio: 1 })
 * ```
 * @param {Record<string, any>} props
 * @return {string}
 * @note Using data-uk-icon breaks in uikit@3.16.4
 */
function serializeUikitConfig(props) {
  return JSON.stringify(props)
}

export default Icon
