import { html } from 'htm/preact'

import { useTranslator } from '@eo-locale/preact'

import Dialog from './Dialog.js'

/**
 * @typedef { import('./Dialog.js').handleSubmitInterface } handleSubmitInterface
 */

/**
 * @typedef {Object} ScreenSaverRange
 * @property {string} start
 * @property {string} end
 */

/**
 * @typedef {Object} ComponentProps
 * @property {ScreenSaverRange|null} value
 * @property {(screenSaverRange: ScreenSaverRange|null) => void} onChange
 */

/**
 * @param {ComponentProps} props
 */
export default function ScreenSaverRange({
  value = null,
  onChange = () => {},
}) {
  const translator = useTranslator()

  /**
   * Handle form submit
   * @type {handleSubmitInterface}
   */
  const handleSubmit = (formData) => { // eslint-disable-line no-unused-vars
    const start = /** @type {string} */ (formData.get('start'))
    const end = /** @type {string} */ (formData.get('end'))

    onChange(
      start && end
        ? { start, end }
        : null
    )
  }

  return (
    html`
      <${Dialog}
        title=${translator.translate('screenSaverRange.title')}
        button=${{
          title: translator.translate('screenSaverRange.action.change.hint'),
          text: value
            ? `${value.start} – ${value.end}`
            : translator.translate('screenSaverRange.action.change.text'),
          icon: 'clock',
        }}
        showConfirmButton=${true}
        showResetButton=${true}
        onSubmit=${handleSubmit}
      >
        <fieldset className="uk-fieldset">
          <div className="uk-form-horizontal">
            <div class="uk-margin">
              <label className="uk-form-label">
                ${translator.translate('screenSaverRange.field.start.text')}
              </label>
              <div className="uk-form-controls">
                <input
                  className="uk-input uk-form-small uk-form-width-small"
                  name="start"
                  type="time"
                  value=${value ? value.start : null}
                  inputmode="numeric"
                  step="60"
                />
              </div>
            </div>

            <div class="uk-margin">
              <label className="uk-form-label">
                ${translator.translate('screenSaverRange.field.end.text')}
              </label>
              <div className="uk-form-controls">
                <input
                  className="uk-input uk-form-small uk-form-width-small"
                  name="end"
                  type="time"
                  value=${value ? value.end : null}
                  inputmode="numeric"
                  step="60"
                />
              </div>
            </div>
          </div>
        </fieldset>
      </${Dialog}>
    `
  )
}
