import styles from './notfound.module.css'
import { useLocation } from 'wouter-preact'

/**
 * Not found page
 *
 * @type {preact.FunctionComponent}
 * @param {Object} props
 * @param {{path: string}} [props.params] - Route params
 */
const NotFound = props => {
  const [ location ] = useLocation()

  // Trim slash from beginning
  const path = location.replace(/^\/(.*)/, '$1')

  return (
    <section className={`uk-article ${styles.notfound}`}>
      <h1 className="uk-article-title">
        404: Not Found
      </h1>
      <p>
        {`The '${path}' route is gone :(`}
      </p>
    </section>
  )
}

export default NotFound
