import STATE from '../../Components/State/state.constants.js'
import { ROLES } from './User.constants.js'
import errorToStatusFactory from '../../Components/Form/errorToStatus.js'

/** @type {App.Entity.User} */
export const initialData = {
  id: null,
  email: '',
  password: null,
  passwordConfirm: null,
  name: '',
  organisationId: null,
  roles: [],
  language: null,
  state: STATE.ENABLED,
}

export const getStatusFromError = errorToStatusFactory(initialData)

/**
 * Transform form values to entity
 * @param {Object} values
 * @return {Omit<App.Entity.User, 'id'>}
 */
export function transformValues(values) {
  return {
    email: values.email,
    password: values.password || null,
    passwordConfirm: values.passwordConfirm || null,
    name: values.name,
    organisationId: values.organisationId ? Number.parseInt(values.organisationId) : null,
    roles: values.roles,
    language: values.language || null,
    state: Number.parseInt(values.state),
 }
}

/**
 * Validate form
 * @param {Object} values
 * @param {boolean} isNew
 * @returns {Object<string, string>}
 */
export function validate(values, isNew = true) {
  /** @type {Object<string, string>} */
  const errors = {}
  const item = transformValues(values)

  if (!item.email) {
    errors.email = 'required'
  }

  if (isNew && !item.password) {
    errors.password = 'required'
  }

  if (item.passwordConfirm !== item.password) {
    errors.passwordConfirm = 'equals,password'
  }

  if (!item.name) {
    errors.name = 'required'
  }

  if (!item.organisationId) {
    errors.organisationId = 'required'
  }

  if (!item.roles.length) {
    errors.roles = 'required'
  } else if (!item.roles.reduce((acc, role) => acc && ROLES.includes(role), true)) {
    errors.roles = 'in'
  }

  if (
    item.state !== STATE.ENABLED &&
    item.state !== STATE.DISABLED
  ) {
    errors.state = 'in'
  }

  return errors
}
