import { createElement } from 'preact'
import { TranslationsProvider } from '@eo-locale/preact'

import localeResources from '../i18n/resources/index.js'

/**
 * @typedef { import('preact').FunctionComponent } FunctionComponent
 * @typedef { import('preact').ComponentClass } ComponentClass
 * @typedef { import('./PlaylistEditor.js').ComponentProps } ComponentProps
 */

/**
 * Higher-Order component that uses translations provider with language that is set as wrapped component prop
 * @param {FunctionComponent | ComponentClass} WrappedComponent
 * @param {string} [fallbackLanguage]
 * @return {FunctionComponent}
 */
function withTranslationsProvider(
  WrappedComponent,
  fallbackLanguage = 'en'
) {
  // Format for display in DevTools
  const componentDisplayName = WrappedComponent.displayName || WrappedComponent.name || 'unknown'

  /**
   * @type {FunctionComponent}
   * @param {ComponentProps} props
   */
  const Wrapped = props => {
    const language = props.language || fallbackLanguage

    return createElement(
      TranslationsProvider, {
        language,
        locales: localeResources,
      },
      createElement(
        WrappedComponent,
        { language, ...props }
      )
    )
  }

  Wrapped.displayName = `withTranslationsProvider(${componentDisplayName})`

  return Wrapped
}

export default withTranslationsProvider
