import STATE from '../../Components/State/state.constants.js'
import errorToStatusFactory from '../../Components/Form/errorToStatus.js'

/** @type {App.Entity.Organisation} */
export const initialData = {
  id: null,
  hash: null,
  name: '',
  language: 'pl-PL',
  state: STATE.ENABLED,
  defaultPlaylistId: null,
  plan: {
    code: null,
    startedAt: null,
  }
}

export const getStatusFromError = errorToStatusFactory(initialData)

/**
 * @param {Object} values
 * @return {Omit<App.Entity.Organisation, 'id'>}
 */
export function transformValues(values) {
  return {
    hash: null,
    name: values.name,
    language: values.language,
    state: Number.parseInt(values.state),
    defaultPlaylistId: values.defaultPlaylistId ? Number.parseInt(values.defaultPlaylistId) : null,
    plan: {
      code: null,
      startedAt: null,
    },
  }
}

/**
 * @param {Object} values
 * @param {boolean} isNew
 * @return {Object<string, string>}
 */
export function validate(values, isNew = true) {
  /** @type {Object<string, string>} */
  const errors = {}
  const item = transformValues(values)

  if (!item.name) {
    errors.name = 'required'
  }

  if (!item.language) {
    errors.language = 'required'
  }

  if (
    item.state !== STATE.ENABLED &&
    item.state !== STATE.DISABLED
  ) {
    errors.state = 'in'
  }

  return errors
}
