import { Link } from 'wouter-preact'
import { connect, Form } from 'formik'

import routes from '../../routes.js'
import { useAuth } from '../../Context/AuthProvider.jsx'
import { withPagePermissions } from '../../Components/Guard/index.jsx'
import { GLOBAL_CONTEXT, PLAYLIST } from '../../Constants/permission.js'

import { useIndex } from '../../Hooks/Api/useRestApi.js'

import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb.jsx'
import LoadingOverlay from '../../Components/LoadingOverlay/LoadingOverlay.jsx'
import SelectOrganisation from '../../Components/Form/SelectOrganisation.jsx'
import ValidationMessages from '../../Components/Form/ValidationMessages.jsx'
import ENVIRONMENT from '../../Components/Environment/environment.constants.js'

/**
 * @typedef { import('../../Hooks/Api/useRestApi.js').IndexResponse<App.Entity.Playlist> } PlaylistIndexResponse
 */

/**
 * @type {preact.FunctionComponent}
 * @param {Object} props
 * @param {number|null} props.id
 * @param {Error} [props.loadingError]
 * @param {boolean} [props.isLoading]
 * @param {boolean} [props.isSyncing]
 * @param {boolean} [props.readOnly]
 * @param {import('formik').FormikContextType<any>} props.formik
 */
function ScreenForm({
  id,
  loadingError = undefined,
  isLoading = false,
  isSyncing = false,
  readOnly = false,
  formik,
}) {
  const { isGranted } = useAuth()

  /** @type {PlaylistIndexResponse} */
  const {
    items: playlists = [],
    isLoading: isLoadingPlaylists,
  } = useIndex('/playlists')

  return (
    <section className="uk-article">

      {/** Breadcrumbs */}
      <Breadcrumb items={[
        { text: 'Screens', path: routes.screen.index },
        { text: id ?? 'New item' },
      ]} />

      <Form className="uk-card uk-card-default uk-card-small uk-flex-inline uk-flex-column">
        <fieldset
          className="uk-fieldset"
          disabled={isLoading || isSyncing}
        >
          <div className="uk-card-header uk-flex uk-flex-between">
            <h1 className="uk-card-title uk-margin-remove">
              {id ? readOnly ? 'View Screen' : 'Edit Screen' : 'Create Screen' }
            </h1>

            {/** Actions */}
            <ul className="uk-iconnav">
              <li>
                <button
                  className="uk-icon"
                  type="submit"
                  disabled={readOnly}
                  data-uk-icon="icon: check"
                  data-uk-tooltip={'Save'}
                ></button>
              </li>
              <li>
                <Link
                  className="uk-icon"
                  href={routes.screen.index}
                  data-uk-icon="icon: close"
                  data-uk-tooltip={'Cancel'}
                />
              </li>
            </ul>
          </div>

          <div className="uk-card-body uk-form-horizontal">
            {/** PIN code */}
            {!id &&
              <div className="uk-margin">
                <label className="uk-form-label">{'PIN code'}</label>
                <div className="uk-form-controls">
                  <input
                    className="uk-input uk-form-width-small"
                    type="text"
                    placeholder={'1234abcd'}
                    pattern="[0-9a-f]{8}"
                    maxLength={8}
                    autoComplete="off"
                    style={{ fontFamily: 'Consolas, monaco, monospace' }}
                    disabled={readOnly}
                    {...formik.getFieldProps('pinCode')}
                  />
                  <ValidationMessages name="pinCode" />
                </div>
              </div>
            }

            {/** Label */}
            <div className="uk-margin">
              <label className="uk-form-label">{'Label'}</label>
              <div className="uk-form-controls">
                <input
                  className="uk-input uk-form-width-medium"
                  type="text"
                  placeholder={''}
                  disabled={readOnly}
                  {...formik.getFieldProps('label')}
                />
                <ValidationMessages name="label" />
              </div>
            </div>

            {/** Geo Coords */}
            <fieldset className="uk-margin uk-fieldset uk-form-vertical">
              <legend className="uk-form-label">{'Geo coords'}</legend>
              <div className="uk-form-controls">
                <div>
                  <input
                    className="uk-input uk-form-width-small"
                    type="number"
                    min="-90.0"
                    max="90.0"
                    step="any"
                    disabled={readOnly}
                    {...formik.getFieldProps('geoCoords[0]')}
                  />
                  <ValidationMessages name="geoCoords[0]" />
                  <input
                    className="uk-input uk-form-width-small"
                    type="number"
                    min="-180.0"
                    max="180.0"
                    step="any"
                    disabled={readOnly}
                    {...formik.getFieldProps('geoCoords[1]')}
                  />
                  <ValidationMessages name="geoCoords[1]" />
                </div>
              </div>
            </fieldset>

            {/** Organisation Id */ }
            {isGranted(GLOBAL_CONTEXT.ACCESS) &&
              <div className="uk-margin">
                <label className="uk-form-label">{'Organisation'}</label>
                <div className="uk-form-controls">
                  <SelectOrganisation name="organisationId" disabled={readOnly} />
                </div>
              </div>
            }

            {/** Playlist Id */ }
            <div className="uk-margin">
              <label className="uk-form-label">{'Playlist'}</label>
              <div className="uk-form-controls">
                <select
                  className="uk-select uk-form-width-medium"
                  multiple={true}
                  size={3}
                  disabled={isLoadingPlaylists || readOnly}
                  {...formik.getFieldProps('playlistIds')}
                >
                  {playlists.map(playlist =>
                    <option value={playlist.id} key={playlist.id}>
                      {playlist.label}
                    </option>
                  )}
                </select>
                <ValidationMessages name="playlistIds" />
              </div>
            </div>

            {/** Specs */}
            <div className="uk-magin">
              <label className="uk-form-label">{'Specifications'}</label>
              <div className="uk-form-controls">
                <textarea
                  className="uk-textarea uk-form-small uk-form-width-medium"
                  name="specs"
                  value={JSON.stringify(formik.values.specs)}
                  disabled={readOnly}
                  onChange={event => {
                    try {
                      // @ts-ignore
                      formik.setFieldValue('specs', JSON.parse(event.target.value))
                    } catch {}
                  }}
                  onBlur={formik.handleBlur}
                ></textarea>
                <ValidationMessages name="specs" />
              </div>
            </div>

            {/** Environment */}
            <div className="uk-margin">
              <label className="uk-form-label">{'Environment'}</label>
              <div className="uk-form-controls">
                <select
                  className="uk-select uk-form-width-medium"
                  disabled={readOnly}
                  {...formik.getFieldProps('environment')}
                >
                  <option value={ENVIRONMENT.PRODUCTION}>
                    {'Production'}
                  </option>
                  <option value={ENVIRONMENT.STAGING}>
                    {'Staging'}
                  </option>
                </select>
                <ValidationMessages name="environment" />
              </div>
            </div>

          </div>
        </fieldset>

        {/** Overlays */}
        <LoadingOverlay
          loadingError={loadingError}
          isLoading={isLoading || isLoadingPlaylists}
        />

      </Form>
    </section>
  )
}

export default withPagePermissions(connect(ScreenForm), [PLAYLIST.INDEX])
