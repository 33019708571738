import { Component } from 'preact'

import { html } from 'htm/preact'

import classNames from 'classnames'

import { useTranslator } from '@eo-locale/preact'

import {
  formControlInvalid,
  createFormControlsRefReportValidity
} from '../../utils/constraintValidation.js'

/**
 * @typedef { import('preact/hooks').MutableRef<HTMLInputElement[]> } HTMLInputElementRefs
 */

/**
 * @typedef {Object} Options
 * @property {string[]} items
 * @property {boolean} unmute
 */

/**
 * @typedef {Object} Config
 * @property {string} placeholder
 */

/**
 * @typedef {Object} ComponentProps
 * @property {Options} options
 * @property {Config} config
 * @property { import('../PlaylistEditor.js').setReportValidityInterface } setReportValidity
 * @property { import('../PlaylistEditor.js').onChange } onChange
 */

const emptyValue = ''

/**
 * Multiple URLs
 * @extends Component<ComponentProps>
 */
export default class YoutubePlaylist extends Component {
  /**
   * @inheritdoc
   * @return {ComponentProps}
   */
  static get defaultProps() {
    return {
      options: {
        items: [],
        unmute: false,
      },
      config: {
        placeholder: 'https://biotic.cloud/',
      },
      setReportValidity: () => {},
      onChange: () => {},
    }
  }

  /**
   * @inheritdoc
   * @param {ComponentProps} props
   */
  constructor(props) {
    super(props)

    /** @type {HTMLInputElementRefs} */
    this.inputsRef = { current: [] }

    this.handleOptionChange = this.handleOptionChange.bind(this)
    this.handleUmuteChange = this.handleUmuteChange.bind(this)
  }

  /**
   * @inheritdoc
   */
  componentDidMount() {
    // Pass default props
    this.props.onChange(this.props.options)

    // Set report validity callback
    this.props.setReportValidity(
      createFormControlsRefReportValidity(this.inputsRef)
    )
  }

  /**
   * @inheritdoc
   * @param {ComponentProps} prevProps
   */
  componentDidUpdate(prevProps) {
    // Update refs array length
    // {@link https://stackoverflow.com/a/56063129/1012616}
    if (this.props.options.items.length !== prevProps.options.items.length) {
      this.inputsRef.current = this.inputsRef.current.slice(0, this.props.options.items.length + 1)

      // Rerender with reassigned refs
      this.forceUpdate()
    }
  }

  /**
   * Handle input value change
   * @param {number} index
   * @param {Object} event
   * @return {void}
   */
  handleOptionChange(index, event) {
    const value = event.target.value

    let items

    // Add
    if (index === this.props.options.items.length) {
      items = [...this.props.options.items, value]
    // Remove
    } else if (value === emptyValue) {
      items = this.props.options.items.filter((cmpItem, cmpIndex) =>
        index !== cmpIndex
      )
    // Change
    } else {
      items = this.props.options.items.map((cmpItem, cmpIndex) =>
        index === cmpIndex
          ? value
          : cmpItem
      )
    }

    this.props.onChange({
      ...this.props.options,
      items,
    })
  }

  /**
   * Handle checkbox change
   * @param {Object} event
   * @param {HTMLInputElement} event.target
   * @return {void}
   */
  handleUmuteChange(event) {
    this.props.onChange({
      ...this.props.options,
      unmute: event.target.checked,
    })
  }

  /**
   * @inheritdoc
   */
  render() {
    const translator = useTranslator()

    // Add append empty item
    const items = [
      ...this.props.options.items,
      emptyValue,
    ]

    return html`
      <fieldset className="uk-fieldset uk-form-horizontal  bip-form-horizontal">
        <div className="uk-margin-small">
          <label className="uk-form-label">
            ${translator.translate('option.youtubePlaylist.field.url.text')}
          </label>
          <div className="uk-form-controls">
            ${items.map((item, index) => html`
              <div className="uk-margin-small">
                <input
                  ref=${element => this.inputsRef.current[index] = element}
                  className=${classNames('uk-input uk-form-small', {
                    'uk-form-danger': formControlInvalid(this.inputsRef.current[index]),
                  })}
                  type="url"
                  value=${item}
                  placeholder=${this.props.config.placeholder}
                  required=${!index || index !== this.props.options.items.length}
                  inputmode="url"
                  onChange=${event => this.handleOptionChange(index, event)}
                />
              </div>
            `)}
          </div>
        </div>
        <div className="uk-margin-small">
          <label className="uk-form-label">
            ${translator.translate('option.youtubePlaylist.field.unmute.text')}
          </label>
          <div className="uk-form-controls uk-form-controls-text">
          <label>
            <input
              className="uk-checkbox"
              type="checkbox"
              checked=${this.props.options.unmute}
              onChange=${this.handleUmuteChange}
            />
          </label>
        </div>
        </div>
      </fieldset>
    `
  }
}
