/** @typedef { import('../../Constants/permission.js').Permission } Permission */

/** @enum {Permission} */
const PERMISSION = {
  INDEX: 'user:index',
  CREATE: 'user:create',
  READ: 'user:read',
  UPDATE: 'user:update',
  ROLES__SET: 'user.roles:set',
  ROLE_ROOT__SET: 'user.role.root:set',
  DELETE: 'user:delete',
}

export default PERMISSION
