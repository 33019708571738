/** @typedef {string} Role */

/** @enum {Role} */
const ROLE = {
  ROOT: 'root',
  MANAGER: 'manager',
  USER: 'user',
  SERVICE: 'service',
}

/** @type {Role[]} */
const ROLES = [
  ROLE.ROOT,
  ROLE.MANAGER,
  ROLE.USER,
  ROLE.SERVICE,
]

export {
  ROLE,
  ROLES,
}
