import { useEffect } from 'preact/hooks'
import { Link } from 'wouter-preact'
import { Formik, Form } from 'formik'
import clsx from 'clsx'

import { useAuth } from '../../Context/AuthProvider.jsx'
import { useSignIn } from '../../Hooks/Api/useAuthApi.js'
import routes from '../../routes.js'
import ValidationMessages from '../../Components/Form/ValidationMessages.jsx'
import ValidationError from '../../Error/ValidationError.js'
import Icon from '../../Components/Icon/Icon.jsx'
import './SignIn.css'

/** @type {Object<string, string[]>} */
const initialFormikStatus = {
  email: [],
  password: [],
}

/**
 * Sign In page
 *
 * @type {preact.FunctionComponent}
 */
export default function SignIn() {

  const { authenticate } = useAuth()
  const { data: authResponse, error: authError, isLoading, trigger: signIn } = useSignIn()

  useEffect(() => {
    if (authResponse) {
      authenticate(authResponse)
    }
  }, [authResponse])

  let tabIndex = 0

  return (
    <Formik
      initialValues={{ email: null, password: null }}
      initialStatus={initialFormikStatus}
      validate={validate}
      onSubmit={values => signIn(values)}
    >
      {formik =>
        <Form className="uk-form-stacked">
          <fieldset
            className="uk-fieldset"
            disabled={isLoading}
          >
            <h1 className="uk-card-title">
              {'Welcome back!'}
            </h1>
            {/** Email */}
            <div className="uk-margin-small">
              <label
                className="uk-form-label"
                htmlFor="mp-signin-form-email"
                hidden
              >
                {'Email'}
              </label>
              <div className="uk-form-controls">
                <div className="uk-inline uk-width-1-1">
                  <Icon
                    className="uk-form-icon uk-form-icon-flip"
                    icon="user"
                  />
                  <input
                    id="mp-signin-form-email"
                    className={clsx('uk-input', {'uk-form-danger': formik.errors.email && formik.touched.email})}
                    type="email"
                    placeholder="Your email"
                    autoComplete="username"
                    autoFocus={true}
                    tabIndex={++tabIndex}
                    {...formik.getFieldProps('email')}
                  />
                </div>
                <ValidationMessages name="email" />
              </div>
            </div>

            {/** Password */}
            <div className="uk-margin-small">
              <label
                className="uk-form-label"
                htmlFor="mp-signin-form-password"
                hidden
              >
                {'Password'}
              </label>
              <div className="uk-form-controls">
                <div className="uk-inline uk-width-1-1">
                  <Icon
                    className="uk-form-icon uk-form-icon-flip "
                    icon="lock"
                  />
                  <input
                    id="mp-signin-form-password"
                    className={clsx('uk-input', {'uk-form-danger': formik.errors.password && formik.touched.password})}
                    type="password"
                    placeholder="Password"
                    autoComplete="current-password"
                    tabIndex={++tabIndex}
                    {...formik.getFieldProps('password')}
                  />
                </div>
                <ValidationMessages name="password" />
              </div>
            </div>

            {/** Submit button */}
            <div className="uk-margin-top">
              <button
                className="uk-button uk-button-primary uk-width-1-1"
                type="submit"
                tabIndex={++tabIndex}
              >
                {'Sign in'}
              </button>
            </div>
          </fieldset>

          {/** Validation */}
          <div>
            { authError &&
              <div className={`uk-alert uk-alert-${authError instanceof ValidationError ? 'warning' : 'danger'} uk-margin-top`}>
                <p>
                  {authError.message || 'Error'}
                </p>
              </div>
            }
          </div>

          <ul className="uk-list mp-sign-in-list uk-text-small bi-margin-bottom-remove">
            {/*
            <li>
              {'Forgot your password?'}
            </li>
            */}
            {import.meta.env.VITE_APP_FEATURE_SIGN_UP === 'true' &&
              <li>
                <span className="uk-margin-small-right">{'Don\'t have an account?'}</span>
                <Link
                  href={routes.auth.signUp}
                  className="uk-link-text"
                >
                  {'Sign Up'}
                </Link>
              </li>
            }
          </ul>
        </Form>
      }
    </Formik>
  )
}

/**
 * Validate form
 * @param {Object} values
 * @return {Object<string, string>}
 */
function validate(values) {
  /** @type {Object<string, string>} */
  const errors = {}

  if (!values.email) {
    errors.email = 'required'
  }

  if (!values.password) {
    errors.password = 'required'
  }

  return errors
}
