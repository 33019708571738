/**
 * Routes
 * @type {Record<'dashboard'|'profile', string> & Record<'screen'|'playlist'|'organisation'|'user'|'auth', Record<string, string>>}
 */
export default {
  dashboard: '/',
  profile: '/profile',
  screen: {
    index: '/screens',
    create: '/screens/create',
    update: '/screens/:id/edit',
  },
  playlist: {
    index: '/playlists',
    create: '/playlists/create',
    update: '/playlists/:id/edit',
  },
  organisation: {
    index: '/organisations',
    create: '/organisations/create',
    update: '/organisations/:id/edit',
  },
  user: {
    index: '/users',
    create: '/users/create',
    update: '/users/:id/edit',
  },
  auth: {
    signIn: '/',
    signUp: '/sign-up',
    signOut: '/sign-out',
  },
}
