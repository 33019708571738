import { useContext } from 'preact/hooks'

import { createContext } from 'preact'

/**
 * @typedef {Object} ApiConfigInterface
 * @property {string} baseUrl
 */

/**
 * Context object
 */
export const ApiConfigContext = createContext(/** @type {ApiConfigInterface|null} */(null))

ApiConfigContext.displayName = 'ApiConfigContext'

/**
 * Hook
 * @return {ApiConfigInterface}
 */
export function useApiConfig () {
  const value = useContext(ApiConfigContext)

  if (!value) {
    throw new Error('Api Context has not been provided')
  }

  return value
}

/**
 * @type {preact.FunctionComponent}
 * @param {Object} props
 * @param {string} props.baseUrl
 * @param {preact.ComponentChildren} props.children
 */
export function ApiConfigProvider ({
  baseUrl,
  children,
}) {
  return (
    <ApiConfigContext.Provider value={{
      baseUrl,
    }}>
      { children }
    </ApiConfigContext.Provider>
  )
}

/**
 * Decode subject claim from JWT payload
 * [NOT USED]
 * @param {string} jwt
 * @return {string|null}
 */
export function decodeJwtSubject(jwt) {
  const [ _headerBase64, payloadBase64 ] = jwt.split('.')

  const payload = atob(payloadBase64)
  const { sub: subject } = JSON.parse(payload)

  return subject
}
