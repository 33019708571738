import AbstractHttpError from './AbstractHttpError.js'

/**
 * Authentication error (401)
 */
export default class Authentication extends AbstractHttpError {
  /**
   * @inheritdoc
   * @param {string} message
   * @param {Response} [response]
   */
  constructor(message = 'The request requires valid user authentication.', response = undefined) {
    super(message, response)

    this.name = 'AuthenticationError'

    Object.setPrototypeOf(this, Authentication.prototype)
  }
}
