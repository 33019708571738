import { useState } from 'preact/hooks'

/** @type {Number} */
let uniqueIdComponent = 0

/**
 * Use unique id
 * @see https://gist.github.com/sqren/fc897c1629979e669714893df966b1b7
 * @return {Number}
 */
export function useUniqueId() {
  const [componentId] = useState(() => ++uniqueIdComponent)

  return componentId
}
