import UIkit from 'uikit'

/**
 * Open UIkit confirmation modal
 * @param {string} [message]
 * @param {string} [container]
 * @return {Promise<boolean>}
 */
export async function confirm(message = 'Delete item?', container = '.uk-article') {

  try {
    await UIkit.modal.confirm(message, {
      // @ts-expect-error - Types not updated to v3.16.0+; no idea how to patch types
      i18n: { ok: 'Ok' },
      container: container,
    })
  } catch {
    return false
  }

  return true
}
