import { html } from 'htm/preact'

import Lightbox from './Lightbox.js'

import { blobRegExp, srcVectorRegExp } from './utils.js'

/**
 * @typedef {(src: string) => void} eventListener
 */

/**
 * Thumbnail image
 * @param {Object} props
 * @param {string} props.src
 * @param {number|null} [props.width]
 * @param {eventListener} [props.onLoad]
 * @param {eventListener} [props.onError]
 */
export default function Thumbnail ({
  src,
  width = 300,
  onLoad,
  onError,
}) {
  if (!src) {
    return null
  }

  return html`
    <${Lightbox} src=${getThumbnailUrl(src)}>
      <img
        className="bip-media-preview"
        src=${getThumbnailUrl(src, width)}
        alt=""
        width=${width}
        loading="lazy"
        onLoad=${() => onLoad && onLoad(src)}
        onError=${() => onError && onError(src)}
      />
    </${Lightbox}
  `
}

/**
 * Get thumbnail url
 * @param {string} urlString
 * @param {number|null} [width]
 * @return {string}
 */
function getThumbnailUrl(urlString, width = null) {
  // Null
  if (urlString === null) {
    return urlString
  }

  // Blob
  if (blobRegExp.test(urlString)) {
    return urlString
  }

  // Vector
  if (srcVectorRegExp.test(urlString)) {
    return urlString
  }

  const url = new URL(urlString)
  const dpr = window.devicePixelRatio || 1

  // Set Width * DPR
  if (width) {
    url.searchParams.set('width', (width * dpr).toFixed())
  // Just use DPR
  } else if (dpr !== 1) {
    url.searchParams.set('dpr', dpr.toFixed())
  }

  return url.toString()
}
