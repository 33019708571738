import { render } from 'preact'

import 'uikit/dist/css/uikit.css'

import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'

import AppProviders from './Context/index.jsx'
import App from './App.jsx'

import './index.css'

UIkit.use(Icons)

render(
  <AppProviders>
    <App />
  </AppProviders>,
  document.getElementById('root')
)
