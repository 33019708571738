import { html } from 'htm/preact'
import { useRef, useState, useEffect } from 'preact/hooks'
import UIkit from 'uikit'

import { useTranslator } from '@eo-locale/preact'

import { isDialogAvailable } from '../utils/dom.js'

/**
 * @typedef { import('preact/hooks').MutableRef<HTMLDialogElement> } HTMLDialogElementRef
 * @typedef { import('preact/hooks').MutableRef<HTMLFormElement> } HTMLFormElementRef
 * @typedef { import('preact/hooks').MutableRef<HTMLElement> } HTMLElementRef
 */

/**
 * @typedef {(formData: FormData) => void} handleSubmitInterface
 */

/**
 * Dialog
 * {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dialog}
 * Note: Firefox 98+
 * @param {Object} props
 * @param {preact.VNode} props.children
 * @param {string} props.title - Modal title
 * @param {preact.VNode} [props.output] - Additional output below action button
 * @param {{ title: string, text?: string, icon?: string }} props.button - Action button props
 * @param {preact.VNode} [props.footer]
 * @param {boolean} [props.showConfirmButton] - Show confirm button, not required when there is submit button in children
 * @param {boolean} [props.showResetButton] - Show form reset button
 * @param {handleSubmitInterface} [props.onSubmit] - Fired when form is submitted
 * @return {preact.VNode}
 */
export default ({
  children,
  title = 'Edit',
  output = null,
  button = {
    title: 'Edit',
    text: 'Edit',
    icon: 'pencil',
  },
  footer = null,
  showConfirmButton = false,
  showResetButton = false,
  onSubmit = undefined,
}) => {
  const [open, setOpen] = useState(false)

  /** @type {HTMLDialogElementRef} */
  const dialogRef = useRef()

  /** @type {HTMLFormElementRef} */
  const formRef = useRef()

  /** @type {HTMLElementRef} */
  const modalBodyRef = useRef()

  /** @type {HTMLElementRef} - Reference to element active before open */
  const prevActiveElementRef = useRef()

  const translator = useTranslator()

  // Use dialog API
  useEffect(() => {
    if (open) {
      if (document.activeElement instanceof HTMLElement) {
        prevActiveElementRef.current = document.activeElement
      }

      dialogRef.current.showModal()

      modalBodyRef.current.focus()

      // @ts-ignore
      UIkit.update(modalBodyRef.current, 'update')
    } else if (dialogRef.current.open) {
      dialogRef.current.close()
    }
  }, [open])

  // Handle dialog close
  useEffect(() => {
    if (!dialogRef.current) {
      return
    }

    dialogRef.current.addEventListener('close', handleDialogClose)

    return () => dialogRef.current.removeEventListener('close', handleDialogClose)

    /**
     * Handle dialog close by:
     * - Submit button in dialog
     * - Submit button in children
     * - Close event
     * - Cancel event
     * @param {Event & {target: HTMLDialogElement}} event
     */
    function handleDialogClose(event) { // eslint-disable-line no-unused-vars
      // Execute callback only on confirm button usage
      if (onSubmit && event.target.returnValue === 'confirm') {
        onSubmit(new FormData(formRef.current))
      }

      // Reset return value
      event.target.returnValue = ''

      // Reset form
      if (formRef.current instanceof HTMLFormElement) {
        formRef.current.reset()
      }

      // Refocus previously active element
      if (prevActiveElementRef.current) {
        prevActiveElementRef.current.focus()
      }

      setOpen(false)
    }
  }, [dialogRef.current, onSubmit])

  /**
   * Open
   * @return {void}
   */
  const handleOpenButtonClick = () =>
    setOpen(true)

  return html`
    <dialog
      ref=${dialogRef}
      className="bip-dialog"
    >
      ${open && html`
        <form
          ref=${formRef}
          className="bip-dialog-container uk-flex uk-flex-column"
          method="dialog"
        >
          <!-- Close icon -->
          <button
            className="uk-modal-close uk-modal-close-default uk-icon uk-close"
            type="submit"
            value="close"
            formmethod="dialog"
            tabindex="-1"
          >
            <span
              className="uk-icon"
              data-uk-icon="icon: close"
            ></span>
          </button>
          <!-- Header -->
          <div className="uk-modal-header">
            <h2 className="uk-modal-title">
              ${title}
            </h2>
          </div>
          <!-- Body -->
          <div
            ref=${modalBodyRef}
            className="uk-modal-body uk-overflow-auto"
          >
            ${open && children}
          </div>
          <!-- Footer -->
          <menu className="uk-modal-footer uk-text-right">
            <!-- Custom button -->
            ${footer}
            <!-- Confirm button -->
            ${showConfirmButton && html`
              <button
                className="uk-button uk-button-primary uk-button-small uk-margin-small-right"
                type="submit"
                value="confirm"
              >
                <span
                  className="uk-icon"
                  data-uk-icon="icon: check"
                ></span>
                <span className="uk-text-middle uk-margin-small-left">
                  ${translator.translate('common.dialog.action.confirm.text')}
                </span>
              </button>
            `}
            <!-- Reset button -->
            ${showResetButton && html`
              <button
                className="uk-button uk-button-default uk-button-small"
                type="reset"
              >
                <span
                  className="uk-icon"
                  data-uk-icon="icon: refresh"
                ></span>
                <span className="uk-text-middle uk-margin-small-left">
                  ${translator.translate('common.dialog.action.reset.text')}
                </span>
              </button>
            `}
            <!-- Cancel button -->
            <button
              className="uk-button uk-button-default uk-button-small"
              type="submit"
              value="cancel"
              formmethod="dialog"
            >
              <span
                className="uk-icon"
                data-uk-icon="icon: close"
              ></span>
              <span className="uk-text-middle uk-margin-small-left">
                ${translator.translate('common.dialog.action.close.text')}
              </span>
            </button>
          </menu>
        </form>
      `}
    </dialog>
    <!-- Custom text -->
    ${output && html`
      <output className="uk-margin-bottom">
        ${output}
      </output>
    `}
    <!-- Button -->
    <menu className="uk-margin-remove uk-padding-remove">
      <button
        className="uk-button uk-button-default uk-button-small  uk-text-nowrap"
        type="button"
        disabled=${!isDialogAvailable}
        title=${button.title}
        onClick=${handleOpenButtonClick}
      >
        ${button.icon && html`
          <span
            className="uk-icon"
            data-uk-icon=${`icon: ${button.icon}`}
          ></span>
        `}
        ${button.text && html`
          <span className="uk-text-middle uk-margin-small-left">
            ${button.text}
          </span>
        `}
      </button>
    </menu>
  `
}
