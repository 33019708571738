/**
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Constraint_validation
 */

/**
 * @typedef { import('preact/hooks').MutableRef<FormControl[]> } FormControlsRef
 */

/**
 * @typedef { import('../Components/PlaylistEditor.js').reportValidityInterface } reportValidityInterface
 */

/**
 * @typedef {HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement|HTMLFieldSetElement} FormControl
 */

/**
 * Report validity for form control
 * @param {FormControl} formControl
 * @return {boolean}
 */
function formControlReportValidity(formControl) {
  return (
    formControl &&
    formControl.willValidate &&
    formControl.reportValidity()
  )
}

/**
 * Report validity for form controls
 * @param {FormControl[]} formControls
 * @return {boolean}
 */
function formControlsReportValidity(formControls) {
  return formControls.reduce(
    (acc, formControl) => acc && formControlReportValidity(formControl),
    true
  )
}

/**
 * Report validity for form controls
 * @param {FormControlsRef} formControlsRef
 * @return {boolean}
 */
function formControlsRefReportValidity(formControlsRef) {
  return formControlsReportValidity(formControlsRef.current)
}

/**
 * Report validities on fieldset elements
 * @deprecated As inputs are usually set as refes (to indicate validity), pass'em to formControlsReportValidity
 * @param {HTMLFieldSetElement} fieldsetElement
 * @return {boolean}
 */
function fieldsetReportValidity(fieldsetElement) {
  return formControlsReportValidity(
    // @ts-ignore
    Array.from(fieldsetElement.elements)
  )
}

/**
 * Check if form control is invalid
 * @param {FormControl|undefined} formControl
 * @return {boolean|undefined} - Validity state or undefined when cannot determine
 */
export function formControlInvalid(formControl) {
  if (
    !formControl ||
    !formControl.validity
  ) {
    return undefined
  }

  return formControl.validity.valid !== true
}

export const createFormControlReportValidity = wrap(formControlReportValidity)
export const createFormControlsReportValidity = wrap(formControlsReportValidity)
export const createFormControlsRefReportValidity = wrap(formControlsRefReportValidity)
export const createFieldsetReportValidity = wrap(fieldsetReportValidity)

/**
 * Create new function by wrapping arguments
 * @template {any} T
 * @param {(arg: T) => boolean} fn
 * @return {(arg: T) => reportValidityInterface}
 */
function wrap(fn) {
  return (...args) => () => fn(...args)
}
