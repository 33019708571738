import { useState, useEffect } from 'preact/hooks'
import { Formik } from 'formik'

import { withPagePermissions } from '../../Components/Guard/index.jsx'
import PERMISSION from './user.permission.js'

import { useRead } from '../../Hooks/Api/useRestApi.js'

import UserForm from './UserForm.jsx'
import { initialData } from './userStore.js'


/**
 * @typedef { import('../../Hooks/Api/useRestApi.js').ReadResponse<App.Entity.User> } UserReadResponse
 */

/**
 * User read item page
 *
 * @type {preact.FunctionComponent<{ id: string }>}
 */
function UserRead({
  params
}) {
  const id = Number.parseInt(params.id)

  /** @type {UserReadResponse} */
  const { item, isLoading, error: loadingError } = useRead('/users', id)

  const [ formItem, setFormItem ] = useState({ ...initialData, id })
  const [ isHydrated, setIsHydrated ] = useState(false)

  useEffect(() => {
    if (isHydrated) {
      return
    }

    if (item) {
      setFormItem(item)
      setIsHydrated(true)
    }
  }, [item])

  return (
    <Formik
      initialValues={formItem}
      enableReinitialize={true}
      onSubmit={undefined}
    >
      <UserForm
        readOnly
        id={id}
        loadingError={loadingError}
        isLoading={isLoading}
      />
    </Formik>
  )
}

export default withPagePermissions(UserRead, [PERMISSION.READ])
