const common = {
  none: {
    text: 'None',
  },
  default: {
    text: 'Default',
  },
  dialog: {
    action: {
      confirm: { text: 'Confirm '},
      reset: { text: 'Reset' },
      close: { text: 'Close' },
    },
  },
}

const playlistEditor = {
  // Table
  header: {
    index: {
      text: '№',
      hint: 'Index',
    },
    start: {
      text: 'Start',
      hint: 'Start at in [Hours]:[Minutes]:[Seconds] format',
    },
    duration: {
      text: 'Duration',
      hint: 'Duration in [Hours]:[Minutes]:[Seconds] format',
    },
    label: {
      text: 'Label',
      hint: 'Custom label',
    },
    type: {
      text: 'Type',
      hint: 'Content type',
    },
    options: {
      text: 'Options',
    },
    actions: {
      text: 'Actions',
    },
  },

  // Action buttons
  action: {
    options: {
      hide: {
        text: 'Hide',
        hint: 'Hide options',
      },
      show: {
        text: 'Show',
        hint: 'Show options',
      },
      notAvailable: 'N/A',
    },
    order: {
      moveUp: {
        hint: 'Move up',
      },
      moveDown: {
        hint: 'Move down',
      },
    },
    state: {
      enable: {
        hint: 'Enable',
      },
      disable: {
        hint: 'Disable',
      },
    },
    remove: {
      hint: 'Remove',
    },
    add: {
      text: 'Add',
    },
    selection: {
      text: 'Selection',
    },
    selectionItems: {
      disable: {
        text: 'Disable',
      },
    },
  },
}

const mediamanager = {
  message: {
    empty: 'Library is empty',
  },
  action: {
    select: {
      text: 'Select',
    },
    remove: {
      text: 'Remove',
    },
  },
}

const screenSaverRange = {
  title: 'Screen Saver',
  action: {
    change: {
      hint: 'Edit options',
      text: 'Set screen saver',
    },
  },
  field: {
    start: {
      text: 'Start',
    },
    end: {
      text: 'End',
    },
  },
}

// Options

const station = {
  field: {
    station: {
      text: 'Station',
    },
    customLabel: {
      text: 'Custom label',
    },
    sensors: {
      text: 'Sensors',
      hint: 'max. {maxSensors}',
      options: {
        'pm1': 'PM 1',
        'pm2_5': 'PM 2,5',
        'pm10': 'PM 10',
        'humidity': 'Humidity',
        'air_pressure': 'Air Pressure',
        'temperature': 'Temperature',
        'co': 'CO',
        'no2': 'NO2',
        'so2': 'SO2',
        'o3': 'O3',
        'c6h6': 'C6H6',
        'caqi': 'CAQI',
        'noise': 'Noise',
        // 'soil_vwc': 'soil_vwc',
        // 'soil_temperature': 'soil_temperature',
        // 'soil_ap': 'soil_ap',
        'ch2o': 'CH2O',
        // 'light_intensity': 'light_intensity',
      },
    },
  },
}

const stations = {
  field: {
    station: {
      text: 'Station # {index}',
    },
    title: {
      text: 'Title',
      placeholder: 'Hello world!',
    },
    messages: {
      text: 'Messages',
      placeholder: 'Hello world!',
    },
  },
}

const airlyStation = {
  title: 'Airly Station',
  field: {
    apiKey: {
      text: 'API Key',
    }
  },
}

const biStation = {
  title: 'BI Station',
}

const biStations = {
  title: 'BI Stations',
}

const clock = {
  title: 'Clock',
}

const countdown = {
  title: 'Countdown',
  field: {
    datetime: {
      text: 'Date and time',
    },
    text: {
      text: 'Text',
    },
  },
}

const image = {
  title: 'Image',
  field: {
    image: {
      text: 'Image',
    },
    fit: {
      text: 'Fit',
      option: {
        default: 'Default',
        contain: 'Contain',
        cover: 'Cover',
        fill: 'Fill',
      },
    },
    offset: {
      text: 'Offset',
    },
    backgroundColor: {
      text: 'Background color',
    },
  },
  action: {
    change: {
      text: 'Change',
      hint: 'Open lightbox',
    },
    upload: {
      text: 'Upload',
    },
  },
  lightbox: {
    title: 'Image gallery',
  },
}

const reference = {
  title: 'Reference',
  field: {
    index: {
      text: 'Index',
    },
  },
}

const screensaver = {
  title: 'Screensaver',
}

const syngeosStation = {
  title: 'Syngeos Station',
}

const syngeosStations = {
  title: 'Syngeos Stations',
}

const text = {
  title: 'Text',
  field: {
    text: {
      text: 'Text',
      placeholder: 'Hello world!',
    },
    animation: {
      text: 'Animation',
      option: {
        none: 'None',
        horizontal: 'Horizontal',
        vertical: 'Vertical',
      },
    },
    repeat: {
      text: 'Repeat',
    },
    color: {
      text: 'Color',
    },
  },
}

const video = {
  title: 'Video',
  field: {
    video: {
      text: 'Video',
    },
  },
  action: {
    change: {
      text: 'Change',
      hint: 'Open lighbox',
    },
  },
  lightbox: {
    title: 'Video gallery',
  },
}

const videoPlaylist = {
  title: 'Video playlist',
}

const website = {
  title: 'Website',
  field: {
    url: {
      text: 'URL',
    },
  },
}

const youtubePlaylist = {
  title: 'YouTube playlist',
  field: {
    url: {
      text: 'URL',
    },
    unmute: {
      text: 'Unmute',
    },
  },
}

export default {
  language: 'en',
  messages: {
    common,
    playlistEditor,
    mediamanager,
    screenSaverRange,
    option: {
      airlyStation,
      biStation,
      biStations,
      clock,
      countdown,
      image,
      reference,
      screensaver,
      station,
      stations,
      syngeosStation,
      syngeosStations,
      text,
      video,
      videoPlaylist,
      website,
      youtubePlaylist,
    },
  },
}
