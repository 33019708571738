import { useMemo } from 'preact/hooks'
import { Link } from 'wouter-preact'
import { Formik, Form } from 'formik'
import UIkit from 'uikit'
import clsx from 'clsx'

import routes from '../../routes.js'
import { useAuth } from '../../Context/AuthProvider.jsx'
import { withPagePermissions } from '../../Components/Guard/index.jsx'
import PERMISSION from './profile.permission.js'

import {
  useIndex,
  useRead,
  useUpdate,
} from '../../Hooks/Api/useRestApi.js'
import { initialData, getStatusFromError, validate, transformValues } from './profileStore.js'

import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb.jsx'
import LoadingOverlay from '../../Components/LoadingOverlay/LoadingOverlay.jsx'
import ValidationMessages from '../../Components/Form/ValidationMessages.jsx'

/**
 * @typedef { import('../../Hooks/Api/useRestApi.js').IndexResponse<App.Entity.User> } UserIndexResponse
 * @typedef { import('../../Hooks/Api/useRestApi.js').ReadResponse<App.Entity.User> } UserReadResponse
 * @typedef { import('../../Hooks/Api/useRestApi.js').UpdateResponse<App.Entity.User> } UserUpdateResponse
 */

/**
 * Users profile page
 *
 * @type {preact.FunctionComponent}
 */
function Profile() {
  const { isGranted, setUser: setAuthUser } = useAuth()
  /** @type {UserReadResponse} */
  const { item: user = initialData, isLoading, mutate } = useRead('/profile', null)
  /** @type {UserUpdateResponse} */
  const { isUpdating, error: updateError, updater } = useUpdate('/profile', null)
  /** @type {UserIndexResponse} */
  const { mutate: mutateItems } = useIndex('/users', false)

  /**
   * @param {App.Entity.User} values
   */
  async function handleFormSubmit(values, { setSubmitting }) {
    // Merge current user item with selected form data
    const item = {
      ...user,
      ...transformValues(values),
    }

    /** @type {App.Entity.User} */
    const updatedItem = await mutate(updater(item))
    setSubmitting(false)

    if (!updatedItem) {
      UIkit.notification({ message: 'Error', status: 'warning'})
      return
    }

    // Update cached items
    await mutateItems((/** @type {App.Entity.User[]|undefined} */ cachedItems) =>
      cachedItems?.map(cachedItem => cachedItem.id === item.id
        ? { ...updatedItem }
        : cachedItem
      ),
      false
    )

    setAuthUser(item)
    UIkit.notification({ message: 'Profile updated', status: 'success' })
  }

  /**
   * Update formik status
   * Note: Using fx to handle infinite loop
   * @see https://github.com/formium/formik/issues/150
   * @see https://stackoverflow.com/a/54328567/1012616
   */
  const status = useMemo(() => getStatusFromError(updateError), [updateError])

  return (
    <section>
      {/** Breadcrumbs */}
      <Breadcrumb items={[{ text: 'Profile' }]} />

      <Formik
        initialValues={user}
        initialStatus={status}
        enableReinitialize={true}
        validate={validate}
        onSubmit={handleFormSubmit}
      >
        {formik =>
          <Form
            className="uk-card uk-card-default uk-card-small uk-flex-inline uk-flex-column"
            onSubmit={formik.handleSubmit}
          >
            <fieldset
              className="uk-fieldset"
              disabled={isUpdating}
            >
              <div className="uk-card-header uk-flex uk-flex-between">
                {/** Title */}
                <h1 className="uk-card-title uk-margin-remove">
                  {'My profile'}
                </h1>

                {/** Actions */}
                <ul className="uk-iconnav">
                  {isGranted(PERMISSION.UPDATE) &&
                    <li>
                      <button
                        className="uk-icon"
                        type="submit"
                        disabled={isLoading || isUpdating}
                        data-uk-icon="icon: check"
                        data-uk-tooltip={'Save'}
                      ></button>
                    </li>
                  }
                  <li>
                    <Link
                      className="uk-icon"
                      href={routes.dashboard}
                      data-uk-icon="icon: close"
                      data-uk-tooltip={'Cancel'}
                    />
                  </li>
                </ul>
              </div>

              <div className="uk-card-body uk-form-horizontal">
                {/** Name */ }
                <div className="uk-margin">
                  <label className="uk-form-label">
                    {'Name'}
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className={clsx('uk-input uk-form-width-medium', {'uk-form-danger': formik.errors.name && formik.touched.name})}
                      type="text"
                      placeholder={'John Doe'}
                      autoComplete="name"
                      {...formik.getFieldProps('name')}
                    />
                    <ValidationMessages name="name" />
                  </div>
                </div>

                {/** Password */}
                <div className="uk-margin">
                  <label className="uk-form-label">
                    {'Password'}
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className={clsx('uk-input uk-form-width-medium', {'uk-form-danger': formik.errors.password && formik.touched.password})}
                      type="password"
                      placeholder="Password"
                      autoComplete="new-password"
                      {...formik.getFieldProps('password')}
                    />
                    <ValidationMessages name="password" />
                  </div>
                </div>

                {/** Confirm password */}
                <div className="uk-margin">
                  <label className="uk-form-label">
                    {'Confirm Password'}
                  </label>
                  <div className="uk-form-controls">
                    <input
                      className={clsx('uk-input uk-form-width-medium', {'uk-form-danger': formik.errors.passwordConfirm && formik.touched.passwordConfirm})}
                      type="password"
                      placeholder="Confirm password"
                      autoComplete="new-password"
                      {...formik.getFieldProps('passwordConfirm')}
                    />
                    <ValidationMessages name="passwordConfirm" />
                  </div>
                </div>

                {/** Language */ }
                <div className="uk-margin">
                  <label className="uk-form-label">
                    {'Language'}
                  </label>
                  <div className="uk-form-controls">
                    <select
                      className={clsx('uk-select uk-form-width-medium', {'uk-form-danger': formik.errors.language && formik.touched.language})}
                      autoComplete="language"
                      {...formik.getFieldProps('language')}
                    >
                      <option value="">{'Default'}</option>
                      <option value="en-GB">{'English'}</option>
                      <option value="pl-PL">{'Polish'}</option>
                    </select>
                    <ValidationMessages name="language" />
                  </div>
                </div>
              </div>

              {/** Overlays */}
              <LoadingOverlay
                loadingError={undefined}
                isLoading={isLoading}
              />
            </fieldset>
          </Form>
        }
      </Formik>
    </section>
  )
}

export default withPagePermissions(Profile, [PERMISSION.READ])
