import { useState, useMemo } from 'preact/hooks'
import { useLocation } from 'wouter-preact'
import { Formik } from 'formik'
import UIkit from 'uikit'

import routes from '../../routes.js'
import { useAuth } from '../../Context/AuthProvider.jsx'
import { withPagePermissions } from '../../Components/Guard/index.jsx'
import PERMISSION from './screen.permission.js'
import { GLOBAL_CONTEXT } from '../../Constants/permission.js'

import {
  useIndex,
  useCreate,
} from '../../Hooks/Api/useRestApi.js'

import ScreenForm from './ScreenForm.jsx'
import { initialData, getStatusFromError, transformValues, validate } from './screenStore.js'

/**
 * @typedef { import('../../Hooks/Api/useRestApi.js').IndexResponse<App.Entity.Screen> } ScreenIndexResponse
 * @typedef { import('../../Hooks/Api/useRestApi.js').CreateResponse<App.Entity.Screen> } ScreenCreateResponse
 */

/**
 * Screen create item page
 *
 * @type {preact.FunctionComponent}
 */
function ScreenCreate() {

  const { isGranted, user } = useAuth()

  const [ , route ] = useLocation()

  /** @type {ScreenCreateResponse} */
  const { error: createError, isCreating, creator } = useCreate('/screens')

  /** @type {ScreenIndexResponse} */
  const { mutate: mutateItems } = useIndex('/screens', false)

  const [ formItem, setFormItem ] = useState(() =>
    // Set authenticated users organisation when not permitted to change
    isGranted(GLOBAL_CONTEXT.ACCESS)
      ? initialData
      : { ...initialData, organisationId: user.organisationId }
  )

  const status = useMemo(() => getStatusFromError(createError), [createError])

  /**
   * Handle submit
   * @param {Object} values
   */
  async function handleFormSubmit(values, { setSubmitting }) {
    /** @type {App.Entity.ScreenCreate} */
    const item = {
      ...formItem,
      ...transformValues(values),
    }

    const createdItem = await creator(item)
    setSubmitting(false)

    if (!createdItem) {
      UIkit.notification({ message: 'Error', status: 'warning' })
      return
    }

    // Add to cached items
    await mutateItems((/** @type {App.Entity.ScreenCreate[]|undefined} */ cachedItems) =>
      cachedItems && [...cachedItems, createdItem],
      false
    )

    setFormItem(item)

    UIkit.notification({ message: 'Item created', status: 'success' })

    // Redirect
    route(routes.screen.index)
  }

  return (
    <Formik
      initialValues={formItem}
      initialStatus={status}
      enableReinitialize={true}
      validate={values => validate(values, true)}
      onSubmit={handleFormSubmit}
    >
      <ScreenForm
        id={null}
        loadingError={undefined}
        isLoading={false}
        isSyncing={isCreating}
      />
    </Formik>
  )
}

export default withPagePermissions(ScreenCreate, [PERMISSION.CREATE])
