import STATE from './state.constants.js'

/**
 * State label component
 * @type {preact.FunctionComponent}
 * @param {Object} props
 * @param {number} props.state
 * @see https://getuikit.com/docs/label
 */
export default function State({ state }) {
  let style = 'undefined'
  let label = '?'

  switch (state) {
    case STATE.ENABLED:
      style = 'success'
      label = 'Enabled'
      break
    case STATE.DISABLED:
      style = 'error'
      label = 'Disabled'
      break
  }

  return (
    <span className={`uk-label uk-label-${style}`}>
      {label}
    </span>
  )
}
