import { useRef, useEffect } from 'preact/hooks'

import { html } from 'htm/preact'

import classNames from 'classnames'

import { useTranslator } from '@eo-locale/preact'

import {
  formControlInvalid,
  createFormControlReportValidity
} from '../../utils/constraintValidation.js'

/**
 * @typedef { import('preact/hooks').MutableRef<HTMLFieldSetElement> } HTMLFieldsetElementRef
 * @typedef { import('preact/hooks').MutableRef<HTMLInputElement> } HTMLInputElementRef
 */

/**
 * @typedef {Object} Options
 * @property {string} url
 */

/**
 * @typedef {Object} ComponentProps
 * @property {Options} options
 * @property { import('../PlaylistEditor.js').setReportValidityInterface } setReportValidity
 * @property { import('../PlaylistEditor.js').onChange } onChange
 */

/**
 * Single URL
 * @param {ComponentProps} props
 */
export default function Website ({
  options = {
    url: 'about:blank'
  },
  setReportValidity,
  onChange,
}) {
  /** @type {HTMLInputElementRef} */
  const inputRef = useRef()

  const translator = useTranslator()

  useEffect(() => {
    setReportValidity(
      createFormControlReportValidity(inputRef.current)
    )
  }, [])

  /**
   * Handle option URL change
   * @param {Object} event
   * @param {HTMLInputElement} event.target
   * @return {void}
   */
  const handleOptionUrlChange = event =>
    onChange({
      ...options,
      url: event.target.value,
    })

  return html`
    <fieldset className="uk-fieldset uk-form-horizontal  bip-form-horizontal">
      <div>
        <label className="uk-form-label">
          ${translator.translate('option.website.field.url.text')}
        </label>
        <div className="uk-form-controls">
          <input
            ref=${inputRef}
            className=${classNames('uk-input uk-form-small', {
              'uk-form-danger': formControlInvalid(inputRef.current),
            })}
            type="url"
            value=${options.url}
            placeholder="http://google.com"
            required
            inputmode="url"
            onChange=${handleOptionUrlChange}
          />
        </div>
      </div>
    </fieldset>
  `
}
