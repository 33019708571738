import { useField } from 'formik'

import { useOrganisations } from '../../Context/Organisations.jsx'
import ValidationMessages from './ValidationMessages.jsx'

/**
 * @typedef { import('../../Hooks/Api/useRestApi.js').IndexResponse<App.Entity.Organisation> } OrganisationIndexResponse
 */

/**
 * Organisation selector
 * @type {preact.FunctionComponent}
 * @param {Object} props
 * @param {string} props.name
 * @example
 * ```jsx
 * <OrganisationSelect name="organisationId" />
 * ```
 */
function OrganisationSelect (props) {
  const {
    items: organisations,
    isLoading,
  } = useOrganisations()

  const [field] = useField({ ...props })

  return (
    <>
      <select
        className="uk-select uk-form-width-medium"
        disabled={isLoading}
        {...field}
        {...props}
      >
        <option value={null}>
          {'- None -'}
        </option>
        {organisations.map(organisation =>
          <option value={organisation.id} key={organisation.id}>
            {organisation.name}
          </option>
        )}
      </select>
      <ValidationMessages name={props.name} />
    </>
  )
}

export default OrganisationSelect
