import {
  useState,
  useEffect,
  useContext,
} from 'preact/hooks'

import { createContext } from 'preact'

import PersistentStorage from './PersistentStorage.js'

/**
 * @typedef {Object} AuthPropsInterface
 * @property {string|null} accessToken
 * @property {App.Entity.User|null} user
 * @property {App.Entity.Organisation|null} organisation
 * @property {boolean} isAuthenticated
 * @property {(permission: string) => boolean} isGranted
 * @property {(authApiResponse: AuthApiResponse) => void} authenticate
 * @property {() => void} signout
 * @property {UserStateUpdater} setUser
 */

/**
 * @typedef {Object} AuthApiResponse
 * @property {string} accessToken
 * @property {App.Entity.User} user
 * @property {App.Entity.Organisation|null} organisation
 * @property {string[]} permissions
 */

/**
 * @typedef { import('preact').Context<AuthPropsInterface> } AuthContextPropsInterface
 *
 * @typedef { import('preact/hooks').useState } useStateType
 *
 * @typedef { import('preact/hooks').Dispatch<App.Entity.User> } UserStateUpdater
 * @typedef { import('preact/hooks').Dispatch<App.Entity.Organisation> } OrganisationStateUpdater
 */

/**
 * Context object
 */
export const AuthContext = createContext(/** @type {AuthPropsInterface|null} */(null))

AuthContext.displayName = 'AuthContext'

/**
 * Hook
 * @return {AuthPropsInterface}
 */
export function useAuth() {
  const value = useContext(AuthContext)

  if (!value) {
    throw new Error('Auth Context has not been provided')
  }

  return value
}

const isDevEnvironment = window.location.hostname === 'localhost'
const authStorage = new PersistentStorage('persist:mypromo')

/**
 * Provider
 * @type {preact.FunctionComponent}
 */
export function AuthProvider({
  children
}) {

  /** @type {[App.Entity.User, UserStateUpdater]} */
  const [ user, setUser ] = useState(null)
  /** @type {[App.Entity.Organisation, OrganisationStateUpdater]} */
  const [ organisation, setOrganisation ] = useState(null)
  const [ accessToken, setAccessToken ] = useState(null)
  const [ permissions, setPermissions ] = useState([])

  // Authenticate using stuff from local storage (note: token may be expired)
  useEffect(() => {
    if (!isDevEnvironment) {
      return
    }

    // Should check exp claim
    const authData = authStorage.get()

    authData && authenticate(authData)
  }, [])

  useEffect(() => {
    if (!isDevEnvironment) {
      return
    }

    if (user) {
      authStorage.set({
        accessToken,
        user,
        organisation,
        permissions,
      })
    } else {
      /**
       * Note: At this moment SWRConfig component is destroyed on signout
       * If this changes, use swr cache.clear() to remove cached responses that are bound to current user
       * @see https://github.com/vercel/swr/issues/424
       */
      authStorage.remove()
    }
  }, [user])

  /**
   * Set as authenticated
   * @param {AuthApiResponse} authApiResponse
   */
  function authenticate({
    accessToken,
    user,
    organisation,
    permissions
  }) {
    setAccessToken(accessToken)
    setUser(user)
    setOrganisation(organisation)
    setPermissions(permissions)
  }

  /**
   * Sign out
   */
  function signout() {
    setAccessToken(null)
    setUser(null)
    setOrganisation(null)
    setPermissions([])
  }

  /**
   * Test if user is authorized (granted specific permission)
   * @param {string} permission
   * @return {boolean}
   */
  function isGranted(permission) {
    return permissions.includes(permission)
  }

  return (
    <AuthContext.Provider value={{
      accessToken,
      user,
      organisation,
      isAuthenticated: !!accessToken,
      isGranted,
      authenticate,
      signout,
      setUser,
    }}>
      { children }
    </AuthContext.Provider>
  )
}
