/**
 * @see https://getuikit.com/docs/breadcrumb
 * @see https://ant.design/components/breadcrumb/
 */
import { Link } from 'wouter-preact'

import routes from '../../routes.js'

/**
 * @typedef { import('preact').VNode } VNode
 * @typedef { import('preact').FunctionComponent<{items?: ItemDef[]}> } ListComponent
 * @typedef { import('preact').FunctionComponent<{path?: string}> } ItemComponent
 * @typedef { import('preact').ComponentChild } ComponentChild
 */

/**
 * @typedef {Object} ItemDef - Breadcrumb item definitions
 * @property {ComponentChild} text
 * @property {string} [path]
 */

/**
 * @type {ListComponent & {Item: ItemComponent}}
 */
const List = ({
  items = [],
  children = undefined,
}) => (
  <ul className="uk-breadcrumb">
    <li>
      <Link
        href={routes.dashboard}
        className="uk-icon"
        data-uk-icon="icon: home; ratio: .75"
      ></Link>
    </li>
    {items.map(({path, text}, index) =>
      <Item
        key={index}
        path={path}
      >
        {text}
      </Item>
    )}
    {children}
  </ul>
)

/**
 * Single breacrumbt component
 * @type {ItemComponent}
 */
const Item = ({
  children,
  path = undefined,
}) => (
  <li>
    {path
      ? <Link href={path}>{children}</Link>
      : <span>{children}</span>
    }
  </li>
)

List.Item = Item

export default List
