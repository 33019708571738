import errorToStatusFactory from '../../Components/Form/errorToStatus.js'

/** @type {App.Entity.Playlist} */
export const initialData = {
  id: null,
  label: '',
  organisationId: null,
  params: {},
  items: [],
  screenSaverRange: null,
}

export const getStatusFromError = errorToStatusFactory(initialData)

/**
 * @param {Object} values
 * @return {Omit<App.Entity.Playlist, 'id' | 'params'>}
 */
export function transformValues(values) {
  return {
    label: values.label,
    organisationId: values.organisationId ? Number.parseInt(values.organisationId) : null,
    items: values.items,
    screenSaverRange: values.screenSaverRange,
  }
}

/**
 * @param {Object} values
 * @param {boolean} isNew
 * @return {Object<string, string>}
 */
export function validate(values, isNew = true) {
  /** @type {Object<string, string>} */
  const errors = {}
  const item = transformValues(values)

  if (!item.label) {
    errors.label = 'required'
  }

  if (!item.organisationId) {
    errors.organisationId = 'required'
  }

  if (!item.items) {
    errors.items = 'invalid schema'
  }

  return errors
}
