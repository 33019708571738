import AbstractHttpError from './AbstractHttpError.js'

/**
 * Authorization error (403)
 */
export default class AuthorizationError extends AbstractHttpError {
  /**
   * @inheritdoc
   * @param {string} message
   * @param {Response} [response]
   */
  constructor(message = 'You are not permitted to perform the requested operation.', response = undefined) {
    super(message, response)

    this.name = 'AuthorizationError'

    Object.setPrototypeOf(this, AuthorizationError.prototype)
  }
}
