import { html } from 'htm/preact'
import { useRef, useEffect } from 'preact/hooks'

import classNames from 'classnames'

import { useTranslator } from '@eo-locale/preact'

import CONTENT_TYPES from '../../Constants/ContentType.js'

import {
  formControlInvalid,
  createFormControlReportValidity,
} from '../../utils/constraintValidation.js'

/**
 * @typedef { import('preact/hooks').MutableRef<HTMLSelectElement> } HTMLSelectElementRef
 */

/**
 * @typedef { import('../PlaylistEditor.js').PlaylistRow } PlaylistRow
 */

/**
 * @typedef {Object} Options
 * @property {number} uid
 */

/**
 * @typedef {Object} Config
 * @property {PlaylistRow[]} rows
 */

/**
 * @typedef {Object} ComponentProps
 * @property {Options} options
 * @property {Config} config
 * @property { import('../PlaylistEditor.js').setReportValidityInterface } setReportValidity
 * @property { import('../PlaylistEditor.js').onChange } onChange
 */

/**
 * @typedef {Object} SelectOption
 * @property {PlaylistRow} row
 * @property {number} index
 */

/**
 * @param {ComponentProps} props
 */
export default function Reference ({
  options = {
    uid: undefined,
  },
  config = {
    rows: [],
  },
  setReportValidity,
  onChange,
}) {
  /** @type {HTMLSelectElementRef} */
  const selectRef = useRef()

  // const [uid, setUid] = useState(undefined)

  const translator = useTranslator()

  /** @type {SelectOption[]} */
  const selectOptions = config.rows
    .map((row, index) => ({ row, index }))
    .filter(option => option.row.item.type !== CONTENT_TYPES.REFERENCE)

  // Find by uid
  const optionByUid = selectOptions.find(({ row }) => row.uid === options.uid)

  // No such select option but used (ie. disabled)
  if (!optionByUid && options.uid) {
    onChange({ ...options, uid: undefined })

    return null
  }

  const uid = optionByUid && options.uid

  useEffect(() => {
    setReportValidity(
      createFormControlReportValidity(selectRef.current)
    )
  }, [])

  /**
   * Handle option index change
   * @param {Object} event
   * @param {HTMLSelectElement} event.target
   * @return {void}
   */
  const handleOptionIndexChange = event => {
    const uid = event.target.value
      ? Number.parseInt(event.target.value)
      : undefined

    onChange({ ...options, uid })
  }

  return html`
    <fieldset className="uk-fieldset uk-form-horizontal  bip-form-horizontal">
      <div className="uk-margin-small">
        <label className="uk-form-label">
          ${translator.translate('option.reference.field.index.text')}
        </label>
        <div className="uk-form-controls">
          <select
            ref=${selectRef}
            className=${classNames('uk-select uk-form-small', {
              'uk-form-danger': formControlInvalid(selectRef.current),
            })}
            value=${uid}
            required
            onChange=${handleOptionIndexChange}
          >
            <option value=${undefined}>
              ${translator.translate('common.none.text')}
            </option>
            ${selectOptions.map(({ row, index }) => html`
              <option value=${row.uid}>
                ${index + 1}. ${row.item.label} (${row.item.type})
              </option>
            `)}
          </select>
        </div>
      </div>
    </fieldset>
  `
}
