import AbstractHttpError from './AbstractHttpError.js'

/**
 * Not found error (404)
 */
export default class NotFoundError extends AbstractHttpError {
  /**
   * @inheritdoc
   * @param {string} message
   * @param {Response} [response]
   */
  constructor(message = 'The requested resource could not be found.', response = undefined) {
    super(message, response)

    this.name = 'NotFoundError'

    Object.setPrototypeOf(this, NotFoundError.prototype)
  }
}
