import { useState, useEffect, useMemo } from 'preact/hooks'
import { Formik } from 'formik'
import UIkit from 'uikit'

import { withPagePermissions } from '../../Components/Guard/index.jsx'
import PERMISSION from './organisation.permission.js'

import {
  useIndex,
  useRead,
  useUpdate,
} from '../../Hooks/Api/useRestApi.js'

import OrganisationForm from './OrganisationForm.jsx'
import { initialData, getStatusFromError, validate, transformValues } from './organisationStore.js'

/**
 * @typedef { import('../../Hooks/Api/useRestApi.js').ReadResponse<App.Entity.Organisation> } OrganisationReadResponse
 * @typedef { import('../../Hooks/Api/useRestApi.js').UpdateResponse<App.Entity.Organisation> } OrganisationUpdateResponse
 */

/**
 * Organisation update item page
 *
 * @type {preact.FunctionComponent}
 */
function OrganisationUpdate({
  params,
}) {
  const id = Number.parseInt(params.id)

  /** @type {OrganisationReadResponse} */
  const { item, isLoading, error: loadingError, mutate } = useRead('/organisations', id)
  /** @type {OrganisationUpdateResponse} */
  const { isUpdating, error: updateError, updater } = useUpdate('/organisations', id)

  const { mutate: mutateItems } = useIndex('/organisations', false)

  const [ formItem, setFormItem ] = useState({ ...initialData, id })
  const [ isHydrated, setIsHydrated ] = useState(false)

  useEffect(() => {
    // Prevent fresh updates to overwrite form values
    if (isHydrated) {
      return
    }

    if (item) {
      setFormItem(item)
      setIsHydrated(true)
    }
  }, [item])

  const status = useMemo(() => getStatusFromError(updateError), [updateError])

  /**
   * @param {Object} values
   */
  async function handleFormSubmit(values, { setSubmitting }) {
    /** @type {App.Entity.Organisation} */
    const item = {
      ...formItem,
      ...transformValues(values),
    }

    /** @type {App.Entity.Organisation} */
    const updatedItem = await mutate(updater(item), false)
    setSubmitting(false)

    if (!updatedItem) {
      UIkit.notification({ message: 'Error', status: 'warning' })
      return
    }

    // Update cached items
    await mutateItems((/** @type {App.Entity.Organisation[]|undefined} */  cachedItems) =>
      cachedItems?.map(cachedItem => cachedItem.id === item.id
        ? { ...updatedItem }
        : cachedItem
      ),
      false
    )

    setFormItem(item)
    UIkit.notification({ message: 'Item updated', status: 'success' })
  }

  return (
    <Formik
      initialValues={formItem}
      initialStatus={status}
      enableReinitialize={true}
      validate={values => validate(values, false)}
      onSubmit={handleFormSubmit}
    >
      <OrganisationForm
        id={id}
        loadingError={loadingError}
        isLoading={isLoading || !isHydrated}
        isSyncing={isUpdating}
      />
    </Formik>
  )
}

export default withPagePermissions(OrganisationUpdate, [PERMISSION.UPDATE])
