import { Link } from 'wouter-preact'

import routes from '../../routes.js'
import { withPagePermissions } from '../../Components/Guard/index.jsx'
import { SCREEN } from '../../Constants/permission.js'

import { useIndex } from '../../Hooks/Api/useRestApi.js'

import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb.jsx'
import Icon from '../../Components/Icon/Icon.jsx'
import LoadingOverlay from '../../Components/LoadingOverlay/LoadingOverlay.jsx'

import styles from './dashboard.module.css'

/**
 * @typedef { import('../../Hooks/Api/useRestApi.js').IndexResponse<App.Entity.Screen> } ScreenIndexResponse
 */

/** @type {App.Entity.Screen[]} */
const initialData = []

/**
 * Dashboard page
 *
 * @type {preact.FunctionComponent}
 */
function Dashboard() {

  /** @type {ScreenIndexResponse} */
  const { items = initialData, isLoading, error: loadingError } = useIndex('/screens')

  return (
    <section className={`uk-article ${styles.dashboard}`}>

      {/** Breadcrumbs */}
      <Breadcrumb items={[]} />

      <div className="uk-card uk-card-default uk-card-small">
        <div className="uk-card-header uk-flex uk-flex-between">
          <h1 className="uk-card-title uk-margin-remove">
            {'Dashboard'}
          </h1>
        </div>
        <div className="uk-card-body uk-overflow-auto">
          <table className="uk-table uk-table-divider uk-table-striped uk-table-hover uk-table-small uk-table-middle uk-table-responsive">
            <thead>
              <tr>
                <th>{'Label'}</th>
                <th>{'Geo coords'}</th>
                <th className="uk-table-shrink">{'Actions'}</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, key) =>
                <tr key={key}>
                  <td>{item.label}</td>
                  <td>{item.geoCoords ? item.geoCoords.toString() : '-'}</td>
                  <td>
                    <div className="uk-button-group">
                      <Link
                        href={routes.screen.update.replace(':id', item.id.toString())}
                        className="uk-button uk-button-primary uk-button-small"
                      >
                        <Icon icon="pencil" />
                      </Link>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <LoadingOverlay
          loadingError={loadingError}
          isLoading={isLoading}
        />
      </div>

    </section>
  )
}

export default withPagePermissions(Dashboard, [SCREEN.INDEX])
