import { Switch, Route } from 'wouter-preact'
import { SWRConfig } from 'swr'
import ConnectionError from '../../Error/ConnectionError.js'

import { useFetcher } from '../../Hooks/Api/useRestApi.js'
import { useAuth } from '../../Context/AuthProvider.jsx'
import { OrganisationsProvider } from '../../Context/Organisations.jsx'

import Sidebar from '../Sidebar/Sidebar.jsx'
import Main from '../Content/Content.jsx'
import { withPermissionFactory } from '../Guard/index.jsx'

import Dashboard from '../../Pages/Dashboard/Dashboard.jsx'
import ScreenIndex from '../../Pages/Screens/ScreenIndex.jsx'
import ScreenCreate from '../../Pages/Screen/ScreenCreate.jsx'
import ScreenUpdate from '../../Pages/Screen/ScreenUpdate.jsx'
import PlaylistIndex from '../../Pages/Playlists/PlaylistIndex.jsx'
import PlaylistCreate from '../../Pages/Playlist/PlaylistCreate.jsx'
import PlaylistUpdate from '../../Pages/Playlist/PlaylistUpdate.jsx'
import OrganisationIndex from '../../Pages/Organisations/OrganisationIndex.jsx'
import OrganisationCreate from '../../Pages/Organisation/OrganisationCreate.jsx'
import OrganisationUpdate from '../../Pages/Organisation/OrganisationUpdate.jsx'
import UserIndex from '../../Pages/Users/UserIndex.jsx'
import UserCreate from '../../Pages/User/UserCreate.jsx'
import UserUpdate from '../../Pages/User/UserUpdate.jsx'
import Profile from '../../Pages/Profile/Profile.jsx'
import SignOut from '../../Pages/SignOut/SignOut.jsx'
import NotFound from '../../Pages/NotFound/NotFound.jsx'

import routes from '../../routes.js'

import PERMISSION from '../../Constants/permission.js'

const AuthorizedRoute = withPermissionFactory(Route, NotFound)

/**
 * Panel component
 * @type {preact.FunctionComponent}
 */
export default function Panel() {

  const fetcher = useFetcher()

  // Note: May request fresh user instance via API after SWR is configured with fetcher
  const { user, organisation } = useAuth()

  return (
    <SWRConfig value={{
      fetcher,
      // Retry only on connection errors
      shouldRetryOnError: (error) => error instanceof ConnectionError,
    }}>
      <Sidebar
        user={user}
        organisation={organisation}
      />
      <OrganisationsProvider>
        <Main>
          <Switch>
            <Route component={Dashboard} path={routes.dashboard} />
            <AuthorizedRoute component={ScreenIndex} path={routes.screen.index} permission={PERMISSION.SCREEN.INDEX} />
            <AuthorizedRoute component={ScreenCreate} path={routes.screen.create} permission={PERMISSION.SCREEN.CREATE} />
            <AuthorizedRoute component={ScreenUpdate} path={routes.screen.update} permission={PERMISSION.SCREEN.UPDATE} />
            <AuthorizedRoute component={PlaylistIndex} path={routes.playlist.index} permission={PERMISSION.PLAYLIST.INDEX} />
            <AuthorizedRoute component={PlaylistCreate} path={routes.playlist.create} permission={PERMISSION.PLAYLIST.CREATE} />
            <AuthorizedRoute component={PlaylistUpdate} path={routes.playlist.update} permission={PERMISSION.PLAYLIST.UPDATE} />
            <AuthorizedRoute component={OrganisationIndex} path={routes.organisation.index} permission={PERMISSION.ORGANISATION.INDEX} />
            <AuthorizedRoute component={OrganisationCreate} path={routes.organisation.create} permission={PERMISSION.ORGANISATION.CREATE} />
            <AuthorizedRoute component={OrganisationUpdate} path={routes.organisation.update} permission={PERMISSION.ORGANISATION.UPDATE} />
            <AuthorizedRoute component={UserIndex} path={routes.user.index} permission={PERMISSION.USER.INDEX} />
            <AuthorizedRoute component={UserCreate} path={routes.user.create} permission={PERMISSION.USER.CREATE} />
            <AuthorizedRoute component={UserUpdate} path={routes.user.update} permission={PERMISSION.USER.UPDATE} />
            <AuthorizedRoute component={Profile} path={routes.profile} permission={PERMISSION.PROFILE.READ} />
            <Route component={SignOut} path={routes.auth.signOut} />
            <Route component={NotFound} path="/:path*" />
          </Switch>
        </Main>
      </OrganisationsProvider>
    </SWRConfig>
  )
}
