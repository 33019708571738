// Common
const common = {
  none: {
    text: 'Brak wyboru',
  },
  default: {
    text: 'Domyślny',
  },
  dialog: {
    action: {
      confirm: { text: 'Potwierdź '},
      reset: { text: 'Resetuj' },
      close: { text: 'Zamknij' },
    },
  },
}

const playlistEditor = {
  // Table
  header: {
    index: {
      text: '№',
      hint: 'Indeks',
    },
    start: {
      text: 'Początek',
      hint: 'Początek w formacie [Godziny]:[Minuty]:[Sekundy]',
    },
    duration: {
      text: 'Czas trwania',
      hint: 'Czas trwania w formacie [Godziny]:[Minuty]:[Sekundy]',
    },
    label: {
      text: 'Etykieta',
      hint: 'Własna etykieta',
    },
    type: {
      text: 'Typ',
      hint: 'Typ treści',
    },
    options: {
      text: 'Opcje',
    },
    actions: {
      text: 'Akcje',
    },
  },

  // Action buttons
  action: {
    options: {
      hide: {
        text: 'Ukryj',
        hint: 'Ukryj opcje',
      },
      show: {
        text: 'Pokaż',
        hint: 'Pokaż opcje',
      },
      notAvailable: 'N/A',
    },
    order: {
      moveUp: {
        hint: 'Przesuń w górę',
      },
      moveDown: {
        hint: 'Przesuń w dół',
      },
    },
    state: {
      enable: {
        hint: 'Włącz',
      },
      disable: {
        hint: 'Wyłącz',
      },
    },
    remove: {
      hint: 'Usuń',
    },
    add: {
      text: 'Dodaj',
    },
    selection: {
      text: 'Wybrane',
    },
    selectionItems: {
      disable: {
        text: 'Wyłącz',
      },
    },
  },
}

const mediamanager = {
  message: {
    empty: 'Library is empty',
  },
  action: {
    select: {
      text: 'Wybierz',
    },
    remove: {
      text: 'Usuń',
    },
  },
}

const screenSaverRange = {
  title: 'Wygaszacz ekranu',
  action: {
    change: {
      hint: 'Zmień opcje',
      text: 'Ustaw wygaszacz ekranu',
    },
  },
  field: {
    start: {
      text: 'Początek',
    },
    end: {
      text: 'Koniec',
    },
  },
}

// Options

const station = {
  field: {
    station: {
      text: 'Stacja',
    },
    customLabel: {
      text: 'Własna etykieta',
    },
    sensors: {
      text: 'Czujniki',
      hint: 'maks. {maxSensors}',
      options: {
        'pm1': 'PM 1',
        'pm2_5': 'PM 2,5',
        'pm10': 'PM 10',
        'humidity': 'Wilgotność',
        'air_pressure': 'Ciśnienie',
        'temperature': 'Temperatura',
        'co': 'CO',
        'no2': 'NO2',
        'so2': 'SO2',
        'o3': 'O3',
        'c6h6': 'C6H6',
        'caqi': 'CAQI',
        'noise': 'Hałas',
        // 'soil_vwc': 'soil_vwc', //
        // 'soil_temperature': 'soil_temperature', //
        // 'soil_ap': 'soil_ap', //
        'ch2o': 'CH2O',
        // 'light_intensity': 'light_intensity', //
      },
    },
  },
}

const stations = {
  field: {
    station: {
      text: 'Stacja # {index}',
    },
    title: {
      text: 'Tytuł',
      placeholder: 'Witaj świecie',
    },
    messages: {
      text: 'Wiadomości',
      placeholder: 'Witaj świecie',
    },
  },
}

const airlyStation = {
  title: 'Stacja Airly',
  field: {
    apiKey: {
      text: 'Klucz API',
    }
  }
}

const biStation = {
  title: 'Stacja BI',
}

const biStations = {
  title: 'Stacje BI',
}

const clock = {
  title: 'Zegar',
}

const countdown = {
  title: 'Odliczanie',
  field: {
    datetime: {
      text: 'Data i czas',
    },
    text: {
      text: 'Tekst',
    },
  },
}

const image = {
  title: 'Obrazek',
  field: {
    image: {
      text: 'Obrazek',
    },
    fit: {
      text: 'Dopasowanie',
      option: {
        default: 'Domyślne',
        contain: 'Zawacie',
        cover: 'Pokrycie',
        fill: 'Wypełnienie',
      },
    },
    offset: {
      text: 'Offset',
    },
    backgroundColor: {
      text: 'Kolor tła',
    },
  },
  action: {
    change: {
      text: 'Zmień',
      hint: 'Otwórz galerię',
    },
    upload: {
      text: 'Wgraj',
    },
  },
  lightbox: {
    title: 'Galeria zasobów',
  },
}

const reference = {
  title: 'Odnośnik',
  field: {
    index: {
      text: 'Indeks',
    },
  },
}

const screensaver = {
  title: 'Wygaszacz ekranu',
}

const syngeosStation = {
  title: 'Stacja Syngeos',
}

const syngeosStations = {
  title: 'Stacje Syngeos',
}

const text = {
  title: 'Tekst',
  field: {
    text: {
      text: 'Tekst',
      placeholder: 'Witaj świecie!',
    },
    animation: {
      text: 'Animacja',
      option: {
        none: 'Żadna',
        horizontal: 'Horyzontalna',
        vertical: 'Wertykalna',
      },
    },
    repeat: {
      text: 'Powtórz',
    },
    color: {
      text: 'Kolor',
    },
  },
}

const video = {
  title: 'Wideo',
  field: {
    video: {
      text: 'Wideo',
    },
  },
  action: {
    change: {
      text: 'Zmień',
      hint: 'Otwórz galerię',
    },
  },
  lightbox: {
    title: 'Galeria zasobów',
  },
}

const videoPlaylist = {
  title: 'Wideo playlista',
}

const website = {
  title: 'Strona www',
  field: {
    url: {
      text: 'URL',
    },
  },
}

const youtubePlaylist = {
  title: 'YouTube playlista',
  field: {
    url: {
      text: 'URL',
    },
    unmute: {
      text: 'Dźwięk',
    },
  },
}

export default {
  language: 'pl',
  messages: {
    common,
    playlistEditor,
    mediamanager,
    screenSaverRange,
    option: {
      airlyStation,
      biStation,
      biStations,
      clock,
      countdown,
      image,
      reference,
      screensaver,
      station,
      stations,
      syngeosStation,
      syngeosStations,
      text,
      video,
      videoPlaylist,
      website,
      youtubePlaylist,
    },
  },
}
