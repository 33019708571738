import { Component } from 'preact'
import { html } from 'htm/preact'

import { useTranslator } from '@eo-locale/preact'

import Thumbnail from './Video/Thumbnail.js'
import Placeholder from './Media/Placeholder.js'
import Manager from './Video/Manager.js'

import Dialog from '../Dialog.js'

/**
 * @typedef {Object} Item - Selected item
 * @property {string} url
 */

/**
 * @typedef {Object} AvailableItem
 * @property {string} url
 * @property {string} mime
 * @property {number} size
 * @property {number} timestamp
 * @property {boolean} isWorking
 */

/**
 * @typedef {Object} Options
 * @property {Item[]} items
 */

/**
 * @typedef {Object} Config
 * @property {string} apiUrl
 * @property {string} [placeholder]
 */

/**
 * @typedef {Object} ComponentProps
 * @property {Options} options
 * @property {Config} config
 * @property { import('../PlaylistEditor.js').setReportValidityInterface } setReportValidity
 * @property { import('../PlaylistEditor.js').onChange } onChange
 */

/**
 * @typedef {Object} ComponentState
 * @property {AvailableItem[]} availableItems
 */

/** @type {string} */
const emptyUrlValue = ''

/**
 * Multiple URLs
 * @extends Component<ComponentProps>
 */
export default class VideoPlaylist extends Component {
  /**
   * @inheritdoc
   * @return {ComponentProps}
   */
  static get defaultProps() {
    return {
      options: {
        items: [],
      },
      config: {
        apiUrl: undefined,
        placeholder: 'https://interactive-examples.mdn.mozilla.net/media/examples/flower.mp4',
      },
      setReportValidity: () => {},
      onChange: () => {},
    }
  }

  /**
   * @inheritdoc
   * @param {ComponentProps} props
   */
  constructor(props) {
    super(props)

    /** @type {ComponentState} */
    this.state = {
      availableItems: [],
    }

    this.handleSelectAvailableItem = this.handleSelectAvailableItem.bind(this)
  }

  /**
   * @inheritdoc
   */
  componentDidMount() {
    // Pass default props
    this.props.onChange(this.props.options)

    if (!this.props.config.apiUrl) {
      console.error('Video playlist: Please set apiUrl in config')
      return
    }

    // Fetch available items
    fetch(this.props.config.apiUrl)
      .then(response => response.json())
      .then((/** @type {AvailableItem[]} */ availableItems) =>
        this.setState({
          availableItems: availableItems.sort((itemA, itemB) =>
            itemB.timestamp - itemA.timestamp
          )
        })
      )
  }

  /**
   * Handle input value change
   * @param {number} index
   * @param {string} url
   * @return {void}
   */
  handleSelectAvailableItem(index, url) {
    let items

    // Add
    if (!this.props.options.items[index]) {
      items = [...this.props.options.items, {
        url
      }]
    // Remove
    } else if (this.props.options.items[index].url === url) {
      items = this.props.options.items.filter((cmpOption, cmpIndex) =>
        index !== cmpIndex
      )
    // Change
    } else {
      items = this.props.options.items.map((cmpOption, cmpIndex) =>
        index === cmpIndex
          ? { ...cmpOption, url }
          : cmpOption
      )
    }

    this.props.onChange({ items })
  }

  /**
   * @inheritdoc
   */
  render() {
    const translator = useTranslator()

    // Add append empty ite
    const items = [...this.props.options.items, { url: emptyUrlValue }]

    return html`
      <fieldset className="uk-fieldset uk-form-horizontal  bip-form-horizontal">
        <div>
          <label className="uk-form-label">
            ${translator.translate('option.video.field.video.text')}
          </label>
          <div className="uk-form-controls">
            ${items.map((item, index) => html`
              <div className="uk-margin-small">
                <div className="uk-card uk-card-default uk-margin-small">
                  <!-- Preview -->
                  <div className="uk-card-media-top uk-height-small  bip-card-media-top">
                    ${item.url
                      ? Thumbnail({ src: item.url })
                      : Placeholder()
                    }
                  </div>
                  <!-- Actions -->
                  <div className="uk-card-footer uk-padding-small">
                    <!-- Update -->
                    <${Dialog}
                      title=${translator.translate('option.video.lightbox.title')}
                      button=${{
                        title: translator.translate('option.video.action.change.hint'),
                        text: translator.translate('option.video.action.change.text'),
                        icon: 'pencil',
                      }}
                      showConfirm=${false}
                    >
                      <${Manager}
                        items=${this.state.availableItems}
                        selectedUrl=${item.url}
                        actionsEnabledOnSelected=${true}
                        onSelectButtonClick=${url => this.handleSelectAvailableItem(index, url)}
                        onRemoveButtonClick=${undefined}
                      />
                    </${Dialog}>
                  </div>
                </div>
              </div>
            `)}
          </div>
        </div>
      </fieldset>
    `
  }
}
