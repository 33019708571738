import { useAuth } from '../../Context/AuthProvider.jsx'

/**
 * @typedef { import('preact').ComponentClass } ComponentClass
 * @typedef { import('preact').FunctionComponent } FunctionComponent
 * @typedef { import('preact').VNode } VNode
 */

/**
 * Hight order component
 * @param {ComponentClass | FunctionComponent} WrappedComponent
 * @param {ComponentClass | FunctionComponent | null} [FallbackComponent]
 * @return {FunctionComponent}
 */
export default function withPermissionFactory (
  WrappedComponent,
  FallbackComponent = null,
) {
  const componentDisplayName = WrappedComponent.displayName || WrappedComponent.name || 'unknown'

  /**
   * @param {Object} props
   * @param {string} props.permission
   * @return {VNode}
   */
  const Wrapped = ({ permission, ...props }) => {
    const { isGranted } = useAuth()

    const Component = isGranted(permission)
      ? WrappedComponent
      : FallbackComponent

    return Component
      ? <Component {...props} />
      : null
  }

  Wrapped.displayName = `withPermission(${componentDisplayName})`

  return Wrapped
}
