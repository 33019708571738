import CONTENT_TYPES from '../../../Constants/ContentType.js'

import { getUniqueId } from '../../../utils/uid.js'

/**
 * @typedef { import('../../PlaylistEditor').rowParseInterface } rowParseInterface
 * @typedef { import('../../PlaylistEditor').rowFormatInterface } rowFormatInterface
 * @typedef { import('../../PlaylistEditor').rowCreateInterface } rowCreateInterface
 */

/**
 * @type {rowParseInterface}
 */
export function parse(rows) {
  // Create index <-> uid map
  const indexToUidMap = new Map(
    rows.map((row, index) => [index, getUniqueId()])
  )

  return rows.map((row, index) => {
    // Add uid
    const output = {
      ...row,
      uid: indexToUidMap.get(index),
    }

    const item = { ...row.item }

    // Replace index by uid in options
    if (
      item.type === CONTENT_TYPES.REFERENCE &&
      item.options
    ) {
      const { index, ...options } = item.options

      item.options = { ...options, uid: indexToUidMap.get(index) }
    }

    return { ...output, item }
  })
}

/**
 * @type {rowFormatInterface}
 */
export function format(rows) {
  // Create uid <-> index map
  const uidToIndexMap = new Map(
    rows.map((row, index) => [row.uid, index])
  )

  return rows.map(row => {
    // Remove uid
    const {
      uid, // eslint-disable-line
      ...output
    } = row

    const item = { ...row.item }

    // Replace uid by index in options
    if (
      item.type === CONTENT_TYPES.REFERENCE &&
      item.options
    ) {
      const { uid, ...options } = item.options

      item.options = { ...options, index: uidToIndexMap.get(uid) }
    }

    return { ...output, item }
  })
}

/**
 * @type {rowCreateInterface}
 */
export function create(row) {
  return {
    ...row,
    uid: getUniqueId(),
  }
}
