import { useAuth } from '../../Context/AuthProvider.jsx'
import NotFoundPage from '../../Pages/NotFound/NotFound.jsx'

/**
 * @typedef { import('preact').ComponentClass } ComponentClass
 * @typedef { import('preact').FunctionComponent } FunctionComponent
 * @typedef { import('preact').VNode } VNode
 */

/**
 *
 * @param {ComponentClass | FunctionComponent} WrappedComponent
 * @param {string[]} permissions
 * @param {ComponentClass | FunctionComponent} FallbackComponent
 * @return {FunctionComponent}
 */
export default function withPagePermissions (
  WrappedComponent,
  permissions,
  FallbackComponent = NotFoundPage
) {
  const componentDisplayName = WrappedComponent.displayName || WrappedComponent.name || 'unknown'

  /**
   * @param {Object} props
   * @return {VNode}
   */
  const Wrapped = props => {
    const { isGranted } = useAuth()

    const Component = permissions.every(permission => isGranted(permission))
      ? WrappedComponent
      : FallbackComponent

    return <Component {...props} />
  }

  Wrapped.displayName = `withPagePermissions(${componentDisplayName})`

  return Wrapped
}
