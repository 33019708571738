import { html } from 'htm/preact'
import { useRef, useEffect } from 'preact/hooks'

import classNames from 'classnames'

import { useTranslator } from '@eo-locale/preact'

import { useUniqueId } from '../../Hooks/uid.js'

import {
  formControlInvalid,
  createFormControlReportValidity,
} from '../../utils/constraintValidation.js'

/**
 * @typedef { import('preact/hooks').MutableRef<HTMLSelectElement|HTMLInputElement> } HTMLSelectElementRef
 */

/**
 * @typedef {Object} SyngeosStation
 * @property {number} id
 * @property {string} label
 * @property {string[]} [sensors] - Selected sensors
 */

/**
 * @typedef {Object} Config
 * @property {SyngeosStation[]} items - Available stations
 * @property {string[]} sensors - Available sensor constants
 * @property {number|null} maxSensors - Maximum number of sensors user may pick
 * @property {boolean}  manualEntry - Use Input instead of select
 */

/**
 * @typedef {Object} ComponentProps
 * @property {SyngeosStation} options
 * @property {Config} config
 * @property { import('../PlaylistEditor.js').setReportValidityInterface } setReportValidity
 * @property { import('../PlaylistEditor.js').onChange } onChange
 */

/**
 * Beskid Instruments station
 * @param {ComponentProps} props
 */
export default function SyngeosStation ({
  options = {
    id: undefined,
    label: null,
    sensors: [],
  },
  config = {
    items: [],
    sensors: [
      // 'pm1',
      'pm2_5',
      'pm10',
      'humidity',
      'air_pressure',
      'temperature',
      'co',
      'no2',
      'so2',
      // 'o3',
      // 'c6h6',
      // 'caqi',
      'noise',
      // 'soil_vwc',
      // 'soil_temperature',
      // 'soil_ap',
      'ch2o',
      // 'light_intensity',
    ],
    maxSensors: 5,
    manualEntry: false,
  },
  setReportValidity,
  onChange,
}) {
  /** @type {HTMLSelectElementRef} */
  const selectRef = useRef()

  const translator = useTranslator()

  const datalistId = `bip-options-syngeos-station-id-datalist-${useUniqueId()}`

  useEffect(() => {
    setReportValidity(
      createFormControlReportValidity(selectRef.current)
    )
  }, [])

  /**
   * Handle option ID change
   * @note For HTMLInputElement may use valueAsNumber
   * @param {Object} event
   * @param {HTMLSelectElement|HTMLInputElement} event.target
   * @return {void}
   */
  const handleOptionIdChange = event =>
    onChange({
      ...options,
      id: event.target.value ? Number.parseInt(event.target.value) : undefined,
    })

  /**
   * Handle label change
   * @param {Object} event
   * @param {HTMLInputElement} event.target
   * @return {void}
   */
  const handleOptionLabelChange = event =>
    onChange({
      ...options,
      label: event.target.value || null,
    })

  /**
   * Handle sensors change
   * @param {Object} event
   * @param {HTMLInputElement} event.target
   * @return {void}
   */
  const handleOptionSensorsChange = event => {
    const stateSensors = options.sensors || []

    const sensor = event.target.value
    const isAddition = event.target.checked

    let sensors = stateSensors

    // Remove
    if (!isAddition) {
      sensors = stateSensors.filter(cmpSensor => cmpSensor !== sensor)
    // Add within limit (over limit -> re-render)
    } else if (!config.maxSensors || stateSensors.length < config.maxSensors) {
      sensors = [...stateSensors, sensor]
    }

    if (!sensors.length) {
      sensors = undefined
    }

    return onChange({ ...options, sensors })
  }

  return html`
    <fieldset className="uk-fieldset uk-form-horizontal  bip-form-horizontal">
      <!-- ID -->
      <div className="uk-margin-small">
        <label className="uk-form-label">
          ${translator.translate('option.station.field.station.text')}
        </label>
        <div className="uk-form-controls">
          ${config.manualEntry
            ? html`
                <input
                  ref=${selectRef}
                  className=${classNames('uk-input uk-form-small', {
                    'uk-form-danger': formControlInvalid(selectRef.current),
                  })}
                  type="number"
                  value=${options.id}
                  placeholder="5664"
                  pattern="\d+"
                  required
                  min="1"
                  list=${datalistId}
                  onChange=${handleOptionIdChange}
                />
                <datalist id=${datalistId}>
                  ${config.items.map(station => html`
                    <option value=${station.id.toString()}>
                      ${station.label}
                    </option>
                  `)}
                </datalist>
              `
            : html`
                <select
                  ref=${selectRef}
                  className=${classNames('uk-select uk-form-small', {
                    'uk-form-danger': formControlInvalid(selectRef.current),
                  })}
                  value=${options.id}
                  required
                  onChange=${handleOptionIdChange}
                >
                  <option value="">
                    ${translator.translate('common.none.text')}
                  </option>
                  ${config.items.map(station => html`
                    <option key=${station.id} value=${station.id.toString()}>
                      ${station.id.toString()}: ${station.label}
                    </option>
                  `)}
                </select>
            `
          }
        </div>
      </div>
      <!-- Custom label -->
      <div className="uk-margin-small">
        <label
          className="uk-form-label"
          hidden
        >
          ${translator.translate('option.station.field.customLabel.text')}
        </label>
        <div className="uk-form-controls">
          <input
            className="uk-input uk-form-small"
            type="text"
            value=${options.label}
            disabled=${!options.id}
            placeholder=${translator.translate('option.station.field.customLabel.text')}
            onChange=${handleOptionLabelChange}
          />
        </div>
      </div>
      <!-- Sensors -->
      <div className="uk-margin-small">
        <label className="uk-form-label">
          <!-- Text -->
          ${translator.translate('option.station.field.sensors.text')}
          <!-- Hint -->
          ${config.maxSensors && html`
            <div className="uk-text-muted">
              ${translator.translate('option.station.field.sensors.hint', { maxSensors: config.maxSensors })}
            </div>
          `}
        </label>
        <div className="uk-form-controls uk-form-controls-text">
          ${config.sensors.map(code => html`
            <label className="uk-display-block">
              <input
                className="uk-checkbox"
                type="checkbox"
                value=${code}
                checked=${options.sensors && options.sensors.includes(code)}
                disabled=${options.sensors && !options.sensors.includes(code) && config.maxSensors && options.sensors.length >= config.maxSensors}
                onChange=${handleOptionSensorsChange}
              />
              <span className="uk-margin-small-left">
                <!-- Code -->
                ${translator.getMessageById(`option.station.field.sensors.options.${code}`, code)}
                <!-- Index -->
                ${options.sensors && options.sensors.includes(code) && html`
                  <span className="uk-badge uk-text-secondary uk-background-muted uk-margin-small-left">
                    ${`${options.sensors.indexOf(code) + 1}.`}
                  </span>
                `}
              </span>
            </label>
          `)}
        </div>
      </div>
    </fieldset>
  `
}
