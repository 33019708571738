import { html } from 'htm/preact'

import Thumbnail from './Thumbnail.js'

import {
  formatFileMime,
  formatFileSize,
  formatFileTimestamp,
} from '../Media/info.js'

import { useTranslator } from '@eo-locale/preact'

/** @typedef { import('../Video.js').AvailableItem } Item */

/**
 * Gallery dialog
 * @param {Object} props
 * @param {Item[]} props.items
 * @param {string|null} props.selectedUrl
 * @param {boolean} [props.actionsEnabledOnSelected]
 * @param {(url: string) => void} [props.onSelectButtonClick]
 * @param {(url: string) => void} [props.onRemoveButtonClick]
 */
export default function Manager({
  items = [],
  selectedUrl = null,
  actionsEnabledOnSelected = false,
  onSelectButtonClick,
  onRemoveButtonClick,
}) {
  const translator = useTranslator()

  return items.length
    ? html`
      <div
        className="uk-grid uk-child-width-1-2@s uk-child-width-1-4@m uk-child-width-1-6@xl"
        data-uk-grid
      >
        ${items.map(item => html`
          <div className="bip-manager-column">
            <div className=${`uk-card uk-card-${item.url === selectedUrl ? 'primary' : 'default'} uk-card-hover uk-card-small`}>
              <!-- Thumbnail -->
              <div className="uk-card-media-top uk-height-small  bip-card-media-top">
                <${Thumbnail}
                  src=${item.url}
                />
                <div
                  className="uk-overlay-primary uk-position-cover"
                  hidden=${!item.isWorking}
                >
                  <div
                    className="uk-position-center"
                    data-uk-spinner="ratio: 2"
                  ></div>
                </div>
              </div>
              <!-- Info -->
              <div className="uk-card-body">
                <ul className="uk-list uk-text-small uk-list-collapse uk-text-meta">
                  <li>${formatFileMime(item.mime)}</li>
                  <li>${formatFileSize(item.size)}</li>
                  <li>${formatFileTimestamp(item.timestamp)}</li>
                </ul>
              </div>
              <!-- Actions -->
              <fieldset
                className="uk-card-footer uk-fieldset uk-text-right"
                disabled=${item.isWorking || (item.url === selectedUrl && !actionsEnabledOnSelected)}
              >
                <div className="uk-button-group">
                  <!-- Select -->
                  ${onSelectButtonClick && html`
                    <button
                      className="uk-button uk-button-primary uk-button-small"
                      type="submit"
                      value=${item.url}
                      title=${translator.translate('mediamanager.action.select.text')}
                      onClick=${() => onSelectButtonClick(item.url)}
                    >
                      <span
                        className="uk-icon"
                        data-uk-icon="icon: check"
                      ></span>
                    </button>
                  `}
                  <!-- Remove -->
                  ${onRemoveButtonClick && html`
                    <button
                      className="uk-button uk-button-default uk-button-small uk-margin-small-left"
                      type="button"
                      title=${translator.translate('mediamanager.action.remove.text')}
                      onClick=${() => onRemoveButtonClick(item.url)}
                    >
                      <span
                        className="uk-icon"
                        data-uk-icon="icon: trash"
                      ></span>
                    </button>
                  `}
                </div>
              </fieldset>
            </div>
          </div>
        `)}
      </div>
    `
    : html`
      <div className="uk-alert uk-alert-warning">
        <span
          className="uk-icon uk-margin-small-right"
          data-uk-icon="icon: warning"
        ></span>
        ${translator.translate('mediamanager.message.empty')}
      </div>
    `
}
