import { useEffect, useMemo } from 'preact/hooks'
import { Link } from 'wouter-preact'
import { Formik, Form } from 'formik'
import clsx from 'clsx'

import routes from '../../routes.js'
import { useAuth } from '../../Context/AuthProvider.jsx'
import { useSignUp } from '../../Hooks/Api/useAuthApi.js'

import errorToStatusFactory from '../../Components/Form/errorToStatus.js'
import ValidationMessages from '../../Components/Form/ValidationMessages.jsx'
import ValidationError from '../../Error/ValidationError.js'
import Icon from '../../Components/Icon/Icon.jsx'

const initialValues = {
  name: null,
  organisation: null,
  email: null,
  password: null,
  passwordConfirm: null,
  tos: false,
}

const getStatusFromError = errorToStatusFactory(initialValues)

/**
 * Sign Up page
 *
 * @type {preact.FunctionComponent}
 */
export default function SignUp() {

  const { authenticate } = useAuth()
  const { data: authResponse, error: authError, isLoading, trigger: signUp } = useSignUp()

  useEffect(() => {
    if (authResponse) {
      authenticate(authResponse)
    }
  }, [authResponse])

  // Compute formik status
  const status = useMemo(() => getStatusFromError(authError), [authError])

  let tabIndex = 0

  return (
    <Formik
      initialValues={initialValues}
      initialStatus={status}
      enableReinitialize={true}
      validate={validate}
      onSubmit={values => signUp(values)}
    >
      {formik =>
        <Form className="uk-form-stacked">
          <fieldset
            className="uk-fieldset"
            disabled={isLoading}
          >
            <h1 className="uk-card-title">
              {'Create account!'}
            </h1>

            {/** Name */}
            <div className="uk-margin-small">
              <label
                className="uk-form-label"
                htmlFor="mp-signup-form-name"
                hidden
              >
                {'Name'}
              </label>
              <div className="uk-form-controls">
                <div className="uk-inline uk-width-1-1">
                  <Icon
                    className="uk-form-icon uk-form-icon-flip"
                    icon="user"
                  />
                  <input
                    id="mp-signup-form-name"
                    className={clsx('uk-input', {'uk-form-danger': formik.errors.name && formik.touched.name})}
                    type="text"
                    placeholder={'Your name'}
                    autoComplete="name"
                    tabIndex={++tabIndex}
                    {...formik.getFieldProps('name')}
                  />
                </div>
                <ValidationMessages name="name" />
              </div>
            </div>

            {/** Organisation */}
            <div className="uk-margin-small">
              <label
                className="uk-form-label"
                htmlFor="mp-signup-form-organisation"
                hidden
              >
                {'Organisation'}
              </label>
              <div className="uk-form-controls">
                <div className="uk-inline uk-width-1-1">
                  <Icon
                    className="uk-form-icon uk-form-icon-flip"
                    icon="users"
                  />
                  <input
                    id="mp-signup-form-organisation"
                    className={clsx('uk-input', {'uk-form-danger': formik.errors.organisation && formik.touched.organisation})}
                    type="text"
                    placeholder={'Organisation'}
                    autoComplete="organization"
                    tabIndex={++tabIndex}
                    {...formik.getFieldProps('organisation')}
                  />
                </div>
                <ValidationMessages name="organisation" />
              </div>
            </div>

            {/** Email */}
            <div className="uk-margin-small">
              <label
                className="uk-form-label"
                htmlFor="mp-signup-form-email"
                hidden
              >
                {'Email'}
              </label>
              <div className="uk-form-controls">
                <div className="uk-inline uk-width-1-1">
                  <Icon
                    className="uk-form-icon uk-form-icon-flip"
                    icon="mail"
                  />
                  <input
                    id="mp-signup-form-email"
                    className={clsx('uk-input', {'uk-form-danger': formik.errors.email && formik.touched.email})}
                    type="email"
                    placeholder={'Your email'}
                    autoComplete="email"
                    autoFocus={true}
                    tabIndex={++tabIndex}
                    {...formik.getFieldProps('email')}
                  />
                </div>
                <ValidationMessages name="email" />
              </div>
            </div>

            {/** Password */}
            <div className="uk-margin-small">
              <label
                className="uk-form-label"
                htmlFor="mp-signin-form-password"
                hidden
              >
                {'Password'}
              </label>
              <div className="uk-form-controls">
                <div className="uk-inline uk-width-1-1">
                  <Icon
                    className="uk-form-icon uk-form-icon-flip"
                    icon="lock"
                  />
                  <input
                    id="mp-signin-form-password"
                    className={clsx('uk-input', {'uk-form-danger': formik.errors.password && formik.touched.password})}
                    type="password"
                    placeholder={'Password'}
                    autoComplete="new-password"
                    tabIndex={++tabIndex}
                    {...formik.getFieldProps('password')}
                  />
                </div>
                <ValidationMessages name="password" />
              </div>
            </div>

            {/** Confirm password */}
            <div className="uk-margin-small">
              <label
                className="uk-form-label"
                htmlFor="mp-signin-form-password-confirm"
                hidden
              >
                {'Confirm password'}
              </label>
              <div className="uk-form-controls">
                <div className="uk-inline uk-width-1-1">
                  <Icon
                    className="uk-form-icon uk-form-icon-flip"
                    icon="lock"
                  />
                  <input
                    id="mp-signin-form-password-confirm"
                    className={clsx('uk-input', {'uk-form-danger': formik.errors.passwordConfirm && formik.touched.passwordConfirm})}
                    type="password"
                    placeholder={'Confirm password'}
                    autoComplete="new-password"
                    tabIndex={++tabIndex}
                    {...formik.getFieldProps('passwordConfirm')}
                  />
                </div>
                <ValidationMessages name="passwordConfirm" />
              </div>
            </div>

            {/** TOS */}
            <div className="uk-margin-small">
              <div className="uk-form-controls">
                <div className="uk-inline uk-width-1-1">
                  <label className="uk-form-label">
                    <input
                      id="mp-signin-form-tos"
                      className="uk-checkbox"
                      type="checkbox"
                      tabIndex={++tabIndex}
                      {...formik.getFieldProps('tos')}
                    />
                    <span className="uk-margin-small-left">
                      {'I agree to terms of service'}
                    </span>
                  </label>
                </div>
                <ValidationMessages name="tos" />
              </div>
            </div>

            {/** Submit button */}
            <div className="uk-margin-top">
              <button
                className="uk-button uk-button-primary uk-width-1-1"
                type="submit"
                tabIndex={++tabIndex}
              >
                {'Get started'}
              </button>
            </div>
          </fieldset>

          {/** Error handling */}
          <div>
            { authError && authError instanceof ValidationError === false &&
              <div className='uk-alert uk-alert-danger uk-margin-top'>
                <p>
                  {authError.message || 'Error'}
                </p>
              </div>
            }
          </div>

          <ul className="uk-list uk-text-small">
            <li>
              <span className="uk-margin-small-right">
                {'Already have an account?'}
              </span>
              <Link
                className="uk-link-text"
                href={routes.auth.signIn}
              >
                {'Sign In'}
              </Link>
            </li>
          </ul>
        </Form>
      }
    </Formik>
  )
}

/**
 * Validate form
 * @param {Object} values
 * @return {Object<string, string>}
 */
function validate(values) {
  /** @type {Object<string, string>} */
  const errors = {}

  if (!values.name) {
    errors.name = 'required'
  }

  if (!values.organisation) {
    errors.organisation = 'required'
  }

  if (!values.email) {
    errors.email = 'required'
  }

  if (!values.password) {
    errors.password = 'required'
  }

  if (!values.passwordConfirm || values.passwordConfirm !== values.password) {
    errors.passwordConfirm = 'equals,password'
  }

  if (!values.tos) {
    errors.tos = 'required'
  }

  return errors
}
