import { html } from 'htm/preact'
import { useRef, useEffect } from 'preact/hooks'

import classNames from 'classnames'

import { useTranslator } from '@eo-locale/preact'

import {
  formControlInvalid,
  createFormControlsReportValidity,
} from '../../utils/constraintValidation.js'

/**
 * @typedef { import('preact/hooks').MutableRef<HTMLInputElement> } HTMLInputElementRef
 */

/**
 * @typedef {Object} AirlyStation
 * @property {string} id
 * @property {string} label
 * @property {string} apiKey
 */

/**
 * @typedef {Object} Config
 */

/**
 * @typedef {Object} ComponentProps
 * @property {AirlyStation} options
 * @property {Config} config
 * @property { import('../PlaylistEditor.js').setReportValidityInterface } setReportValidity
 * @property { import('../PlaylistEditor.js').onChange } onChange
 */

/**
 * Airly station
 * @param {ComponentProps} props
 */
export default function AirlyStation ({
  options = {
    id: undefined,
    label: undefined,
    apiKey: undefined,
  },
  setReportValidity,
  onChange,
}) {

  /** @type {HTMLInputElementRef} */
  const idInputRef = useRef()

  /** @type {HTMLInputElementRef} */
  const labelInputRef = useRef()

  /** @type {HTMLInputElementRef} */
  const apiKeyInputRef = useRef()

  const translator = useTranslator()

  useEffect(() => {
    setReportValidity(
      createFormControlsReportValidity([
        idInputRef.current,
        labelInputRef.current,
        apiKeyInputRef.current,
      ])
    )
  }, [])

  /**
   * Handle option ID change
   * @param {Object} event
   * @param {HTMLInputElement} event.target
   * @return {void}
   */
  const handleOptionIdChange = event =>
    onChange({
      ...options,
      id: event.target.value ? Number.parseInt(event.target.value, 10) : undefined,
    })

  /**
   * Handle label change
   * @param {Object} event
   * @param {HTMLInputElement} event.target
   * @return {void}
   */
  const handleOptionLabelChange = event =>
    onChange({
      ...options,
      label: event.target.value || undefined,
    })

    /**
     * Handle API Key change
     * @param {Object} event
     * @param {HTMLInputElement} event.target
     * @return {void}
     */
  const handleApiKeyChange = event =>
    onChange({
      ...options,
      apiKey: event.target.value || undefined,
    })

  return html`
    <fieldset className="uk-fieldset uk-form-horizontal  bip-form-horizontal">
      <!-- ID -->
      <div className="uk-margin-small">
        <label className="uk-form-label">
          ${translator.translate('option.station.field.station.text')}
        </label>
        <div className="uk-form-controls">
          <input
            ref=${idInputRef}
            className=${classNames('uk-input uk-form-small', {
              'uk-form-danger': formControlInvalid(idInputRef.current)
            })}
            type="number"
            value=${options.id}
            placeholder="7816"
            pattern="\d+"
            required
            min="0"
            onChange=${handleOptionIdChange}
          />
        </div>
      </div>
      <!-- Custom label -->
      <div className="uk-margin-small">
        <label className="uk-form-label">
          ${translator.translate('option.station.field.customLabel.text')}
        </label>
        <div className="uk-form-controls">
          <input
            ref=${labelInputRef}
            className=${classNames('uk-input uk-form-small', {
              'uk-form-danger': formControlInvalid(labelInputRef.current)
            })}
            type="text"
            value=${options.label}
            placeholder=${translator.translate('option.station.field.customLabel.text')}
            required
            onChange=${handleOptionLabelChange}
          />
        </div>
      </div>
      <!-- API Key -->
      <div className="uk-margin-small">
        <label
          className="uk-form-label"
        >
          ${translator.translate('option.airlyStation.field.apiKey.text')}
        </label>
        <div className="uk-form-controls">
          <input
            ref=${apiKeyInputRef}
            className=${classNames('uk-input uk-form-small', {
              'uk-form-danger': formControlInvalid(apiKeyInputRef.current)
            })}
            type="text"
            value=${options.apiKey}
            placeholder="123456789012345678901234567890ab"
            required
            onChange=${handleApiKeyChange}
          />
        </div>
      </div>
    </fieldset>
  `
}
