import { useMemo } from 'preact/hooks'
import { Link } from 'wouter-preact'

import routes from '../../routes.js'
import { useAuth } from '../../Context/AuthProvider.jsx'
import { withPagePermissions } from '../../Components/Guard/index.jsx'
import PERMISSION from '../Screen/screen.permission.js'
import { GLOBAL_CONTEXT } from '../../Constants/permission.js'

import {
  useIndex,
  useDelete,
} from '../../Hooks/Api/useRestApi.js'
import { useOrganisations } from '../../Context/Organisations.jsx'

import Environment from '../../Components/Environment/Environment.jsx'
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb.jsx'
import Icon from '../../Components/Icon/Icon.jsx'
import DateTime from '../../Components/DateTime/DateTime.jsx'
import OnlineIndicator from '../../Components/OnlineIndicator/OnlineIndicator.jsx'
import LoadingOverlay from '../../Components/LoadingOverlay/LoadingOverlay.jsx'

import { confirm } from '../../utils/modal.js'

import styles from './screens.module.css'

/**
 * @typedef { import('../../Hooks/Api/useRestApi.js').IndexResponse<App.Entity.Screen> } ScreenIndexResponse
 * @typedef { import('../../Hooks/Api/useRestApi.js').DeleteResponse<App.Entity.Screen> } ScreenDeleteResponse
 */

/** @type {App.Entity.Screen[]} */
const initialData = []

/**
 * Screens collection page
 *
 * @type {preact.FunctionComponent}
 */
function ScreenIndex() {
  // Note: 5 minutes is default for Emag screens
  const refreshIntervalSeconds = useMemo(() =>
    Number.parseFloat(import.meta.env.VITE_SCREENS_REFRESH_INTERVAL),
    []
  )

  // Default online state lifetime
  const scrensOnlineLifetime = useMemo(() =>
    Number.parseFloat(import.meta.env.VITE_SCREENS_ONLINE_LIFETIME),
    []
  )

  /** @type {ScreenIndexResponse} */
  const { items = initialData, isLoading, error: loadingError, mutate } = useIndex('/screens', true, {
    refreshInterval: refreshIntervalSeconds * 1_000
  })

  const { find: findOrganisation } = useOrganisations()

  /** @type {ScreenDeleteResponse} */
  const { isDeleting, deleter } = useDelete('/screens', undefined)

  const { isGranted } = useAuth()

  /**
   * @param {App.Entity.Screen} item
   */
  async function handleDeleteClick(item) {
    if (isDeleting) {
      return
    }

    if (!await confirm()) {
      return
    }

    await deleter(item)
    await mutate(cachedItems =>
      cachedItems && cachedItems.filter(cachedItem => cachedItem.id !== item.id)
    )
  }

  return (
    <section className={`uk-article ${styles.screens}`}>
      {/** Breadcrumbs */}
      <Breadcrumb items={[{ text: 'Screens' }]} />

      <div className="uk-card uk-card-default uk-card-small">
        <div className="uk-card-header uk-flex uk-flex-between">
          <h1 className="uk-card-title uk-margin-remove">
            {'Screens'}
          </h1>

          {/** Actions */}
          <ul className="uk-iconnav">
            {isGranted(PERMISSION.CREATE) &&
              <li>
                <Link
                  href={routes.screen.create}
                  className="uk-icon"
                  data-uk-icon="icon: plus"
                  data-uk-tooltip={'Add'}
                />
              </li>
            }
          </ul>
        </div>

        <div className="uk-card-body uk-overflow-auto">
          <table className="uk-table uk-table-divider uk-table-striped uk-table-hover uk-table-small uk-table-middle uk-table-responsive">
            <thead>
              <tr>
                <th className="uk-table-shrink">{'Id'}</th>
                <th className="uk-table-expand">{'Label'}</th>
                {isGranted(GLOBAL_CONTEXT.ACCESS) &&
                  <th className="uk-table-shrink">{'Organisation'}</th>
                }
                <th>{'Geo coords'}</th>
                <th>{'Environment'}</th>
                <th>{'Version'}</th>
                <th className="uk-table-shrink">{'Online'}</th>
                <th>{'Heartbeat'}</th>
                <th className="uk-table-shrink">{'Actions'}</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, key) =>
                <tr key={key}>
                  {/** Id */}
                  <td>{item.id}</td>
                  {/** Label */}
                  <td className="uk-text-bold">{item.label}</td>
                  {/** Organisation */}
                  {isGranted(GLOBAL_CONTEXT.ACCESS) &&
                    <td>{findOrganisation(item.organisationId)?.name ?? '-'}</td>
                  }
                  {/** Geo coords */}
                  <td>{formatGeoCoords(item.geoCoords)}</td>
                  {/** Environment */}
                  <td><Environment environment={item.environment} /></td>
                  {/** Version */}
                  <td className="uk-text-small">{item.version}</td>
                  {/** Online */}
                  <td className="uk-table-shrink uk-text-center">
                    <OnlineIndicator
                      heartbeat={item.heartbeat}
                      offlineAfter={refreshIntervalSeconds + scrensOnlineLifetime}
                    />
                  </td>
                  {/** Heartbeat */}
                  <td className="uk-text-small">
                    <DateTime timestamp={item.heartbeat} />
                  </td>
                  {/** Actions */}
                  <td>
                    <fieldset
                      className="uk-fieldset uk-text-right uk-text-left@m"
                      disabled={isDeleting}
                    >
                      <div className="uk-button-group">
                        {/** Read */}
                        {isGranted(PERMISSION.READ) &&
                          <Link
                            href={routes.screen.read.replace(':id', item.id.toString())}
                            className="uk-button uk-button-primary uk-button-small"
                          >
                            <Icon icon="eye" />
                          </Link>
                        }
                        {/** Update */}
                        {isGranted(PERMISSION.UPDATE) &&
                          <Link
                            href={routes.screen.update.replace(':id', item.id.toString())}
                            className="uk-button uk-button-primary uk-button-small"
                          >
                            <Icon icon="pencil" />
                          </Link>
                        }
                        {/** Delete */}
                        {isGranted(PERMISSION.DELETE) &&
                          <button
                            className="uk-button uk-button-danger uk-button-small"
                            type="button"
                            onClick={() => handleDeleteClick(item)}
                          >
                            <Icon icon="trash" />
                          </button>
                        }
                      </div>
                    </fieldset>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <LoadingOverlay
          loadingError={loadingError}
          isLoading={isLoading}
        />
      </div>
    </section>
  )
}

export default withPagePermissions(ScreenIndex, [PERMISSION.INDEX])

/**
 * Format geo coordinates
 * @param {Array<number, number>|null} geoCoords
 * @return {string}
 */
function formatGeoCoords(geoCoords) {
  if (!geoCoords) {
    return '-'
  }

  return geoCoords
    .map(coordinate => coordinate.toFixed(4))
    .join(', ')
}
