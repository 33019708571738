/**
 * Parse duration in H:M:S.ms format
 * @param {string} durationString
 * @return {number} Duration in miliseconds
 * @throws {RangeError}
 */
export function parseDuration(durationString) {
  const [
    hours = 0,
    minutes = 0,
    seconds = 0,
  ] = durationString
    .split(':', 3)
    .map(Number.parseFloat)

  // Convert seconds' fraction to miliseconds
  // const miliseconds = seconds * 1e3 % 1e3

  // Use Date as converter and validator at the same time
  const dateTs = Date.UTC(1970, 0, 1, hours, minutes, seconds) // , miliseconds)

  if (Number.isNaN(dateTs)) {
    throw new RangeError('Invalid range')
  }

  return dateTs
}

/**
 * Format duration in miliseconds to H:M:S.ms format
 * @param {number} value
 * @return {string}
 */
export function formatDuration(value) {
  const hours =   Math.floor(value / 1e3 / 60 / 60)
  const minutes = Math.floor(value / 1e3 / 60 % 60)
  const seconds = Math.floor(value / 1e3 % 60)
  // const miliseconds = value % 1e3


  const durationString = [hours, minutes, seconds]
    .map(fragment => fragment.toString())
    .map(fragment => fragment.padStart(2, '0'))
    .join(':')

  return durationString

  // Append miliseconds
  // return `${durationString}.${miliseconds.toString().padEnd(3, '0')}`
}
