/**
 * Feature dedection
 */

// Dialog element
const dialogElement = document.createElement('dialog')

const isDialogAvailable = ('showModal' in dialogElement)

// Firefox workaround
export { isDialogAvailable }
