/**
 * @typedef { import('../Components/PlaylistEditor.js').PlaylistRow } PlaylistRow
 */

/**
 * Compute rows duration
 * @param {PlaylistRow[]} rows
 * @param {boolean} [countDisabled]
 * @return {number}
 */
export function getDuration(rows, countDisabled = false) {
  return rows
    .filter(row => countDisabled || filterDisabled(row))
    .reduce((acc, row) =>
      acc + (row.item.duration * 1e3),
      0
    )
}

/**
 * Compute rows count
 * @param {PlaylistRow[]} rows
 * @param {boolean} [countDisabled] - Count in disabled items
 * @return {number}
 */
export function getCount(rows, countDisabled = false) {
  return rows
    .filter(row => countDisabled || filterDisabled(row))
    .length
}

/**
 * Filter disabled item
 * @param {PlaylistRow} row
 * @return {boolean}
 */
function filterDisabled(row) {
  return !row.item.disabled
}
