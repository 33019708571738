import { Switch, Route, Link } from 'wouter-preact'

import SignIn from '../../Pages/SignIn/SignIn.jsx'
import SignUp from '../../Pages/SignUp/SignUp.jsx'

import routes from '../../routes.js'

import logoPath from '../../Images/logo.svg'
import styles from './start.module.css'

const now = new Date()

/**
 * Sign in/up
 * @type {preact.FunctionComponent}
 */
export default function Sign() {
  return (
    <div className="uk-flex uk-flex-center uk-flex-middle uk-height-viewport">
      <div className="uk-card uk-card-default uk-card-small uk-card-body uk-width-medium uk-text-center">
        <div className={styles['start-logo-container']}>
          <Link href={routes.dashboard}>
            <img
              src={logoPath}
              width="120"
              alt="Logo"
            />
          </Link>
        </div>
        <Switch>
          {/** Sign Up */}
          {import.meta.env.VITE_APP_FEATURE_SIGN_UP === 'true' &&
            <Route component={SignUp} path={routes.auth.signUp} />
          }
          {/** Any */}
          <Route component={SignIn} />
        </Switch>
      </div>
      {/** Footnote */}
      <div className="uk-position-bottom-center uk-position-small">
        <div className="uk-text-small uk-text-muted">
          <span>
            {`© ${now.getFullYear()}`}
          </span>
          <a
            className="uk-margin-small-left"
            href="https://syngeos.pl"
            target="external"
          >
            {'syngeos.pl'}
          </a>
          <span className="uk-margin-small-left">
            { `v${__APP_VERSION__}` }
          </span>
        </div>
      </div>
    </div>
  )
}
