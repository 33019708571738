/**
 * Generic application error
 */
export default class AppError extends Error {
  /**
   * @inheritdoc
   * @param {string} message
   */
  constructor(message) {
    super(message)

    this.name = 'AppError'

    // V8 only, doesn't prepend cause
    // @ts-ignore
    if (Error.captureStackTrace) {
      // @ts-ignore
      Error.captureStackTrace(this, new.target)
    }

    // Set the prototype explicitly.
    // https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, AppError.prototype)
  }
}
