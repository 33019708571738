import ValidationError from '../../Error/ValidationError.js'

/**
 * Get formik status factory function
 * @param {Object<string, any>} initialData - Data to build status schema from
 * @return {(error: Error) => Object<string, string[]>}
 */
export default function errorToStatusFactory(initialData) {
  // Create initial status
  const keys = Object.keys(initialData)
  const entries = keys.map(key => [key, []])
  const initialStatus = Object.fromEntries(entries)

  // Add errors from ValidationError context
  return error => error instanceof ValidationError
    ? { ...initialStatus, ...error.context }
    : initialStatus
}
