import clsx from 'clsx'

import Icon from '../Icon/Icon.jsx'

/**
 * Component loading overlay
 * @type {preact.FunctionComponent}
 * @param {object} props
 * @param {boolean} props.isLoading
 * @param {Error} props.loadingError
 */
const LoadingOverlay = ({
  loadingError = undefined,
  isLoading = false,
}) => (
  <div
    className={clsx('uk-overlay uk-overlay-default uk-position-cover uk-flex uk-flex-center uk-flex-middle ', {
      'uk-animation-fade uk-animation-reverse uk-disabled': !isLoading && !loadingError,
    })}
  >
    {/** Loading */}
    {isLoading &&
      <span
        className="uk-position-center"
        data-uk-spinner="ratio: 1"
      ></span>
    }
    {/** Error */}
    {loadingError &&
      <div className="uk-text-danger">
        <Icon
          icon="warning"
          className="uk-margin-small-right"
        />
        <span className="uk-text-middle">
          {loadingError.message || 'Error'}
        </span>
      </div>
    }
  </div>
)

export default LoadingOverlay
