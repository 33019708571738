import { useState, useEffect } from 'preact/hooks'
import { Formik } from 'formik'

import { withPagePermissions } from '../../Components/Guard/index.jsx'
import PERMISSION from './playlist.permission.js'

import { useRead } from '../../Hooks/Api/useRestApi.js'

import PlaylistForm from './PlaylistForm.jsx'

import { initialData } from './playlistStore.js'

/**
 * @typedef { import('../../Hooks/Api/useRestApi.js').ReadResponse<App.Entity.Playlist> } PlaylistReadResponse
 */

/**
 * Playlist read item page
 *
 * @type {preact.FunctionComponent<{ id: string }>}
 */
function PlaylistRead({
  params,
}) {
  const id = Number.parseInt(params.id)

  /** @type {PlaylistReadResponse} */
  const { item, isLoading, error: loadingError } = useRead('/playlists', id)

  const [ formItem, setFormItem ] = useState({ ...initialData, id })
  const [ isHydrated, setIsHydrated ] = useState(false)

  useEffect(() => {
    if (isHydrated) {
      return
    }

    if (item) {
      setFormItem(item)
      setIsHydrated(true)
    }
  }, [item])

  return (
    <Formik
      initialValues={formItem}
      enableReinitialize={true}
      onSubmit={undefined}
    >
      <PlaylistForm
        readOnly
        id={id}
        loadingError={loadingError}
        isLoading={isLoading}
      />
    </Formik>
  )
}

export default withPagePermissions(PlaylistRead, [PERMISSION.READ])
