import ENVIRONMENT from './environment.constants.js'

/**
 * Environment label component
 * @param {Object} props
 * @param {string} props.environment
 */
export default function Environment({ environment }) {
  let style = 'undefined'
  let label = '?'

  switch (environment) {
    case ENVIRONMENT.PRODUCTION:
      style = 'success'
      label = 'production'
      break
    case ENVIRONMENT.STAGING:
      style = 'warning'
      label = 'staging'
      break
  }

  return (
    <span className={`uk-label uk-label-${style}`}>
      {label}
    </span>
  )
}
