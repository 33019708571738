import { useState, useEffect } from 'preact/hooks'
import { Formik } from 'formik'

import { withPagePermissions } from '../../Components/Guard/index.jsx'
import PERMISSION from './screen.permission.js'

import { useRead } from '../../Hooks/Api/useRestApi.js'

import ScreenForm from './ScreenForm.jsx'
import { initialData } from './screenStore.js'

const { pinCode, ...updateInitialData } = initialData

/**
 * @typedef { import('../../Hooks/Api/useRestApi.js').ReadResponse<App.Entity.Screen> } ScreenReadResponse
 */

/**
 * Screen read item page
 *
 * @type {preact.FunctionComponent<{ id: string }>}
 */
function ScreenRead({
  params,
}) {
  const id = Number.parseInt(params.id)

  /** @type {ScreenReadResponse} */
  const { item, isLoading, error: loadingError } = useRead('/screens', id)

  const [ formItem, setFormItem ] = useState({ ...updateInitialData, id })
  const [ isHydrated, setIsHydrated ] = useState(false)

  useEffect(() => {
    if (isHydrated) {
      return
    }

    if (item) {
      setFormItem(item)
      setIsHydrated(true)
    }
  }, [item])

  return (
    <Formik
      initialValues={formItem}
      enableReinitialize={true}
      onSubmit={undefined}
    >
      <ScreenForm
        readOnly
        id={id}
        loadingError={loadingError}
        isLoading={isLoading}
      />
    </Formik>
  )
}

export default withPagePermissions(ScreenRead, [PERMISSION.READ])
