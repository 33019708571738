import { errorForResponse } from '../../Error/map.js'
import ConnectionError from '../../Error/ConnectionError.js'

/**
 * Fetch wrapped by error handling
 * @param {RequestInfo} requestInfo
 * @param {RequestInit} [requestInit]
 * @param {string} [accessToken]
 * @return {Promise<any>}
 * @throws {ConnectionError|Error}
 * @see https://swr.vercel.app/docs/error-handling#status-code-and-error-object
 * @see https://react-query.tanstack.com/guides/query-functions#usage-with-fetch-and-others-clients-that-do-not-throw-by-default
 */
 export async function wrappedFetch(requestInfo, requestInit = undefined, accessToken = undefined) {
  /** @throws {TypeError} */
  const request = new Request(requestInfo, requestInit)

  // Set content type only when body is present
  if (request.body) {
    request.headers.set('content-type', 'application/json')
  }

  // Set up authorization
  if (accessToken) {
    request.headers.set('authorization', `Bearer ${accessToken}`)
  }

  /** @type {Response} */
  let response

  try {
    response = await fetch(request)
  } catch (typeError) {
    throw new ConnectionError()
  }

  if (!response.ok) {
    throw await errorForResponse(response)
  }

  /** @throws {SyntaxError} */
  return response.json()
}

