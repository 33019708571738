import { useAuth } from './Context/AuthProvider.jsx'

import Panel from './Components/Panel/Panel.jsx'
import Start from './Components/Start/Start.jsx'

/**
 * App component
 * @type {preact.FunctionComponent}
 */
export default function App() {
  const { isAuthenticated } = useAuth()

  return (
    <div className="app">
      { isAuthenticated
        ? <Panel />
        : <Start />
      }
    </div>
  )
}
