/**
 * Compile items
 * use either as
 * - middleware between DB and API
 * - or by client
 */

import CONTENT_TYPE from '../Constants/ContentType.js'

/**
 * @typedef { import('../Components/PlaylistEditor').PlaylistItem } PlaylistItem
 */

/**
 * Interfaces
 * @typedef {(playlistItemsApi: PlaylistItem[]) => PlaylistItemClient[]} compilerInterface
 * @typedef {(playlistItemsApi: PlaylistItem[]) => void} asserterInteface
 */

/**
 * @typedef {Object} PlaylistItemClient
 * @property {number} duration
 * @property {CONTENT_TYPE} type
 * @property {Object|undefined} options
 */

/**
 * Apply compilers
 * @type {compilerInterface}
 */
export default items => {
  /** @type {asserterInteface[]} */
  const asserters = [
    assertInvalidReferences,
  ]

  /** @type {compilerInterface[]} */
  const compilers = [
    // Used only by Playlist Editor
    removeLabelProp,
    // Remove disabled items and property, reassigning reference indexes
    removeDisabledArrayImpl,
    // When handling Reference types is not implemented
    replaceReferences,
  ]

  /** @throws {Error} */
  asserters.forEach(assert => assert(items))

  // Apply compilers
  return compilers.reduce(
    (items, compiler) => compiler(items),
    items
  )
}

/**
 * Assert references
 * @type {asserterInteface}
 * @throws {ReferenceError}
 */
function assertInvalidReferences(items) {
  items.forEach((item, index) => {
    if (
      item.type === CONTENT_TYPE.REFERENCE &&
      // Note: This also works when value item.options.index is undefined
      items[item.options.index] === undefined
    ) {
      throw new ReferenceError(`Reference: invalid index ${item.options.index} for item #${index}`)
    }
  })
}

/**
 * Remove label prop
 * @type {compilerInterface}
 */
function removeLabelProp(items) {
  return items.map(({ label, ...item }) => item) // eslint-disable-line no-unused-vars
}

/**
 * Replace references with copied items
 * @type {compilerInterface}
 */
function replaceReferences(items) {
  return items.map((item, index) => {
    if (item.type === CONTENT_TYPE.REFERENCE) {
      const target = items[item.options.index]

      // Validate target
      if (target === undefined) {
        throw new ReferenceError(`Reference: invalid index ${item.options.index} for item #${index}`)
      }

      // Copy type & options keeping rest
      return {
        ...item,
        type: target.type,
        // Note: options may be undefined in target
        options: target.options,
      }
    }

    return item
  })
}

/**
 * Remove disabled while keeping reference index (Array implementation)
 * @type {compilerInterface}
 * @throws {ReferenceError}
 */
function removeDisabledArrayImpl(items) {
  // Filter
  const filteredItems = items
    .filter(item =>
      item.type === CONTENT_TYPE.REFERENCE && items[item.options.index].disabled
        // Remove references to disabled items
        ? false
        // Remove disabled items
        : !item.disabled
    )

  // Mutations
  return filteredItems
    .map(item => {
      // Copy while removing disabled prop
      const { disabled, ...outputItem } = item // eslint-disable-line no-unused-vars

      // Update reference index
      if (item.type === CONTENT_TYPE.REFERENCE) {
        // Get new index
        const newRefIndex = filteredItems.indexOf(items[item.options.index])

        if (newRefIndex === -1) {
          throw new ReferenceError(`Reference: Invalid index ${item.options.index}`)
        }

        outputItem.options = { ...item.options, index: newRefIndex }
      }

      return outputItem
    })
}

/**
 * Remove disabled while keeping reference index (Map implementation)
 * @type {compilerInterface}
 * @throws {ReferenceError}
 */
function removeDisabledMapImpl(items) { // eslint-disable-line no-unused-vars
  // Convert to map
  const map = new Map(items.entries())

  // Filter
  map.forEach((item, index) => {
    // Remove references to disabled items
    if (
      item.type === CONTENT_TYPE.REFERENCE &&
      // Note: Doesn't validate if target exists
      items[item.options.index].disabled
    ) {
      map.delete(index)
    }

    // Remove disabled items
    if (item.disabled) {
      map.delete(index)
    }
  })

  // Mutations
  map.forEach((item, index) => {
    // Copy while removing disabled prop
    const { disabled, ...outputItem } = item // eslint-disable-line no-unused-vars

    // Update references
    if (
      item.type === CONTENT_TYPE.REFERENCE &&
      map.has(item.options.index)
    ) {
      // Get target
      const target = map.get(item.options.index)

      // Get new index
      const newRefIndex = Array.from(map.values()).indexOf(target)

      if (newRefIndex === -1) {
        throw new ReferenceError(`Reference: Invalid index ${item.options.index}`)
      }

      outputItem.options = { ...item.options, index: newRefIndex }
    }

    map.set(index, outputItem)
  })

  return Array.from(map.values())
}
