import { useRef, useEffect } from 'preact/hooks'

import { html } from 'htm/preact'

import classNames from 'classnames'

import { useTranslator } from '@eo-locale/preact'

import { useUniqueId } from '../../Hooks/uid.js'

import {
  formControlInvalid,
  createFormControlsReportValidity
} from '../../utils/constraintValidation.js'

/**
 * @typedef { import('preact/hooks').MutableRef<HTMLTextAreaElement> } HTMLTextAreaRef
 * @typedef { import('preact/hooks').MutableRef<HTMLInputElement> } HTMLInputRef
 */

/**
 * @typedef {Object} Options
 * @property {string} text
 * @property {ANIMATION} animation
 * @property {number|null} animationRepeatCount
 * @property {string|COLOR} color
 */

/**
 * @typedef {Object} ComponentProps
 * @property {Options} options
 * @property { import('../PlaylistEditor.js').setReportValidityInterface } setReportValidity
 * @property { import('../PlaylistEditor.js').onChange } onChange
 */

/**
 * @typedef {string|null} Animation
 * @enum {Animation}
 */
const ANIMATION = {
  NONE: null,
  SLIDE_HORIZONTAL: 'slide-horizontal',
  SLIDE_VERTICAL: 'slide-vertical',
}

/**
 * @typedef {string|null} Color
 * @enum {Color}
 */
const COLOR = {
  DEFAULT: null,
  FALLBACK: '#f0506e', // See .bip-renderer--text__content
}

/** @type {Array<{value: string, label: string}>} - Predefined colors */
const optionColorOptions = [
  { value: '#f0506e', label: 'Red' },
  { value: '#f0d24f', label: 'Yellow' },
  { value: '#6df04f', label: 'Green' },
  { value: '#4ff0d2', label: 'Cyan' },
  { value: '#4f6df0', label: 'Blue' },
  { value: '#d24ff0', label: 'Magenta' },
]

/**
 * Text options
 * @param {ComponentProps} props
 * @see {@link https://developers.google.com/web/updates/2012/08/datalist-for-range-color-inputs-offer-some-default-choices}
 */
export default function Text ({
  options = {
    text: '',
    animation: ANIMATION.NONE,
    animationRepeatCount: null,
    color: COLOR.DEFAULT,
  },
  setReportValidity,
  onChange,
}) {
  /** @type {HTMLTextAreaRef} */
  const inputRefText = useRef()
  /** @type {HTMLInputRef} */
  const inputRefColor = useRef()

  const translator = useTranslator()

  const datalistId = `bip-options-textarea-color-datalist-${useUniqueId()}`

  useEffect(() => {
    setReportValidity(
      createFormControlsReportValidity([
        inputRefText.current,
        inputRefColor.current,
      ])
    )
  }, [])

  /**
   * Handle option text change
   * @param {Object} event
   * @param {HTMLInputElement} event.target
   * @return {void}
   */
  const handleTextChange = event =>
    onChange({
      ...options,
      text: event.target.value,
    })

  /**
   * Handle animation change
   * @param {Object} event
   * @param {HTMLSelectElement} event.target
   * @return {void}
   */
  const handleAnimationChange = event => {
    const animation = event.target.value === ''
      ? ANIMATION.NONE
      : event.target.value

    onChange({
      ...options,
      animation,
      // Reset repeat count for animation:none, default to 1 for other
      animationRepeatCount: animation === ANIMATION.NONE
        ? null
        : options.animationRepeatCount ?? 1
    })
  }

  /**
   * Handle animation repeat count change
   * @param {Object} event
   * @param {HTMLInputElement} event.target
   * @return {void}
   */
  const handleAnimationRepeatCountChange = event => {
    // Note: Input is hidden anyway
    if (options.animation === ANIMATION.NONE) {
      return onChange({
        ...options,
        animationRepeatCount: null,
      })
    }

    const valueAsNumber = Number.parseFloat(event.target.value)

    onChange({
      ...options,
      animationRepeatCount: Number.isNaN(valueAsNumber)
        // Reset input to previous value
        ? options.animationRepeatCount
        : Math.max(1, valueAsNumber),
    })
  }

  /**
   * Handle background color default toggle
   * @param {Object} event
   * @param {HTMLInputElement} event.target
   * @return {void}
   */
  const handleColorChangeDefault = event =>
    onChange({
      ...options,
      color: event.target.checked
        ? COLOR.DEFAULT
        : COLOR.FALLBACK
    })

  /**
   * Handle color change
   * @param {Object} event
   * @param {HTMLInputElement} event.target
   * @return {void}
   */
  const handleColorChange = event =>
    onChange({
      ...options,
      color: event.target.value,
    })

  return html`
    <fieldset className="uk-fieldset uk-form-horizontal  bip-form-horizontal">
      <!-- Text -->
      <div className="uk-margin-small">
        <label className="uk-form-label">
          ${translator.translate('option.text.field.text.text')}
        </label>
        <div className="uk-form-controls">
          <textarea
            ref=${inputRefText}
            className=${classNames('uk-textarea uk-form-small', {
              'uk-form-danger': formControlInvalid(inputRefText.current),
            })}
            rows="${options.text.split('\n').length}"
            placeholder=${translator.translate('option.text.field.text.placeholder')}
            required
            inputmode="text"
            onChange=${handleTextChange}
          >${ options.text }</textarea>
        </div>
      </div>
      <!-- Animation -->
      <div className="uk-margin-small">
        <label className="uk-form-label">
          ${translator.translate('option.text.field.animation.text')}
        </label>
        <div className="uk-form-controls">
          <select
            className="uk-select uk-form-small uk-form-width-small"
            value=${options.animation}
            onChange=${handleAnimationChange}
          >
            <option value=${''}>${translator.translate('option.text.field.animation.option.none')}</option>
            <option value=${ANIMATION.SLIDE_HORIZONTAL}>${translator.translate('option.text.field.animation.option.horizontal')}</option>
            <option value=${ANIMATION.SLIDE_VERTICAL}>${translator.translate('option.text.field.animation.option.vertical')}</option>
          </select>
        </div>
      </div>
      <!-- Repeat -->
      <div
        className="uk-margin-small"
        hidden=${options.animation === ANIMATION.NONE}
      >
        <label className="uk-form-label">
          ${translator.translate('option.text.field.repeat.text')}
        </label>
        <div className="uk-form-controls">
          <input
            className="uk-input uk-form-small uk-form-width-xsmall uk-text-right"
            type="number"
            value=${options.animationRepeatCount}
            placeholder="1"
            min="1"
            step="any"
            inputmode="numeric"
            onChange=${handleAnimationRepeatCountChange}
          />
        </div>
      </div>
      <!-- Color -->
      <div className="uk-margin-small">
        <label className="uk-form-label">
          ${translator.translate('option.text.field.color.text')}
        </label>
        <div className="uk-form-controls uk-form-controls-text">
          <label>
            <input
              className="uk-checkbox uk-margin-small-right"
              type="checkbox"
              checked=${options.color == COLOR.DEFAULT}
              onChange=${handleColorChangeDefault}
            />
            ${translator.translate('common.default.text')}
          </label>
        </div>
        <div
          className="uk-form-controls uk-margin-small-top"
          hidden=${options.color == COLOR.DEFAULT}
        >
          <input
            ref=${inputRefColor}
            className=${classNames('uk-input uk-form-small uk-form-width-small bip-color-picker', {
              'uk-form-danger': formControlInvalid(inputRefColor.current),
            })}
            type="color"
            value=${options.color == COLOR.DEFAULT ? COLOR.FALLBACK : options.color}
            pattern="#[0-9a-f]{6}"
            list=${datalistId}
            onChange=${handleColorChange}
          />
          <datalist id=${datalistId}>
            ${optionColorOptions.map(({value, label}) => html`
              <option value=${value}>${label}</option>
            `)}
          </datalist>
        </div>
      </div>
    </fieldset>
  `
}
