import { html } from 'htm/preact'
import { useRef, useEffect } from 'preact/hooks'

import classNames from 'classnames'

import { useTranslator } from '@eo-locale/preact'

import {
  formControlInvalid,
  createFormControlReportValidity,
} from '../../utils/constraintValidation.js'

/**
 * @typedef { import('preact/hooks').MutableRef<HTMLSelectElement> } HTMLSelectElementRef
 */

/**
 * @typedef {Object} BiStation
 * @property {string} id
 * @property {string} label
 */

/**
 * @typedef {Object} Config
 * @property {BiStation[]} items
 */

/**
 * @typedef {Object} ComponentProps
 * @property {BiStation} options
 * @property {Config} config
 * @property { import('../PlaylistEditor.js').setReportValidityInterface } setReportValidity
 * @property { import('../PlaylistEditor.js').onChange } onChange
 */

/**
 * Beskid Instruments station
 * @param {ComponentProps} props
 */
export default function BiStation ({
  options = {
    id: undefined,
    label: null,
  },
  config = {
    items: [],
  },
  setReportValidity,
  onChange,
}) {
  /** @type {HTMLSelectElementRef} */
  const selectRef = useRef()

  const translator = useTranslator()

  useEffect(() => {
    setReportValidity(
      createFormControlReportValidity(selectRef.current)
    )
  }, [])

  /**
   * Handle option ID change
   * @param {Object} event
   * @param {HTMLSelectElement} event.target
   * @return {void}
   */
  const handleOptionIdChange = event =>
    onChange({
      ...options,
      id: event.target.value || undefined,
    })

  /**
   * Handle label change
   * @param {Object} event
   * @param {HTMLInputElement} event.target
   * @return {void}
   */
  const handleOptionLabelChange = event =>
    onChange({
      ...options,
      label: event.target.value || null,
    })

  return html`
    <fieldset className="uk-fieldset uk-form-horizontal  bip-form-horizontal">
      <!-- ID -->
      <div className="uk-margin-small">
        <label className="uk-form-label">
          ${translator.translate('option.station.field.station.text')}
        </label>
        <div className="uk-form-controls">
          <select
            ref=${selectRef}
            className=${classNames('uk-select uk-form-small', {
              'uk-form-danger': formControlInvalid(selectRef.current),
            })}
            value=${options.id}
            required
            onChange=${handleOptionIdChange}
          >
            <option value="">
              ${translator.translate('common.none.text')}
            </option>
            ${config.items.map(station => html`
              <option value=${station.id}>
                ${station.id}: ${station.label}
              </option>
            `)}
          </select>
        </div>
      </div>
      <!-- Custom label -->
      <div className="uk-margin-small">
        <label
          className="uk-form-label"
          hidden
        >
          ${translator.translate('option.station.field.customLabel.text')}
        </label>
        <div className="uk-form-controls">
          <input
            className="uk-input uk-form-small"
            type="text"
            value=${options.label}
            disabled=${!options.id}
            placeholder=${translator.translate('option.station.field.customLabel.text')}
            onChange=${handleOptionLabelChange}
          />
        </div>
      </div>
    </fieldset>
  `
}
