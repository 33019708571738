import AbstractHttpError from './AbstractHttpError.js'

/**
 * Server error (500)
 */
export default class ServerError extends AbstractHttpError {
  /**
   * @inheritdoc
   * @param {string} message
   * @param {Response} [response]
   */
  constructor(message = 'Internal server error.', response) {
    super(message, response)

    this.name = 'ServerError'

    Object.setPrototypeOf(this, ServerError.prototype)
  }
}
