import { connect, getIn } from 'formik'

/**
 * @typedef { import('formik').ErrorMessageProps } FormikErrorMessageProps
 * @typedef { import('formik').FormikContextType<any> } FormikContextType
 * @typedef { import('formik').ErrorMessage } FormikErrorMessage
 */

/**
 * Form validation messages
 * @type {preact.FunctionComponent}
 * @param {FormikErrorMessageProps & { formik: FormikContextType }} props
 * @see https://formik.org/docs/api/errormessage
 * @see https://formik.org/docs/api/connect
 * @see https://github.com/formium/formik/blob/master/packages/formik/src/ErrorMessage.tsx
 */
function ValidationMessagesImpl({
  name,
  formik,
}) {
  /** @type {boolean} */
  const touch = getIn(formik.touched, name)
  /** @type {string|undefined} */
  const error = getIn(formik.errors, name)
  /** @type {string[]|undefined} */
  const status = getIn(formik.status, name)

  return (
    <ul className="uk-list uk-list-collapse uk-text-left mp-form-errors">
      {error && touch &&
        <li className="uk-text-warning">
          {error}
        </li>
      }
      {status && status.map((message, index) =>
        <li className="uk-text-danger" key={index}>
          {message}
        </li>
      )}
    </ul>
  )
}

/** @type {FormikErrorMessage} */
export default connect(ValidationMessagesImpl)
