import { useState, useMemo } from 'preact/hooks'
import { useLocation } from 'wouter-preact'
import { Formik } from 'formik'
import UIkit from 'uikit'

import routes from '../../routes.js'
import { withPagePermissions } from '../../Components/Guard/index.jsx'
import PERMISSION from './organisation.permission.js'

import {
  useIndex,
  useCreate,
} from '../../Hooks/Api/useRestApi.js'

import OrganisationForm from './OrganisationForm.jsx'
import { initialData, getStatusFromError, transformValues, validate } from './organisationStore.js'

/**
 * @typedef { import('../../Hooks/Api/useRestApi.js').IndexResponse<App.Entity.Organisation> } OrganisationIndexResponse
 * @typedef { import('../../Hooks/Api/useRestApi.js').CreateResponse<App.Entity.Organisation> } OrganisationCreateResponse
 */

/**
 * Organisation create item page
 *
 * @type {preact.FunctionComponent}
 */
function OrganisationCreate() {

  const [ , route ] = useLocation()

  /** @type {OrganisationCreateResponse} */
  const { error: createError, isCreating, creator } = useCreate('/organisations')

  /** @type {OrganisationIndexResponse} */
  const { mutate: mutateItems } = useIndex('/organisations', false)

  const [ formItem, setFormItem ] = useState(initialData)

  const status = useMemo(() => getStatusFromError(createError), [createError])

  /**
   * Handle submit
   * @param {Object} values
   */
  async function handleFormSubmit(values, { setSubmitting }) {
    /** @type {App.Entity.Organisation} */
    const item = {
      ...formItem,
      ...transformValues(values),
    }

    const createdItem = await creator(item)
    setSubmitting(false)

    if (!createdItem) {
      UIkit.notification({ message: 'Error', status: 'warning' })
      return
    }

    // Add to cached items
    await mutateItems((/** @type {App.Entity.Organisation[]|undefined} */ cachedItems) =>
      cachedItems && [...cachedItems, createdItem],
      false
    )

    setFormItem(item)

    UIkit.notification({ message: 'Item created', status: 'success' })

    // Redirect
    route(routes.organisation.index)
  }

  return (
    <Formik
      initialValues={formItem}
      initialStatus={status}
      enableReinitialize={true}
      validate={values => validate(values, true)}
      onSubmit={handleFormSubmit}
    >
      <OrganisationForm
        id={null}
        loadingError={undefined}
        isLoading={false}
        isSyncing={isCreating}
      />
    </Formik>
  )
}

export default withPagePermissions(OrganisationCreate, [PERMISSION.CREATE])
