import { useState, useEffect, useMemo } from 'preact/hooks'
import { Formik } from 'formik'
import UIkit from 'uikit'

import { withPagePermissions } from '../../Components/Guard/index.jsx'
import PERMISSION from './playlist.permission.js'

import {
  useIndex,
  useRead,
  useUpdate,
} from '../../Hooks/Api/useRestApi.js'

import PlaylistForm from './PlaylistForm.jsx'
import { initialData, getStatusFromError, validate, transformValues }  from './playlistStore.js'

/**
 * @typedef { import('../../Hooks/Api/useRestApi.js').ReadResponse<App.Entity.Playlist> } PlaylistReadResponse
 * @typedef { import('../../Hooks/Api/useRestApi.js').UpdateResponse<App.Entity.Playlist> } PlaylistUpdateResponse
 */

/**
 * Playlist create item page
 *
 * @type {preact.FunctionComponent}
 */
function PlaylistUpdate({
  params,
}) {
  const id = Number.parseInt(params.id)

  /** @type {PlaylistReadResponse} */
  const { item, isLoading, error: loadingError, mutate } = useRead('/playlists', id)
  /** @type {PlaylistUpdateResponse} */
  const { isUpdating, error: updateError, updater } = useUpdate('/playlists', id)

  const { mutate: mutateItems } = useIndex('/playlists', false)

  const [ formItem, setFormItem ] = useState({ ...initialData, id })
  const [ isHydrated, setIsHydrated ] = useState(false)

  useEffect(() => {
    // Prevent fresh updates to overwrite form values
    if (isHydrated) {
      return
    }

    if (item) {
      setFormItem(item)
      setIsHydrated(true)
    }
  }, [item])

  const status = useMemo(() => getStatusFromError(updateError), [updateError])

  /**
   * @param {Object} values
   */
  async function handleFormSubmit(values, { setSubmitting }) {
    /** @type {App.Entity.Playlist} */
    const item = {
      ...formItem,
      ...transformValues(values),
    }

    /** @type {App.Entity.Playlist} */
    const updatedItem = await mutate(updater(item), false)
    setSubmitting(false)

    if (!updatedItem) {
      UIkit.notification({ message: 'Error', status: 'warning' })
      return
    }

    // Update cached items
    await mutateItems((/** @type {App.Entity.Playlist[]|undefined} */ cachedItems) =>
      cachedItems?.map(cachedItem => cachedItem.id === item.id
        ? { ...updatedItem }
        : cachedItem
      ),
      false
    )

    setFormItem(item)
    UIkit.notification({ message: 'Item updated', status: 'success' })
  }

  // Skip to prevent playlist items updates
  // TODO: Remove after ditching Biotic Playlist eidtor widget
  if (!isHydrated) {
    return null
  }

  return (
    <Formik
      initialValues={formItem}
      initialStatus={status}
      enableReinitialize={true}
      validate={values => validate(values, false)}
      onSubmit={handleFormSubmit}
    >
      <PlaylistForm
        id={id}
        loadingError={loadingError}
        isLoading={isLoading || !isHydrated}
        isSyncing={isUpdating}
      />
    </Formik>
  )
}

export default withPagePermissions(PlaylistUpdate, [PERMISSION.UPDATE])
