import { html } from 'htm/preact'

/**
 * Thumbnail video
 * @param {Object} props
 * @param {string|null} props.src
 * @param {number} [props.time]
 */
export default function Thumbnail ({
  src,
  time = 1,
}) {
  if (!src) {
    return null
  }

  return html`
    <video
      className="bip-media-preview"
      controls
      controlslist="nodownload"
      disablePictureInPicture
      disableRemotePlayback
      crossorigin="anonymous"
      muted
      playsinline
      preload="metadata"
      src=${`${src}#t=${time.toString()}`}
    />
  `
}
