import errorToStatusFactory from '../../Components/Form/errorToStatus.js'
import { initialData as userInitialData } from '../User/userStore.js'

export const initialData = userInitialData

export const getStatusFromError = errorToStatusFactory(initialData)

/**
 * Validate form
 * @param {Object} values
 * @return {Object<string, string>}
 */
export function validate(values) {
  /** @type {Object<string, string>} */
  const errors = {}

  if (!values.name) {
    errors.name = 'required'
  }

  if (values.password !== values.passwordConfirm) {
    errors.passwordConfirm = 'equals,password'
  }

  return errors
}

/**
 * @param {Object} values
 * @return {Omit<App.Entity.User, 'id' | 'email' | 'organisationId' | 'roles' | 'state'>}
 */
export function transformValues(values) {
  return {
    name: values.name,
    password: values.password || null,
    passwordConfirm: values.passwordConfirm || null,
    language: values.language || null,
  }
}
