import { html } from 'htm/preact'

/**
 * Placeholder image
 */
export default () => html`
  <svg
    className="bip-media-preview"
    width="185"
    height="170"
    viewBox="0 0 185 170"
  >
    <path
      d="M15 130.896V16.994c0-1.097.898-1.994 2.007-1.994h150.986A2 2 0 0 1 170 16.994v69.147L133.015 51 87.26 120.377l-35.863-17.758L15 130.896zM2 0C.895 0 0 .887 0 2v166c0 1.105.887 2 2 2h181c1.105 0 2-.887 2-2V2c0-1.105-.887-2-2-2H2zm49 72c11.046 0 20-9.178 20-20.5S62.046 31 51 31s-20 9.178-20 20.5S39.954 72 51 72z"
      fill="#b3b3b3"
    />
  </svg>
`
