import AppError from './AppError.js'

/**
 * Connection error
 */
export default class ConnectionError extends AppError {
  /**
   * @inheritdoc
   * @param {string} [message]
   */
  constructor(message = 'No connection.') {
    super(message)

    this.name = 'ConnectionError'

    Object.setPrototypeOf(this, ConnectionError.prototype)
  }
}
