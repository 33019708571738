import { Component, createRef } from 'preact'

import { html } from 'htm/preact'

import classNames from 'classnames'

import { useTranslator } from '@eo-locale/preact'

import {
  formControlInvalid,
  createFormControlReportValidity
} from '../../utils/constraintValidation.js'

/**
 * @typedef { import('preact/hooks').MutableRef<HTMLInputElement> } HTMLInputElementRef
 */

/**
 * @typedef {Object} Options
 * @property {number} dateTimestamp
 * @property {string} text
 */

/**
 * @typedef {Object} Config
 * @property {number} dateMinTimestamp
 */

/**
 * @typedef {Object} ComponentProps
 * @property {Options} options
 * @property {Config} config
 * @property { import('../PlaylistEditor.js').setReportValidityInterface } setReportValidity
 * @property { import('../PlaylistEditor.js').onChange } onChange
 */

/**
 * Text options
 * @extends Component<ComponentProps>
 */
export default class Countdown extends Component {
  /**
   * @inheritdoc
   * @return {ComponentProps}
   */
  static get defaultProps() {
    return {
      options: {
        dateTimestamp: undefined,
        text: null,
      },
      config: {
        dateMinTimestamp: Date.now() / 1e3,
      },
      setReportValidity: undefined,
      onChange: undefined,
    }
  }

  /**
   * @inheritdoc
   * @param {ComponentProps} props
   */
  constructor(props) {
    super(props)

    /** @type {HTMLInputElementRef} */
    this.inputDatetimeRef = createRef()

    this.handleDateTimestampChange = this.handleDateTimestampChange.bind(this)
    this.handleTextChange = this.handleTextChange.bind(this)
  }

  /**
   * @inheritdoc
   */
  componentDidMount() {
    this.props.setReportValidity(
      createFormControlReportValidity(this.inputDatetimeRef.current)
    )
  }

  /**
   * Handle date timestamp change
   * @param {Object} event
   * @param {HTMLInputElement} event.target
   * @return {void}
   */
  handleDateTimestampChange(event) {
    let dateTimestamp

    try {
      // While typing
      // Note: event.target.valueAsDate is null
      dateTimestamp = Countdown.parseDateTime(event.target.value)
    } catch (error) {
      return
    }

    if (dateTimestamp < 0) {
      return
    }

    this.props.onChange({
      ...this.props.options,
      // Note: event.target.valueAsNumber is Unix timestamp in local time and not UTC :/
      dateTimestamp
    })
  }

  /**
   * Handle text change
   * @param {Object} event
   * @return {void}
   */
  handleTextChange(event) {
    this.props.onChange({
      ...this.props.options,
      text: event.target.value || null,
    })
  }

  /**
   * @inheritdoc
   */
  render() {
    const translator = useTranslator()

    return html`
      <fieldset className="uk-fieldset uk-form-horizontal  bip-form-horizontal">
        <div className="uk-margin-small">
          <label className="uk-form-label">
            ${translator.translate('option.countdown.field.datetime.text')}
          </label>
          <div className="uk-form-controls">
            <!-- Note: Firefox 93+ -->
            <input
              ref=${this.inputDatetimeRef}
              className=${classNames('uk-input uk-form-small uk-form-width-medium', {
                'uk-form-danger': formControlInvalid(this.inputDatetimeRef.current),
              })}
              type="datetime-local"
              value=${this.props.options.dateTimestamp && Countdown.formatDateTime(this.props.options.dateTimestamp)}
              step="1"
              min=${Countdown.formatDateTime(this.props.config.dateMinTimestamp)}
              required
              onChange=${this.handleDateTimestampChange}
            />
          </div>
        </div>

        <div className="uk-margin-small">
          <label className="uk-form-label">
            ${translator.translate('option.countdown.field.text.text')}
          </label>
          <div className="uk-form-controls">
            <textarea
              className="uk-textarea uk-form-small uk-form-width-medium uk-resize-vertical"
              onChange=${this.handleTextChange}
              rows=${this.props.options.text ? this.props.options.text.split('\n').length : 1}
              inputmode="text"
            >${this.props.options.text}</textarea>
          </div>
        </div>
      </fieldset>
    `
  }

  /**
   * Parse time
   * @param {string} string [yyyy]-[MM]-[dd]T[HH]:[mm]:[ss]
   * @return {number}
   */
  static parseDateTime(string) {
    const timestamp = Date.parse(string)

    if (isNaN(timestamp)) {
      throw new RangeError('Invalid date')
    }

    return timestamp / 1e3
  }

  /**
   * Format to ISO in current time zone
   * {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/datetime-local}
   * @param {number} unixTimestamp
   * @return {string}
   */
  static formatDateTime(unixTimestamp) {
    const date = new Date(unixTimestamp * 1e3)

    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()

    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()

    return (
      year.toString().padStart(4, '0') +
      '-' + month.toString().padStart(2, '0') +
      '-' + day.toString().padStart(2, '0') +
      'T' +
      hours.toString().padStart(2, '0') +
      ':' + minutes.toString().padStart(2, '0') +
      ':' + seconds.toString().padStart(2, '0')
    )
  }
}
