import AppError from './AppError.js'
import ConnectionError from './ConnectionError.js'
import AuthenticationError from './AuthenticationError.js'
import AuthorizationError from './AuthorizationError.js'
import NotFoundError from './NotFoundError.js'
import ServerError from './ServerError.js'
import ValidationError from './ValidationError.js'

/**
 * Map unsuccessful HTTP response to specific AppError
 * @param {Response} response
 * @return {Promise<AppError>}
 */
export async function errorForResponse(response) {
  switch (response.status) {
    // 400: Bad request exception
    case 400:
      return await ValidationError.createFromResponse(response)
    // 401: Unauthorized (not authenticated/ missing authorization header)
    case 401:
      return await AuthenticationError.createFromResponse(response)
    // 403: Forbidden (no permission to read/ write)
    case 403:
      return await AuthorizationError.createFromResponse(response)
    // 404: Not found
    case 404:
      return await NotFoundError.createFromResponse(response)
    // 405: Method not allowed
    case 405:
      break
    // 500: Internal server error
    case 500:
      return await ServerError.createFromResponse(response)
    // 501: Not implemented
    case 501:
      break
    default:
      break
  }

  return new AppError(`Invalid response (${response.status}: ${response.statusText})`)
}

/**
 * Map TypeError to ConnectionError, not used ATM
 * @param {TypeError|Error} error
 * @return {AppError}
 */
export function mapError(error) {
  if (error instanceof TypeError) {
    return new ConnectionError('Cannot connect to API');
  }

  // Note: there might be a SyntaxError related to JSON parsing

  if (error instanceof AppError) {
    return error
  }

  return new AppError(error.message)
}
