import { Component } from 'preact'

import { html } from 'htm/preact'

import classNames from 'classnames'

import { useTranslator } from '@eo-locale/preact'

import {
  formControlInvalid,
  createFormControlsRefReportValidity,
} from '../../utils/constraintValidation.js'

/**
 * @typedef { import('preact/hooks').MutableRef<Array<HTMLSelectElement|HTMLInputElement>> } HTMLSelectElementRefs
 */

/**
 * @typedef {Object} SyngeosStation
 * @property {number} id
 * @property {string} label
 */

/**
 * @typedef {Object} Options
 * @property {SyngeosStation[]} items
 * @property {string|null} title
 * @property {string[]} messages
 */

/**
 * @typedef {Object} Config
 * @property {SyngeosStation[]} items
 * @property {boolean} [manualEntry]
 */

/**
 * @typedef {Object} ComponentProps
 * @property {Options} options
 * @property {Config} config
 * @property { import('../PlaylistEditor.js').setReportValidityInterface } setReportValidity
 * @property { import('../PlaylistEditor.js').onChange } onChange
 */

const emptyIdValue = ''

/**
 * Multiple Synges stations
 * @extends Component<ComponentProps>
 */
export default class SyngeosStations extends Component {
  /**
   * @inheritdoc
   * @return {ComponentProps}
   */
  static get defaultProps() {
    return {
      options: {
        items: [],
        title: null,
        messages: [],
      },
      config: {
        items: [],
        manualEntry: false,
      },
      setReportValidity: () => {},
      onChange: () => {},
    }
  }

  /**
   * @inheritdoc
   * @param {ComponentProps} props
   */
  constructor(props) {
    super(props)

    /** @type {HTMLSelectElementRefs} */
    this.inputsRef = { current: [] }

    this.handleOptionItemChange = this.handleOptionItemChange.bind(this)
    this.handleOptionLabelChange = this.handleOptionLabelChange.bind(this)
    this.handleOptionTitleChange = this.handleOptionTitleChange.bind(this)
    this.handleOptionMessagesChange = this.handleOptionMessagesChange.bind(this)
  }

  /**
   * @inheritdoc
   */
  componentDidMount() {
    // Pass default props
    this.props.onChange(this.props.options)

    this.props.setReportValidity(
      createFormControlsRefReportValidity(this.inputsRef)
    )
  }

  /**
   * @inheritdoc
   * @param {ComponentProps} prevProps
   */
  componentDidUpdate(prevProps) {
    // Update refs array length
    if (this.props.options.items.length !== prevProps.options.items.length) {
      this.inputsRef.current = this.inputsRef.current.slice(0, this.props.options.items.length + 1)

      // Rerender with reassigned refs
      this.forceUpdate()
    }
  }

  /**
   * Handle select value change
   * @param {number} index
   * @param {Object} event
   * @param {HTMLSelectElement|HTMLInputElement} event.target
   * @return {void}
   */
  handleOptionItemChange(index, event) {
    const id = Number.parseInt(event.target.value)

    let items

    // Add
    if (index === this.props.options.items.length) {
      items = [...this.props.options.items, { id, label: null }]
    // Remove
    } else if (event.target.value === emptyIdValue) { // TODO: doesn't work correctly when removing items in the middle
      items = this.props.options.items.filter((cmpItem, cmpIndex) =>
        index !== cmpIndex
      )
    // Change
    } else {
      items = this.props.options.items.map((cmpItem, cmpIndex) =>
        index === cmpIndex
          ? { ...cmpItem, id }
          : cmpItem
      )
    }

    this.props.onChange({
      ...this.props.options,
      items,
    })
  }

  /**
   * Handle item custom label change
   * @param {number} index
   * @param {Object} event
   * @return {void}
   */
  handleOptionLabelChange(index, event) {
    const items = this.props.options.items.map((cmpItem, cmpIndex) =>
      index === cmpIndex
        ? { ...cmpItem, label: event.target.value || null }
        : cmpItem
    )

    this.props.onChange({
      ...this.props.options,
      items,
    })
  }

  /**
   * Handle title change
   * @param {Object} event
   * @param {HTMLInputElement} event.target
   * @return {void}
   */
  handleOptionTitleChange(event) {
    const title = event.target.value || null

    this.props.onChange({
      ...this.props.options,
      title,
    })
  }

  /**
   * Handle messages change
   * @param {Object} event
   * @param {HTMLTextAreaElement} event.target
   * @return {void}
   */
  handleOptionMessagesChange(event) {
    const messages = event.target.value
      ? event.target.value.split('\n')
      : []

    this.props.onChange({
      ...this.props.options,
      messages,
    })
  }

  /**
   * @inheritdoc
   */
  render() {
    const translator = useTranslator()

    // Append empty item at the end
    const items = [
      ...this.props.options.items,
      { id: emptyIdValue, label: null }
    ]

    return html`
      <fieldset className="uk-fieldset uk-form-horizontal  bip-form-horizontal">
        <!-- Stations -->
        ${items.map((item, index) => html`
          <fieldset className="uk-fieldset uk-margin-small">
            <!-- ID -->
            <div className="uk-margin-small">
              <label className="uk-form-label">
                ${translator.translate('option.stations.field.station.text', { index: index + 1 })}
              </label>
              <div className="uk-form-controls">
                ${this.props.config.manualEntry
                  ? html`
                      <input
                        ref=${element => this.inputsRef.current[index] = element}
                        className=${classNames('uk-select uk-form-small', {
                          'uk-form-danger': formControlInvalid(this.inputsRef.current[index]),
                        })}
                        type="number"
                        value=${item.id}
                        placeholder="5664"
                        pattern="\d+"
                        required=${!index || index !== this.props.options.items.length}
                        min="1"
                        onChange=${event => this.handleOptionItemChange(index, event)}
                      />
                    `
                  : html`
                      <select
                        ref=${element => this.inputsRef.current[index] = element}
                        className=${classNames('uk-select uk-form-small', {
                          'uk-form-danger': formControlInvalid(this.inputsRef.current[index]),
                        })}
                        value=${item.id}
                        required=${!index || index !== this.props.options.items.length}
                        onChange=${event => this.handleOptionItemChange(index, event)}
                      >
                        <option value="">
                          ${translator.translate('common.none.text')}
                        </option>
                        ${this.props.config.items.map(station => html`
                          <option value=${station.id}>
                            ${station.id}: ${station.label}
                          </option>
                        `)}
                      </select>
                    `
                }
              </div>
            </div>
            <!-- Custom label -->
            <div className="uk-margin-small">
              <label
                className="uk-form-label"
                hidden
              >
                ${translator.translate('option.station.field.customLabel.text')}
              </label>
              <div className="uk-form-controls">
                <input
                  className="uk-input uk-form-small"
                  type="text"
                  value=${item.label}
                  disabled=${item.id === emptyIdValue}
                  placeholder=${translator.translate('option.station.field.customLabel.text')}
                  inputmode="text"
                  onChange=${event => this.handleOptionLabelChange(index, event)}
                />
              </div>
            </div>
          </fieldset>
        `)}

        <!-- Title -->
        <div className="uk-margin-small">
          <label className="uk-form-label">
            ${translator.translate('option.stations.field.title.text')}
          </label>
          <div className="uk-form-controls">
            <input
              className=${classNames('uk-input uk-form-small')}
              type="text"
              value=${this.props.options.title}
              placeholder=${translator.translate('option.stations.field.title.placeholder')}
              inputmode="text"
              onChange=${this.handleOptionTitleChange}
            />
          </div>
        </div>

        <!-- Messages -->
        <div className="uk-margin-small">
          <label className="uk-form-label">
            ${translator.translate('option.stations.field.messages.text')}
          </label>
          <div className="uk-form-controls">
            <textarea
              className=${classNames('uk-textarea uk-form-small')}
              rows="${this.props.options.messages.length || 1}"
              placeholder=${translator.translate('option.stations.field.messages.placeholder')}
              onChange=${this.handleOptionMessagesChange}
            >${ this.props.options.messages.join('\n') }</textarea>
          </div>
        </div>
      </fieldset>
    `
  }
}
