import { Link } from 'wouter-preact'

import routes from '../../routes.js'
import { useAuth } from '../../Context/AuthProvider.jsx'
import { withPagePermissions } from '../../Components/Guard/index.jsx'
import PERMISSION from '../../Constants/permission.js'

import { useIndex } from '../../Hooks/Api/useRestApi.js'

import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb.jsx'
import Icon from '../../Components/Icon/Icon.jsx'
import LoadingOverlay from '../../Components/LoadingOverlay/LoadingOverlay.jsx'

import styles from './dashboard.module.css'

/**
 * @typedef { import('../../Hooks/Api/useRestApi.js').IndexResponse<App.Entity.Screen> } ScreenIndexResponse
 */

/** @type {App.Entity.Screen[]} */
const initialData = []

/**
 * Dashboard page
 *
 * @type {preact.FunctionComponent}
 */
function Dashboard() {
  /** @type {ScreenIndexResponse} */
  const { items = initialData, isLoading, error: loadingError } = useIndex('/screens')

  const { isGranted } = useAuth()

  return (
    <section className={`uk-article ${styles.dashboard}`}>

      {/** Breadcrumbs */}
      <Breadcrumb items={[]} />

      <div className="uk-card uk-card-default uk-card-small">
        <div className="uk-card-header uk-flex uk-flex-between">
          <h1 className="uk-card-title uk-margin-remove">
            {'Dashboard'}
          </h1>
        </div>
        <div className="uk-card-body uk-overflow-auto">
          <table className="uk-table uk-table-divider uk-table-striped uk-table-hover uk-table-small uk-table-middle uk-table-responsive">
            <thead>
              <tr>
                <th>{'Label'}</th>
                <th>{'Geo coords'}</th>
                <th className="uk-table-shrink">{'Actions'}</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, key) =>
                <tr key={key}>
                  {/** Label */}
                  <td>{item.label}</td>
                  {/** Geo coords */}
                  <td>{item.geoCoords ? item.geoCoords.toString() : '-'}</td>
                  {/** Actions */}
                  <td>
                    <fieldset className="uk-fieldset uk-text-right uk-text-left@m">
                      <div className="uk-button-group">
                        {/** Read */}
                        {isGranted(PERMISSION.SCREEN.READ) &&
                          <Link
                            href={routes.screen.read.replace(':id', item.id.toString())}
                            className="uk-button uk-button-primary uk-button-small"
                            >
                            <Icon icon="eye" />
                          </Link>
                        }
                      </div>
                    </fieldset>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <LoadingOverlay
          loadingError={loadingError}
          isLoading={isLoading}
        />
      </div>
    </section>
  )
}

export default withPagePermissions(Dashboard, [PERMISSION.SCREEN.INDEX])
