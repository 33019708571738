/**
 * Auth API hooks
 */
import { useState } from 'preact/hooks'
import { useApiConfig } from '../../Context/ApiConfigProvider.jsx'

import { wrappedFetch } from './helpers.js'

/**
 * @typedef { import('../../Context/ApiConfigProvider').ApiConfigInterface } ApiConfigInterface
 */

/**
 * @typedef {Object} SignInData
 * @property {string} email
 * @property {string} password
 */

/**
 * Sign In
 * @return {{
 *   data: Object|undefined,
 *   error: Error|null,
 *   isLoading: boolean,
 *   trigger: (signInData: SignInData) => Promise
 * }}
 */
export function useSignIn() {
  const { baseUrl } = useApiConfig()

  const [ data, setData ] = useState(undefined)
  const [ error, setError ] = useState(null)
  const [ isLoading, setIsLoading ] = useState(false)

  /**
   * @param {SignInData} signInData
   * @return {Promise<void>}
   */
  const trigger = (signInData) => {
    setData(undefined)
    setError(null)
    setIsLoading(true)

    return wrappedFetch(`${baseUrl}/auth/sign-in`, {
      method: 'POST',
      body: JSON.stringify(signInData),
    })
      .then(setData)
      .catch(setError)
      .finally(() => setIsLoading(false))
  }

  return {
    data,
    error,
    isLoading,
    trigger,
  }
}

/**
 * @typedef {Object} SignUpData
 * @property {string} name
 * @property {string} organisation
 * @property {string} email
 * @property {string} password
 * @property {string} passwordConfirm
 * @property {boolean} tos
 */

/**
 * Sign Up
 * @return {{
 *   data: Object|undefined,
 *   error: Error|undefined,
 *   isLoading: boolean,
 *   trigger: (signInData: SignUpData) => Promise<void>
 * }}
 */
export function useSignUp() {
  const { baseUrl } = useApiConfig()

  const [ data, setData ] = useState(undefined)
  const [ error, setError ] = useState(undefined)
  const [ isLoading, setIsLoading ] = useState(false)

  /**
   * @param {SignUpData} signUpData
   * @return {Promise<void>}
   */
  const trigger = (signUpData) => {
    setData(undefined)
    setError(undefined)
    setIsLoading(true)

    return wrappedFetch(`${baseUrl}/auth/sign-up`, {
      method: 'POST',
      body: JSON.stringify(signUpData),
    })
      .then(setData)
      .catch(setError)
      .finally(() => setIsLoading(false))
  }

  return {
    data,
    error,
    isLoading,
    trigger,
  }
}
