/**
 * @see https://getuikit.com/docs/nav
 */

import { Link, useLocation } from 'wouter-preact'
import clsx from 'clsx'

import Icon from '../Icon/Icon.jsx'

import styles from './nav.module.css'

/**
 * NavLink component
 * @type {preact.FunctionComponent}
 * @param {Object} props
 * @param {string} props.path - URL
 * @param {string} props.name - Name
 * @param {string} [props.icon] - Icon
 */
export function NavLink({
  path,
  name,
  icon = 'star',
}) {
  const [ currentPath ] = useLocation()

  const isActive = (
    // Compare current path to path
    currentPath === path ||
    // Note: Should reverse parse list
    currentPath.startsWith(`${path}/`)
  )

  return (
    <li className={clsx({'uk-active': isActive})}>
      <Link href={path}>
        <Icon
          icon={icon}
          ratio={1.1}
        />
        <span className="uk-text-middle uk-margin-small-left uk-visible@m">
          { name }
        </span>
      </Link>
    </li>
  )
}

/**
 * Nav header component
 * @param {Object} props
 * @param {string} props.title
 */
export function NavHeader({
  title
}) {
  return (
    <li className={`uk-nav-header ${styles['nav-header']}`}>
      { title }
    </li>
  )
}

/**
 * Nav divider component
 */
export function NavDivider() {
  return (
    <li className="uk-nav-divider"></li>
  )
}
