/**
 * Available content types
 * @note This is JSDoc type for TS enum
 * @enum {typeof CONTENT_TYPE[keyof typeof CONTENT_TYPE]}
 */
const CONTENT_TYPE = /** @type {const} */ ({
  AIRLY_STATION: 'airly-station',
  BI_STATION: 'bi-station',
  BI_STATIONS: 'bi-stations',
  CLOCK: 'clock',
  COUNTDOWN: 'countdown',
  IMAGE: 'image',
  REFERENCE: 'reference',
  SCREENSAVER: 'screensaver',
  SYNGEOS_STATION: 'syngeos-station',
  SYNGEOS_STATIONS: 'syngeos-stations',
  TEXT: 'text',
  VIDEO: 'video',
  VIDEO_PLAYLIST: 'video-playlist',
  WEBSITE: 'website',
  YOUTUBE_PLAYLIST: 'youtube-playlist',
})

export default CONTENT_TYPE
