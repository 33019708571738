/**
 * @typedef { import('./AuthProvider.jsx').AuthApiResponse } AuthApiResponse
 */

/**
 * Simple persistent storage
 */
export default class PersistentStorage {
  /** @type {string} */
  #storageKey
  /** @type {Storage} */
  #webStorage = window.localStorage

  constructor(storageKey = 'persist:mypromo') {
    this.#storageKey = storageKey
  }

  /**
   * @return {AuthApiResponse|null}
   */
  get() {
    const data = this.#webStorage.getItem(this.#storageKey)

    if (!data) {
      return null
    }

    const { state } = JSON.parse(data)

    return state
  }

  /**
   * @param {AuthApiResponse} value
   * @return {void}
   */
  set(value) {
    return this.#webStorage.setItem(this.#storageKey, JSON.stringify({
      state: value,
      version: 0,
    }))
  }

  /**
   * @return {void}
   */
  remove() {
    return this.#webStorage.removeItem(this.#storageKey)
  }
}
