/** @typedef { import('../../Constants/permission.js').Permission } Permission */

/** @enum {Permission} */
const PERMISSION = {
  INDEX: 'screen:index',
  CREATE: 'screen:create',
  READ: 'screen:read',
  UPDATE: 'screen:update',
  DELETE: 'screen:delete',
}

export default PERMISSION
