import { useState, useEffect } from 'preact/hooks'
import { html } from 'htm/preact'

import { useTranslator } from '@eo-locale/preact'

import Thumbnail from './Video/Thumbnail.js'
import Placeholder from './Media/Placeholder.js'
import Manager from './Video/Manager.js'

import Dialog from '../Dialog.js'

/**
 * @typedef {Object} AvailableItem
 * @property {string} url
 * @property {string} mime
 * @property {number} size
 * @property {number} timestamp
 * @property {boolean} isWorking
 */

/**
 * @typedef {Object} Options
 * @property {string} url
 */

/**
 * @typedef {Object} Config
 * @property {string} apiUrl
 */

/**
 * @typedef {Object} ComponentProps
 * @property {Options} options
 * @property {Config} config
 * @property { import('../PlaylistEditor.js').setReportValidityInterface } setReportValidity
 * @property { import('../PlaylistEditor.js').onChange } onChange
 */

/**
 * @param {ComponentProps} props
 */
export default function Video({
  options = {
    url: undefined
  },
  config = {
    apiUrl: undefined
  },
  onChange,
}) {
  /** @type {ReturnType<typeof useState<AvailableItem[]>>} - Available items */
  const [availableItems, setAvailableItems] = useState([])

  const translator = useTranslator()

  useEffect(() => {
    if (!config.apiUrl) {
      console.error('Video: Please set apiUrl in config')
      return
    }

    fetch(config.apiUrl, {
      method: 'GET',
      headers: {},
    })
      .then(response => response.json())
      .then((/** @type {AvailableItem[]} */ availableItems) =>
        setAvailableItems(availableItems.sort((itemA, itemB) =>
          itemB.timestamp - itemA.timestamp
        ))
      ).catch(() => {})
  }, [config.apiUrl])

  /**
   * Handle option change
   * @param {string} value
   * @return {void}
   */
  const handleOptionUrlChange = value =>
    onChange({
      ...options,
      url: value,
    })

  return html`
    <fieldset className="uk-fieldset uk-form-horizontal  bip-form-horizontal">
      <div>
        <label className="uk-form-label">
          ${translator.translate('option.video.field.video.text')}
        </label>
        <div className="uk-form-controls">
          <div className="uk-margin-small">
            <div className="uk-card uk-card-default uk-margin-small">
              <!-- Preview -->
              <div className="uk-card-media-top uk-height-small  bip-card-media-top">
                ${options.url
                  ? Thumbnail({ src: options.url })
                  : Placeholder()
                }
              </div>
              <!-- Actions -->
              <div className="uk-card-footer uk-padding-small">
                <div className="uk-button-group">
                  <!-- Update -->
                  <${Dialog}
                    title=${translator.translate('option.video.lightbox.title')}
                    button=${{
                      title: translator.translate('option.video.action.change.hint'),
                      text: translator.translate('option.video.action.change.text'),
                      icon: 'pencil',
                    }}
                    showConfirm=${false}
                  >
                    <${Manager}
                      items=${availableItems}
                      selectedUrl=${options.url}
                      actionsEnabledOnSelected=${false}
                      onSelectButtonClick=${handleOptionUrlChange}
                      onRemoveButtonClick=${undefined}
                    />
                  </${Dialog}>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  `
}
