import { Link } from 'wouter-preact'
import routes from '../../routes.js'
import {
  NavLink,
  NavHeader,
  NavDivider,
} from '../Nav/Nav.jsx'
import SidebarUserProfile from './SidebarUserProfile.jsx'
import { withPermissionFactory } from '../Guard/index.jsx'

import PERMISSION from '../../Constants/permission.js'

import logoInversePath from '../../Images/logo--inverse.svg'

import styles from './sidebar.module.css'

const AuthorizedNavLink = withPermissionFactory(NavLink)
const AuthorizedNavDivider = withPermissionFactory(NavDivider)

/**
 * @type {preact.FunctionComponent}
 * @param {Object} props
 * @param {App.Entity.User} props.user
 * @param {App.Entity.Organisation|null} props.organisation
 */
const Sidebar = ({
  user,
  organisation,
}) => (
  <aside className={`${styles['sidebar']} uk-light uk-flex uk-flex-column`}>

    {/** Logo */}
    <Link
      href={routes.dashboard}
      className="uk-navbar-item uk-logo uk-flex-left uk-padding-small uk-padding-remove-vertical"
    >
      <img
        src={logoInversePath}
        width="80"
        alt=""
      />
    </Link>

    {/** Profile */}
    <div className={`uk-padding-small ${styles['sidebar-profile']} uk-visible@m`}>
      <SidebarUserProfile
        user={user}
        organisation={organisation}
      />
    </div>

    {/** Menu */}
    <div className="uk-padding-small">
      <ul
        className={`${styles['uk-nav']} uk-nav uk-nav-default uk-nav-parent-icon"`}
        uk-nav
      >
        <NavHeader title={'Actions'} />
        <NavLink path={routes.dashboard} name={'Dashboard'} icon="home" />
        <AuthorizedNavLink path={routes.screen.index} name={'Screens'} icon="tv" permission={PERMISSION.SCREEN.INDEX} />
        <AuthorizedNavLink path={routes.playlist.index} name={'Playlists'} icon="comments" permission={PERMISSION.PLAYLIST.INDEX} />
        <AuthorizedNavLink path={routes.user.index} name={'Users'} icon="users" permission={PERMISSION.USER.INDEX} />
        <AuthorizedNavDivider permission={PERMISSION.ORGANISATION.INDEX} />
        <AuthorizedNavLink path={routes.organisation.index} name={'Organisations'} icon="settings" permission={PERMISSION.ORGANISATION.INDEX} />
        <NavDivider />
        <AuthorizedNavLink path={routes.profile} name={'My profile'} icon="user" permission={PERMISSION.PROFILE.READ} />
        <NavLink path={routes.auth.signOut} name={'Sign Out'} icon="sign-out" />
      </ul>
    </div>
  </aside>
)

export default Sidebar
