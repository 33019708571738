
import styles from './content.module.css'

export default function Content({ children }) {
  return (
    <main className={`uk-section uk-section-small ${styles.content}`}>
      <div className="uk-container uk-container-expand">
        {children}
      </div>
    </main>
  )
}
