import { Redirect } from 'wouter-preact'

import routes from '../../routes.js'
import { useAuth } from '../../Context/AuthProvider.jsx'

/**
 * Sign out page
 *
 * @type {preact.FunctionComponent}
 */
export default function SignOut() {
  const { signout } = useAuth()

  signout()

  return (
    <Redirect
      to={routes.dashboard}
      replace={true}
    />
  )
}
