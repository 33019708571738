import { Link } from 'wouter-preact'
import { connect, Form } from 'formik'

import routes from '../../routes.js'

import STATE from '../../Components/State/state.constants.js'
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb.jsx'
import LoadingOverlay from '../../Components/LoadingOverlay/LoadingOverlay.jsx'
import ValidationMessages from '../../Components/Form/ValidationMessages.jsx'

/**
 * Create/ Update form
 *
 * @type {preact.FunctionComponent}
 * @param {Object} props
 * @param {number|null} props.id
 * @param {Error|undefined} props.loadingError
 * @param {boolean} props.isLoading
 * @param {boolean} props.isSyncing
 * @param {import('formik').FormikContextType<any>} [props.formik]
 */
function OgranisationForm({
  id,
  loadingError = undefined,
  isLoading = false,
  isSyncing = false,
  formik,
}) {
  return (
    <section className="uk-article">

      {/** Breadcrumbs */}
      <Breadcrumb items={[
        { text: 'Organisations', path: routes.organisation.index },
        { text: id ?? 'New item' },
      ]} />

      <Form className="uk-card uk-card-default uk-card-small uk-flex-inline uk-flex-column">
        <fieldset
          className="uk-fieldset"
          disabled={isLoading || isSyncing}
        >
          <div className="uk-card-header uk-flex uk-flex-between">
            <h1 className="uk-card-title uk-margin-remove">
              {id ? 'Edit' : 'Create'}
            </h1>

            {/** Actions */}
            <ul className="uk-iconnav">
              <li>
                <button
                  className="uk-icon"
                  type="submit"
                  data-uk-icon="icon: check"
                  data-uk-tooltip={'Save'}
                ></button>
              </li>
              <li>
                <Link
                  className="uk-icon"
                  href={routes.organisation.index}
                  data-uk-icon="icon: close"
                  data-uk-tooltip={'Cancel'}
                />
              </li>
            </ul>
          </div>

          <div className="uk-card-body uk-form-horizontal">
            {/** Name */}
            <div className="uk-margin">
              <label className="uk-form-label">{'Name'}</label>
              <div className="uk-form-controls">
                <input
                  className="uk-input uk-form-width-medium"
                  type="text"
                  placeholder={'Acme corporation'}
                  {...formik.getFieldProps('name')}
                />
                <ValidationMessages name="name" />
              </div>
            </div>

            {/** Language */}
            <div className="uk-margin">
              <label className="uk-form-label">{'Language'}</label>
              <div className="uk-form-controls">
                <select
                  className="uk-select uk-form-width-medium"
                  {...formik.getFieldProps('language')}
                >
                  <option value="en-GB">{'English'}</option>
                  <option value="pl-PL">{'Polish'}</option>
                </select>
                <ValidationMessages name="language" />
              </div>
            </div>

            {/** State */}
            <div className="uk-margin">
              <label className="uk-form-label">{'State'}</label>
              <div className="uk-form-controls">
                <select
                  className="uk-select uk-form-width-medium"
                  disabled
                  {...formik.getFieldProps('state')}
                >
                  <option value={STATE.ENABLED}>{'Enabled'}</option>
                  <option value={STATE.DISABLED}>{'Disabled'}</option>
                </select>
                <ValidationMessages name="state" />
              </div>
            </div>
          </div>
        </fieldset>

        {/** Overlays */}
        <LoadingOverlay
          loadingError={loadingError}
          isLoading={isLoading}
        />

      </Form>
    </section>
  )
}

export default connect(OgranisationForm)
