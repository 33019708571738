import { createElement } from 'preact'
import { useRef, useState, useEffect } from 'preact/hooks'
import { html } from 'htm/preact'

import { isDialogAvailable } from '../../../utils/dom.js'

/**
 * @typedef { import('preact').ComponentClass } ComponentClass
 * @typedef { import('preact').FunctionComponent } FunctionComponent
 */


/**
 * @typedef { import('preact/hooks').MutableRef<HTMLDialogElement> } HTMLDialogElementRef
 */


/**
 * @typedef {Object} ComponentProps
 * @property {string} src
 * @property {preact.VNode<[]>} props.children
 */

/**
 * Wrap image in lighbox
 * Inspired by UIkit Lighbox https://getuikit.com/docs/lightbox
 * @type {FunctionComponent}
 * @param {ComponentProps} props
 */
export default function Lightbox ({
  src,
  children
}) {
  const [open, setOpen] = useState(false)

  /** @type {HTMLDialogElementRef} */
  const dialogRef = useRef()

  // Use Dialog API
  useEffect(() => {
    if (open) {
      dialogRef.current.showModal()
    } else if (dialogRef.current.open) {
      dialogRef.current.close()
    }
  }, [open])

  // Handle closing by other means (esc)
  useEffect(() => {
    dialogRef.current.addEventListener('close', handleDialogClose)

    return () => dialogRef.current.removeEventListener('close', handleDialogClose)

    function handleDialogClose() {
      setOpen(false)
    }
  }, [])

  /**
   * Open
   * @return {void}
   */
  const handleOpenButtonClick = () =>
    setOpen(true)

  /**
   * Close
   * @return {void}
   */
  const handleCloseButtonClick = () =>
    setOpen(false)

  return html`
    <dialog
      ref=${dialogRef}
      className="bip-dialog"
    >
      ${open && html`
        <div className="bip-dialog-container uk-flex uk-flex-row">
          <!-- Close icon -->
          <button
            className="uk-modal-close-default"
            type="button"
            title=${'Close'}
            data-uk-close
            onClick=${handleCloseButtonClick}
          ></button>
          <!-- Body -->
          <div className="uk-modal-body uk-overflow-auto">
            <img
              className="bip-media-preview bip-media-preview--lightbox"
              src=${src}
              alt=""
              loading="lazy"
            />
          </div>
        </div>
      `}
    </dialog>
    <!-- Open button -->
    <button
      className="uk-button uk-button-link uk-width-1-1 uk-height-1-1"
      type="button"
      disabled=${!isDialogAvailable || !src}
      title="Open preview in lightbox"
      onClick=${handleOpenButtonClick}
    >
      ${children}
    </button>
  `
}

/**
 * Lighbox Higher-Order Component
 * @param {FunctionComponent | ComponentClass | null} WrappedComponent
 * @param {ComponentProps} [lighboxProps]
 * @return {FunctionComponent | null}
 */
export function withLightbox(
  WrappedComponent,
  lighboxProps
) {
  if (!WrappedComponent) {
    return null
  }

  // Format for display in DevTools
  const componentDisplayName = WrappedComponent.displayName || WrappedComponent.name || 'unknown'

  /**
   * @param {Object} props
   */
  /*
  const Wrapped = props => createElement(
    Lightbox,
    lighboxProps,
    createElement(
      WrappedComponent,
      props
    )
  )
  */

  /**
   * @param {ComponentProps} [props]
   */
  const Wrapped = props => createElement(
    Lightbox,
    // Pick src from wrapped component props to use dynamic src
    { ...lighboxProps, src: props.src },
    createElement(
      WrappedComponent,
      props
    )
  )

  Wrapped.displayName = `withLighbox(${componentDisplayName})`

  return Wrapped
}
