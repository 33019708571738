import { useState, useMemo } from 'preact/hooks'
import { useLocation } from 'wouter-preact'
import { Formik } from 'formik'
import UIkit from 'uikit'

import routes from '../../routes.js'
import { useAuth } from '../../Context/AuthProvider.jsx'
import { withPagePermissions } from '../../Components/Guard/index.jsx'
import PERMISSION from './user.permission.js'
import { GLOBAL_CONTEXT } from '../../Constants/permission.js'

import {
  useIndex,
  useCreate,
} from '../../Hooks/Api/useRestApi.js'

import UserForm from './UserForm.jsx'
import { initialData, getStatusFromError, validate, transformValues } from './userStore.js'

/**
 * @typedef { import('../../Hooks/Api/useRestApi.js').IndexResponse<App.Entity.User> } UserIndexResponse
 * @typedef { import('../../Hooks/Api/useRestApi.js').CreateResponse<App.Entity.User> } UserCreateResponse
 */

/**
 * User create item page
 *
 * @type {preact.FunctionComponent}
 */
function UserCreate() {

  const { isGranted, user } = useAuth()

  const [, route ] = useLocation()

  /** @type {UserCreateResponse} */
  const { error: createError, isCreating, creator } = useCreate('/users')

  /** @type {UserIndexResponse} */
  const { mutate: mutateItems } = useIndex('/users', false)

  const [ formItem, setFormItem ] = useState(() =>
    isGranted(GLOBAL_CONTEXT.ACCESS)
      ? initialData
      : { ...initialData, organisationId: user.organisationId }
  )

  const status = useMemo(() => getStatusFromError(createError), [createError])

  /**
   * Handle submit
   * @param {Object} values
   */
  async function handleFormSubmit(values, { setSubmitting }) {
    /** @type {App.Entity.User} */
    const item = {
      ...formItem,
      ...transformValues(values),
    }

    const createdItem = await creator(item)
    setSubmitting(false)

    if (!createdItem) {
      UIkit.notification({ message: 'Error', status: 'warning' })
      return
    }

    // Add to cached items
    await mutateItems((/** @type {App.Entity.User[]|undefined} */ cachedItems) =>
      cachedItems && [...cachedItems, createdItem],
      false
    )

    setFormItem(item)

    UIkit.notification({ message: 'Item created', status: 'success' })

    // Redirect
    route(routes.user.index)
  }

  return (
    <Formik
      initialValues={formItem}
      initialStatus={status}
      enableReinitialize={true}
      validate={values => validate(values, true)}
      onSubmit={handleFormSubmit}
    >
      <UserForm
        id={null}
        loadingError={undefined}
        isLoading={false}
        isSyncing={isCreating}
      />
    </Formik>
  )
}

export default withPagePermissions(UserCreate, [PERMISSION.CREATE])
