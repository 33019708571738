import AppError from './AppError.js'

/**
 * @typedef {Object} ApiProblem
 * @property {string} title - A short, human-readable summary of the problem type.
 * @property {string} type - A URI reference (RFC3986) that identifies the problem type.
 * @property {number} status - The HTTP status code set by the origin server for this occurrence of the problem.
 * @property {string} [detail] - An human readable explanation specific to this occurrence of the problem.
 * @property {string} [instance] - A URI reference that identifies the specific occurrence of the problem.
 * @property {any} [x-context]
 */

/**
 * Abstract HTTP error
 */
export default class AbstractHttpError extends AppError {
  /**
   * Convert from json-server-auth response
   * @param {Response} response
   * @return {Promise<AbstractHttpError>}
   */
  static async createFromResponse(response) {
    const contentType = response.headers.get('content-type')

    /** @type {string} - Default message */
    let message = `${response.statusText} (${response.status})`
    let context = undefined

    // API Problem (https://tools.ietf.org/html/rfc9457)
    if (contentType && contentType.includes('application/problem+json')) {
      /** @type {ApiProblem} */
      const apiProblem = await response.clone().json()

      message = apiProblem['title']
      context = apiProblem['x-context']
    }

    // Legacy formats
    if (contentType && contentType.includes('application/json')) {
      const body = await response.clone().json()

      switch (typeof body) {
        // deprecated
        // Handle Slim JsonErrorRenderer response (message is same as status + statusText)
        case 'object':
          message = body.message
          break

        // Handle json-server-auth
        case 'string':
        default:
          message = body
          break
      }
    }

    return new this(message, response, context)
  }

  /**
   * @inheritdoc
   * @param {string} message
   * @param {Response} [response]
   * @param {any} [context]
   */
  constructor(message, response = undefined, context = undefined) {
    super(message)

    this.name = 'HttpError'

    this.response = response
    this.context = context

    Object.setPrototypeOf(this, AbstractHttpError.prototype)
  }
}
